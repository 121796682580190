<template>
  <div class="home container">
      <div v-if="versendet">
        <div class="feedbackHeader">
            <div class="feedbackHeader2">
                {{ phrasen.feedback_12 }}
            </div>
        </div>
        <router-link to="/">
            <div class="btn blue darken-3 feedbackButton2">
                <span class="left">
                    <img src="/img/haus.gif" class="buttonImage">
                </span>
                <div class="buttonName">
                    {{ phrasen.feedback_10 }}
                </div>
            </div>
        </router-link>
      </div>
      <div v-if="!versendet">
      <div class="feedbackHeader">
        <div class="feedbackHeader2">
            {{ phrasen.feedback_1 }}
        </div>
        {{ phrasen.feedback_2 }}
      </div>
      <div class="feedbackBewertung blue lighten-5 blue-text text-darken-3">
        <div class="feedbackBewertung2">
            <span v-html="phrasen.feedback_3"></span>
        </div>
        <table><br class="hide-on-large-only">
            <tr class="bewertungTabelleTr">
                <td class="buttonsTabelleTd5">
                    <table>
                        <tr class="bewertungTabelleTr">
                            <td class="bewertungTabelleTd">
                                <img class="bewertungTabelleImg" src="/img/daumen1.gif" @click="bewertungSetzen(1)" v-if="bewertung === 0 || bewertung === 1" />
                                <img class="bewertungTabelleImg" src="/img/daumen1_grau.gif" @click="bewertungSetzen(1)" v-if="bewertung !== 0 && bewertung !== 1" />
                            </td>
                            <td class="bewertungTabelleTd2">
                                <img class="bewertungTabelleImg" src="/img/daumen2.gif" @click="bewertungSetzen(2)" v-if="bewertung === 0 || bewertung === 2" />
                                <img class="bewertungTabelleImg" src="/img/daumen2_grau.gif" @click="bewertungSetzen(2)" v-if="bewertung !== 0 && bewertung !== 2" />
                            </td>
                        </tr>
                    </table>
                    <div class="hilfreich">{{ phrasen.feedback_4 }}</div>
                </td>
                <td class="buttonsTabelleTd4">
                    <div class="right hilfreich">{{ phrasen.feedback_5 }}</div>
                    <table>
                        <tr class="bewertungTabelleTr">
                            <td class="bewertungTabelleTd2">
                                <img class="bewertungTabelleImgNeg" src="/img/daumen2.gif" @click="bewertungSetzen(3)" v-if="bewertung === 0 || bewertung === 3"/>
                                <img class="bewertungTabelleImgNeg" src="/img/daumen2_grau.gif" @click="bewertungSetzen(3)" v-if="bewertung !== 0 && bewertung !== 3"/>
                            </td>
                            <td class="bewertungTabelleTd3">
                                <img class="bewertungTabelleImgNeg" src="/img/daumen1.gif" @click="bewertungSetzen(4)" v-if="bewertung === 0 || bewertung === 4"/>
                                <img class="bewertungTabelleImgNeg" src="/img/daumen1_grau.gif" @click="bewertungSetzen(4)" v-if="bewertung !== 0 && bewertung !== 4"/>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
        </table>
      </div>
      <div class="feedbackBewertung blue lighten-5 blue-text text-darken-3">
        <textarea class="bewertungText" :placeholder="phrasen.feedback_6" ref="bewertung" onfocus="this.placeholder = ''" v-on:keyup="textPruefen"></textarea>
      </div>
      <div class="row buttonsRow">
                <div class="col s12 m6 l4 feedbackCol" @click="feedbackSenden()" v-if="bewertung !== 0 || bewertungTextVorhanden">
                    <div class="btn blue darken-3 feedbackButton">
                        <span class="left">
                            <img src="/img/feedback.gif" class="buttonImage">
                        </span>
                        <div class="buttonName">
                            {{ phrasen.feedback_9 }}
                        </div>
                    </div>
                </div>
                <div class="col s12 m6 l4 feedbackCol" v-if="bewertung === 0 && !bewertungTextVorhanden">
                    <div class="btn grey feedbackButtonGrau">
                        <span class="left">
                            <img src="/img/feedback_grau.gif" class="buttonImage">
                        </span>
                        <div class="buttonName">
                            {{ phrasen.feedback_9 }}
                        </div>
                    </div>
                </div>
                <div class="col s12 m6 l4">
                    <router-link to="/">
                    <div class="btn blue darken-3 feedbackButton">
                        <span class="left">
                            <img src="/img/haus.gif" class="buttonImage">
                        </span>
                        <div class="buttonName">
                            {{ phrasen.feedback_10 }}
                        </div>
                    </div>
                    </router-link>
                </div>
                <div class="col s12 m12 l4 feedbackCol2">
                    <div class="btn blue darken-3 feedbackButton" @click="alleFelderEntfernen()">
                        <span class="left">
                            <img src="/img/delete.gif" class="buttonImage">
                        </span>
                        <div class="buttonName">
                            {{ phrasen.feedback_11 }}
                        </div>
                    </div>
                </div>
            </div>
      </div>
  </div>
</template>

<script>
// Gewährleistet die Funktionalität von Materialize in dieser Vue-Datei
import M from 'materialize-css/dist/js/materialize.min'

export default {
  name: 'Feedback',
  props: ['bestaetigungArzt', 'phrasen'],
  data: function () {
    return {
      bewertung: 0,
      bewertungTextVorhanden: false,
      versendet: false
    }
  },
  methods: {
    // hier wird das Feedback im LocalStorage gespeichert und ein Event "feedback" zum Versenden des Feedbacks ausgelöst
    feedbackSenden: function () {
      if (this.$refs.bewertung.value === '' && this.bewertung === 0) {
        return
      }
      var feedback = []
      if (localStorage.feedback != null) {
        feedback = JSON.parse(localStorage.feedback)
      }
      var neuesFeedback = { nachricht: this.$refs.bewertung.value, bewertung: this.bewertung, datum: new Date() }
      feedback.push(JSON.stringify(neuesFeedback))
      var feedbacksAktuell = JSON.stringify(feedback)
      localStorage.feedback = feedbacksAktuell

      var event
      if (typeof (Event) === 'function') {
        event = new Event('feedback')
      } else {
        event = document.createEvent('Event')
        event.initEvent('feedback', true, true)
      }
      // Dispatch the event.
      window.dispatchEvent(event)
      this.loggeDaten('feedback_versendet')
      this.versendet = true
    },
    // hier werden alle angaben aus dem Feedback Formular gelöscht
    alleFelderEntfernen: function () {
      this.$refs.bewertung.value = ''
      this.bewertung = 0
    },
    // hier wird die ausgewählte Bewertung gesetzt
    bewertungSetzen: function (id) {
      this.bewertung = id
    },
    // hier wird geprüft, ob ein Bewertungstext verfasst wurde (keine Mindestlänge)
    textPruefen: function () {
      if (this.$refs.bewertung.value !== '') {
        this.bewertungTextVorhanden = true
      } else {
        this.bewertungTextVorhanden = false
      }
    },
    loggeDaten: function (id) {
      // hier wird geprüft, ob der User gerade online oder offline ist.
      var statusJetzt = 'offline'
      if (navigator.onLine) {
        statusJetzt = 'online'
      }
      // hier werden die LoggDaten aus dem LocalStorage in ein Array eingelesen und eine Kopie von den LoggDaten aus dem LocalStorage erstellt.
      var loggDaten = []
      var localStorageLoggDatenKopie = null
      if (localStorage.loggDaten != null) {
        loggDaten = JSON.parse(localStorage.loggDaten)
        localStorageLoggDatenKopie = localStorage.loggDaten
      }
      var maxLogEintraege = parseInt(localStorage.maxLogEintraege)
      // wenn die Loggsaten, die Maximallänge nicht überschreiten, dann wird der neue Eintrag in die Daten eingefügt.
      if (loggDaten.length < maxLogEintraege) {
        var neuerLogg = { aktion: id, datum: new Date(), status: statusJetzt }
        loggDaten.push(JSON.stringify(neuerLogg))
        var loggDatenAktuell = JSON.stringify(loggDaten)
        // hier wird versucht, die erweiterten Loggdaten in den LocalStorage zu schreiben. Wenn das schiefläuft, dann werden die alten Loggdaten in den LocalStorage geschrieben.
        try {
          localStorage.loggDaten = loggDatenAktuell
        } catch (e) {
          localStorage.loggDaten = localStorageLoggDatenKopie
        }
      }
    }
  },
  mounted () {
    M.AutoInit() // Gewährleistet die Funktionalität von Materialize in dieser Vue-Datei
    if (this.bestaetigungArzt === false) {
      this.$router.push({ name: 'Disclaimer' })
    }
    var comp = this
    this.$refs.bewertung.addEventListener('blur', (event) => {
      comp.$refs.bewertung.placeholder = comp.phrasen.feedback_6
    })
  }
}
</script>

<style scoped>

.buttonsRow {
    margin-left:-12px; margin-right: -12px;
}

.feedbackHeader {
    background-color:#1565C0;
    width: 100%;
    margin-top: 20px;
    color: white;
    padding: 15px;
    box-shadow: 3px 3px 3px silver;
    font-size: 14px;
}

.feedbackHeader2 {
    font-size: 18px;
    font-weight: bold;
}

.feedbackBewertung {
    width: 100%;
    margin-top: 7px;
    padding: 15px;
    box-shadow: 3px 3px 3px silver;
    font-size: 16px;
}

.hilfreich {
    font-size: 12px;
}

.bewertungTabelleTr {
    border: none;
}

.bewertungTabelleTd {
    vertical-align: top;
    width: 25%;
}

.bewertungTabelleTd2 {
    vertical-align: top;
    width: 25%;
    text-align: center;
}

.bewertungTabelleTd3 {
    vertical-align: top;
    width: 25%;
    text-align: right;
}

.bewertungTabelleImg {
    width: 50%;
    min-width: 30px;
    cursor: pointer;
}

.bewertungTabelleImgNeg {
    width: 50%;
    min-width: 30px;
    -moz-transform: scaleY(-1);
    -o-transform: scaleY(-1);
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1);
    cursor: pointer;
}

.bewertungText {
    background-color: white;
    max-width: 100%;
    min-width: 100%;
    border: none;
    color:#1565C0;
    height: 70px;
}

.bewertungText2 {
    background-color: white;
    max-width: 100%;
    min-width: 100%;
    border: none;
    color:#1565C0;
    height: 30px;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color:#1565C0;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color:#1565C0;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color:#1565C0;
}

.buttonsTabelleTd {
    padding: 0;
    padding-right: 7px;
    width: 35%;
}

.buttonsTabelleTd4 {
    padding: 0;
}

.buttonsTabelleTd6 {
    padding: 0;
}

.buttonsTabelleTd5 {
    padding: 0;
    padding-left: 10px;
}

.buttonsTabelleTd7 {
    padding: 0;
    padding-left: 10px;
    font-size: 14px;
}

.buttonsTabelleTd2 {
    padding: 0;
    padding-right: 7px;
}
.buttonsTabelleTd3 {
    padding: 0;
    width: 35%;
}

.feedbackButton {
    background-color:#1565C0;
    text-transform: none !important;
    width: 100%;
    margin-top: 7px;
    color: white;
    box-shadow: 3px 3px 3px silver;
    font-size: 14px;
    height: 50px;
}

.feedbackButtonGrau {
    text-transform: none !important;
    width: 100%;
    margin-top: 7px;
    color: white;
    box-shadow: 3px 3px 3px silver;
    font-size: 14px;
    height: 50px;
}

.feedbackButton2 {
    background-color:#1565C0;
    text-transform: none !important;
    margin-top: 7px;
    width: 250px;
    color: white;
    box-shadow: 3px 3px 3px silver;
    font-size: 14px;
    height: 50px;
}

.buttonName {
    margin-top: 6px;
}

.feedbackUnterButton {
    width: 85%;
}

.buttonImage {
    height: 20px;
    margin-top: 14px;
}

@media screen and (min-width: 600px) {
    .feedbackCol {
        padding-right: 0px !important;
    }
}

@media screen and (min-width: 1000px) {
  .feedbackHeader {
        background-color:#1565C0;
        width: 100%;
        margin-top: 20px;
        color: white;
        padding: 15px;
        box-shadow: 3px 3px 3px silver;
        font-size: 18px;
    }

    .feedbackHeader2 {
        font-size: 24px;
        font-weight: bold;
    }

    .bewertungText {
        background-color: white;
        max-width: 100%;
        min-width: 100%;
        border: none;
        color:#1565C0;
        height: 170px;
    }

    .buttonsRow {
        margin-left:-12px; margin-right: -12px;
    }

    .feedbackBewertung {
        width: 100%;
        margin-top: 7px;
        padding: 15px;
        box-shadow: 3px 3px 3px silver;
        font-size: 20px;
    }

    .hilfreich {
        font-size: 16px;
    }

    .buttonsTabelleTd4 {
        padding: 0;
        padding-top: 35px;
    }

    .feedbackButton {
        background-color:#1565C0;
        text-transform: none !important;
        width: 100%;
        margin-top: 7px;
        color: white;
        box-shadow: 3px 3px 3px silver;
        font-size: 18px;
        height: 50px;
    }

    .feedbackButtonGrau {
        text-transform: none !important;
        width: 100%;
        margin-top: 7px;
        color: white;
        box-shadow: 3px 3px 3px silver;
        font-size: 18px;
        height: 50px;
    }

    .feedbackCol2 {
        padding-left: 0px !important;
    }

}

</style>
