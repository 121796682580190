<template>
  <div id="hauptdiv"><br>
    <a href="javascript:history.back()" class="btn blue darken-3 backKnopf">Zurück</a>
    <br><br>
    <table class="leftZehn">
      <tr class="trBorderNone">
        <td class="CEImpressum">
          <img class="CELabel" src="/img/ce-schwarz.png">
        </td>
        <td>
          <b class="blue-text text-darken-3"><i>easyDOAC</i></b> ist als Medizinprodukt der Klasse I angemeldet
        </td>
      </tr>
    </table>
    <div class="blue-text text-darken-3" id="uberschrift">Datenschutz</div>
    <div class="leftZehn"><br>Letzte Aktualisierung: Februar 2021<br><br>
      <b class="blue-text text-darken-3">DATENSCHUTZHINWEISE NUTZUNG DER APP <i>easyDOAC</i></b><br><br>
      Mit diesen Datenschutzhinweisen möchten wir darüber informieren, dass im Rahmen der Verwendung der App <b class="blue-text text-darken-3"><i>easyDOAC</i></b> personenbezogene Daten nur mit Ihrem ausdrücklichen Einverständnis verarbeitet werden.
      <br><br>Die bei der Nutzung der App <b class="blue-text text-darken-3"><i>easyDOAC</i></b> eingegebenen Daten verbleiben lokal im geschützten Bereich der App bzw. des verwendeten Webbrowsers auf dem Endgerät. Darüber hinaus verarbeiten wir Ihre Daten nur aus technischen, vertraglichen oder gesetzlichen Zwecken und auch nur mit Ihrer (auch konkludent erfolgten) Einwilligung. Zusätzlich werden in der App <b class="blue-text text-darken-3"><i>easyDOAC</i></b> anonyme Statistikdaten erhoben.
      <br><br>Die App <b class="blue-text text-darken-3"><i>easyDOAC</i></b> ist ein für den Europäischen Wirtschaftsraum klassifiziertes Medizinprodukt und erfüllt die grundlegenden Anforderungen der Richtlinie 93/42/EWG bzw. ihrer nationalen Umsetzungen. Weitere Informationen sind den Nutzungsbestimmungen zu entnehmen.
      <br><br><b class="blue-text text-darken-3"><i>easyDOAC</i></b> wird Ihre persönlichen Daten vertraulich und streng zweckgebunden verarbeiten.
      <br><br><b class="blue-text text-darken-3">1. Wer ist für die Datenverarbeitung verantwortlich und an wen kann ich mich wenden?</b>
      <br><br>Verantwortlicher im Sinne der Datenschutz-Grundverordnung (DSGVO) ist:
      <br><br>BAYOOCARE GmbH
      <br>Europaplatz 5
      <br>64293 Darmstadt
      <br><br>Sie erreichen unseren betrieblichen Datenschutzbeauftragten unter den vorgenannten Kontaktdaten sowie per
      <br>E-Mail: <a href="mailto:dataprivacy@bayoocare.com">dataprivacy@bayoocare.com</a>
      <br><br><b class="blue-text text-darken-3">2. Was sind personenbezogene Daten und in welchem Kontext werden diese verarbeitet?</b>
      <br><br>Personenbezogene Daten sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person beziehen.
      <br><br>Bei der Verwendung der App <b class="blue-text text-darken-3"><i>easyDOAC</i></b> benötigen wir weder Ihren Namen noch weitere Kontaktdaten von Ihnen, sondern speichern Ihre Daten ohne Namensbezug (pseudonym) auf ihrem Smartphone. Lediglich in den Fällen der Absätze 5.1, 5.2 und 5.3 dieser Datenschutzhinweise verarbeiten wir Ihre personenbezogenen Daten.
      <br><br><b class="blue-text text-darken-3">3. Welche Daten erhebt <i>easyDOAC</i>?</b>
      <br><br>3.1. Bestätigung der AGB, Datenschutzerklärung und Nutzungseinschränkung
      <br><br><b class="blue-text text-darken-3"><i>easyDOAC</i></b> fordert vor der Verwendung zur Bestätigung der AGB, der Datenschutzerklärung und damit verbunden der Nutzungseinschränkung für die festgelegten Nutzerkreise auf. Diese Bestätigung wird für ein besseres Nutzerempfinden bei der Verwendung der App lokal in einem Speicherbereich Ihres Webbrowsers abgelegt und muss damit nicht erneut bei jeder Verwendung neu eingefordert werden. Haben Sie den Browser so eingestellt, dass alle Daten regelmäßig gelöscht werden, muss auch die Bestätigung erneut erfolgen. Diese Daten verbleiben ausschließlich auf Ihrem lokalen System.
      <br><br>3.2. Filterparameter und Scoreeingaben
      <br><br><b class="blue-text text-darken-3"><i>easyDOAC</i></b> benötigt Eingaben für die Filterung passender Suchergebnisse in der Fachinformation und der Schätzfunktion für die Nierenfunktionsberechnung. Ebenso werden für die Schätzung der angebotenen Scores Antworten auf Fragen zum Patienten benötigt. Diese Daten werden lokal in einem Speicherbereich Ihres Webbrowsers abgelegt. Diese Daten verbleiben ausschließlich auf Ihrem lokalen System.
      <br><br>3.3. weitere Daten, die Sie uns mitteilen
      <br><br>Wir benötigen grundsätzlich weder Ihren Namen noch weitere Kontaktdaten von Ihnen, sondern speichern Ihre Daten ohne Namensbezug auf ihrem Smartphone.
      <br><br>3.4. Nutzungsdaten, die wir über die Verwendung unserer App erheben
      <br><br>Zur Verbesserung und Weiterentwicklung von <b class="blue-text text-darken-3"><i>easyDOAC</i></b> werden statistische Daten über die Nutzung der App erhoben. Diese statistischen Daten werden von BAYOOCARE und dem Universitätsklinikum Heidelberg als Betreiber gemeinsam verwendet.
      <br><br>3.5. Personenbezogene Daten
      <br><br>Die Zweckbestimmung der App <b class="blue-text text-darken-3"><i>easyDOAC</i></b> sieht keine Erhebung und Verarbeitung von Daten durch Dritte vor.
      <br><br>Bei der Verwendung von <b class="blue-text text-darken-3"><i>easyDOAC</i></b> werden neue Versionen der App und arzneimittelbezogene Daten von einem Server abgerufen und auf Ihrem lokalen Gerät für die weitere Verwendung abgelegt. Bei diesem Abruf wird aus technischen Gründen Ihre IP-Adresse übermittelt. Diese IP-Adresse wird von uns nicht oder nur gekürzt gespeichert.
      <br><br><b class="blue-text text-darken-3">4. Wo speichern wir Ihre personenbezogenen Daten?</b>
      <br><br>Mit der Nutzung dieser App findet die Datenübermittlung und Datenverarbeitung ausschließlich in der EU durch vertraglich gebundene Dienstleister, die in unserem Auftrag handeln, statt.
      <br><br><b class="blue-text text-darken-3">5. Wofür verarbeiten wir Ihre Daten (Zweck der Verarbeitung) und auf welcher Rechtsgrundlage?</b>
      <br><br>Wir verarbeiten personenbezogene Daten im Einklang mit den Bestimmungen der Europäischen DatenschutzGrundverordnung (DSGVO) und dem Bundesdatenschutzgesetz (BDSG):
      <br><br>5.1. zur Erfüllung von vertraglichen Pflichten (Art. 6 Abs. 1 lit. b DSGVO)
      <br><br><b class="blue-text text-darken-3"><i>easyDOAC</i></b> verarbeitet die vertragsgegenständlichen Daten, um Ihnen die vertraglich vereinbarten Leistungen zu erbringen und zuzuordnen sowie die Daten den richtigen Empfängern bereitstellen zu können.
      <br><br>Ihre Angaben werden ohne Personenbezug für wissenschaftliche und statistische Zwecke und zur kontinuierlichen Verbesserung der App sowie des Algorithmus verwendet. Anhand der verwendeten Daten können betroffene Personen nicht oder nicht mehr identifiziert werden.
      <br><br>5.2. zur Verarbeitung personenbezogener Gesundheitsdaten aufgrund Ihrer Einwilligung (Art. 6. Abs. 1 lit. a DSGVO, Art. 9. Abs. 2 lit. a DSGVO)
      <br><br>Beim erstmaligen Start von <b class="blue-text text-darken-3"><i>easyDOAC</i></b> willigen Sie in die Verarbeitung Ihrer Daten durch setzen eines Hakens in der entsprechenden Check Box ein.
      <br><br>5.3. zur Ausübung oder Verteidigung rechtlicher Ansprüche (Art. 9 Abs. 2 lit. f DSGVO)
      <br><br>Soweit erforderlich, verarbeiten wir Ihre Daten ggf. zur Geltendmachung, Ausübung oder Verteidigung rechtlicher Ansprüche.
      <br><br><b class="blue-text text-darken-3">6. Wem werden meine personenbeziehbaren Daten übermittelt?</b>
      <br><br>Innerhalb <b class="blue-text text-darken-3"><i>easyDOAC</i></b> erhalten prinzipiell nur diejenigen Personen Zugriff auf Daten, die diese zur Erfüllung unserer vertraglichen und ggf. gesetzlichen Pflichten benötigen. Auch von uns eingesetzte Dienstleister und Erfüllungsgehilfen können zu diesen Zwecken Daten erhalten.
      <br><br>Wir übermitteln demgemäß keine personenbezogenen oder -beziehbare Daten von Ihnen an Dritte ohne Ihr ausdrückliches vorheriges Einverständnis.
      <br><br><b class="blue-text text-darken-3">7. Ist die Bereitstellung der personenbezogenen Daten gesetzlich oder vertraglich vorgeschrieben?</b>
      <br><br>Sie sind nicht verpflichtet, uns vorgenannte personenbezogene Daten bereitzustellen.
      <br><br><b class="blue-text text-darken-3">8. Wie lange werden meine Daten gespeichert?</b>
      <br><br>Grundsätzlich speichern wir Ihre personenbezogenen Daten bis zur Erfüllung des Zweckes der Kontaktaufnahme bzw. rechtlicher Vorgaben.
      <br><br><b class="blue-text text-darken-3">9. Ihre Rechte als betroffene Person</b>
      <br><br>Jede von unseren personenbezogenen Datenverarbeitungen betroffene Person hat das Recht auf Auskunft nach Artikel 15 DSGVO, das Recht auf Berichtigung nach Artikel 16 DSGVO, das Recht auf Löschung nach Artikel 17 DSGVO, das Recht auf Einschränkung der Verarbeitung nach Artikel 18 DSGVO, das Recht auf Widerspruch aus Artikel 21 DSGVO sowie das Recht auf Datenübertragbarkeit aus Artikel 20 DSGVO. Beim Auskunftsrecht und beim Löschungsrecht gelten die Einschränkungen nach §§ 34 und 35 BDSG. Darüber hinaus besteht ein Beschwerderecht bei einer Datenschutzaufsichtsbehörde gemäß Artikel 77 DSGVO i. V. m § 19 BDSG.
      <br><br><b class="blue-text text-darken-3"><i>easyDOAC</i></b> ist nicht in der Lage, Nutzer zu identifizieren. Mangels ausreichend identifizierender Merkmale ist <b class="blue-text text-darken-3"><i>easyDOAC</i></b> eine Zuordnung von Daten zu einem Nutzer nicht möglich. In diesen Fällen finden die Artikel 15 bis 20 keine Anwendung.
      <br><br><b class="blue-text text-darken-3">10. Information über Ihr Widerspruchsrecht nach Artikel 21 DSGVO</b>
      <br><br>10.1.Einzelfallbezogenes Widerspruchsrecht
      <br><br>Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender personenbezogener Daten, die aufgrund von Artikel 6 Abs. 1 f DSGVO (Datenverarbeitung auf der Grundlage einer Interessenabwägung) erfolgt, Widerspruch einzulegen; dies gilt auch, soweit gegeben, für ein auf diese Bestimmung gestütztes Profiling im Sinne von Artikel 4 Abs. 4 DSGVO. Siehe dazu insbesondere Ziff. 3.4. Legen Sie Widerspruch ein, werden wir Ihre personenbezogenen Daten nicht mehr verarbeiten dürfen, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
      <br><br>Widersprechen Sie der Verarbeitung für Zwecke der Direktwerbung, so werden wir Ihre personenbezogenen Daten nicht mehr für diese Zwecke verarbeiten.
      <br><br>10.2.Widerruf erteilter Einwilligungen
      <br><br>Sie können die, uns gegenüber erteilte, Einwilligung jederzeit widerrufen. Dies gilt auch für den Widerruf von Einwilligungserklärungen, die vor der Geltung der EU-DatenschutzGrundverordnung, also vor dem 25. Mai 2018, uns gegenüber erteilt worden sind. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt.
      <br><br>10.3.Wahrnehmung Widerspruch bzw. Widerruf erteilter Einwilligung
      <br><br>Der Widerspruch kann formfrei per E-Mail unter <a href="mailto:dataprivacy@bayoocare.com">dataprivacy@bayoocare.com</a> erfolgen. Bitte wenden Sie sich bzgl. Ihrer Datenschutzrechte unmittelbar an die Datenschutzbeauftragten.
      <br><br><b class="blue-text text-darken-3">11. Änderungsvorbehalt</b>
      <br><br><b class="blue-text text-darken-3"><i>easyDOAC</i></b> ist berechtigt, die Datenschutzerklärung jederzeit zu ändern, insbesondere diese an Änderungen der Rechtslage durch Gesetz oder Rechtsprechung anzupassen. Die jeweils aktuellste Fassung ist in der App einsehbar. Änderungen der Datenschutzbestimmung werden mit dem Tag ihrer Veröffentlichung an dieser Stelle wirksam.
      <br><br>
    </div>
    <a href="javascript:history.back()" class="btn blue darken-3 backKnopf">Zurück</a><br><br><br>
  </div>
</template>

<script>
export default {
  name: 'Datenschutz'
}
</script>

<style scoped>
#hauptdiv {
  width:97.5%;
  margin:auto;
}

.CEImpressum {
  width: 40px;
  padding-top:20px;
  padding-left: 0px;
}

.CELabel {
  width: 40px;
  margin-left: -9px
}

.leftZehn {
  width:97.5%;
  margin:auto;
}

#uberschrift {
  font-size:21px;
  margin-left:10px;
  margin-bottom:5px;
}

#logoBayoocare {
  max-width:300px;
  max-height:100px;
  margin-bottom:5px;
}

.backKnopf {
  margin-left: 10px;
}
</style>
