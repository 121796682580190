<template>
  <div class="container">
      <div class="row">
        <div class="col s12 m12">
            <div class="card">
                <div v-if="umgebung === 'live'" class="card-content">
                    <span class="card-title blue-text text-darken-3">{{ phrasen.confirmation_health }}</span>
                    <p><span v-html="phrasen.confirmation_text1"></span></p><br>
                    <p>
                        <label>
                            <input type="checkbox" class="filled-in" v-model="agb" />
                            <span class="checkboxText">{{ phrasen.confirmation_text2 }}<a href="/dokumente/agb.pdf" target="_blank" class="blue-text text-darken-3"><b>{{ phrasen.agbs }}</b></a> {{ phrasen.confirmation_text3 }}</span>
                        </label>
                    </p>
                    <br>
                    <p class="checkboxAbstand">
                        <label>
                            <input type="checkbox" class="filled-in" v-model="datenschutz" />
                            <span class="checkboxText">{{ phrasen.confirmation_text2 }}<a href="/dokumente/datenschutz.pdf" target="_blank" class="blue-text text-darken-3"><b>{{ phrasen.data_protection_guidelines }}</b></a> {{ phrasen.confirmation_text3 }}</span>
                        </label>
                    </p>
                    <br>
                    <p class="checkboxAbstand">
                        <label>
                            <input type="checkbox" class="filled-in" v-model="heilberuf" />
                            <span class="checkboxText">{{ phrasen.confirmation_health_checkbox }}</span>
                        </label>
                    </p>
                </div>
                <div v-if="umgebung === 'test'" class="card-content">
                  <span class="card-title blue-text text-darken-3">{{ phrasen.passwort_1 }}</span>
                  <p><span v-html="phrasen.passwort_2"></span></p><br>
                  <div class="input-field">
                    <input id="password" type="password" class="validate" ref="passwordInput" v-on:keyup.enter="bestaetigePasswort()">
                    <label for="password">Passwort</label>
                  </div>
                </div>
                <div class="card-action">
                    <a href="" v-if="!(agb && datenschutz && heilberuf) && umgebung === 'live'" class="grey-text" id="disabledButton">{{ phrasen.i_agree }}</a>
                    <a href="" v-if="agb && datenschutz && heilberuf && umgebung === 'live'" v-on:click="bestaetige()" class="blue-text text-darken-3"><b>{{ phrasen.i_agree }}</b></a>
                    <a v-if="umgebung === 'test'" v-on:click.stop="bestaetigePasswort()" class="blue-text text-darken-3 ok_button"><b>{{ phrasen.ok }}</b></a>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Disclaimer',
  props: ['phrasen', 'hashAPI', 'apiURL'],
  data: function () {
    return {
      agb: false,
      datenschutz: false,
      heilberuf: false,
      umgebung: 'live'
    }
  },
  methods: {
    bestaetige: function () {
      localStorage.bestaetigungArzt = true
      location.reload()
    },
    bestaetigePasswort: function () {
      var passwort = this.$refs.passwordInput.value
      var xhttp = new XMLHttpRequest()
      xhttp.onreadystatechange = function () {
        if (this.readyState === 4 && this.status === 200) {
          var data = JSON.parse(this.responseText)
          if (data.erfolgreich === 1) {
            localStorage.bestaetigungArzt = true
            location.reload()
          }
        }
      }
      xhttp.open('POST', this.apiURL + '?funktion=pruefePasswort' + this.hashAPI + '&passwort=' + passwort)
      xhttp.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')
      xhttp.send()
    },
    umgebungPruefen: function () {
      if (localStorage.umgebung === 'test') {
        this.umgebung = 'test'
      }
    }
  },
  mounted () {
    this.umgebungPruefen()
    if (localStorage.bestaetigungArzt === 'true') {
      this.$router.push({ name: '/' })
    }
  }
}
</script>

<style scoped>
.checkboxText {
    color: black;
}

#disabledButton {
    cursor: default;
}

.ok_button {
  cursor: pointer;
}
</style>
