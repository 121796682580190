<template>
    <div>
      <div class="modalContentDosierungHeader">
        <table>
          <tr class="trBorderNone">
            <td>
              <span class="modalDosierungSpan">HAS-BLED-Score</span>
            </td>
            <td>
            </td>
            <td class="closeModalTd">
              <img href="#!" class="modal-close right widthClose modal-close" src="/img/close.gif">
            </td>
          </tr>
        </table>
      </div>
      <div class="hilfeContent">
        <span class="feldbeschreibung">
          Der HAS-BLED-Score ermöglicht eine Einschätzung des Risikos für schwere Blutungen und wird in Guidelines für die Behandlung von Vorhofflimmern empfohlen [Hindricks 2020]. Dieser Score wurde ursprünglich in Patienten mit Vorhofflimmern und Vitamin-K-Antagonisten entwickelt [Pisters 2010], erlaubt jedoch auch in Patienten mit DOAKs eine orientierende Einschätzung [Yao 2017]. In Studien zeigte sich ein höheres Risiko ab einem HAS-BLED-Score von 3 Punkten [Pisters 2010; Zhu 2015], vermutlich gibt es einen kontinuierlichen Zusammenhang [Abumuaileq 2014].
          <br><br>
          Die Abschätzung des Risikos für Blutungskomplikationen …<br>
          <ul>
            <li class="listenpunkt">soll nicht als Entscheidungskriterium für die Indikationsstellung einer DOAK-Therapie verwendet werden [Hindricks 2020],</li>
            <li class="listenpunkt">kann zur Wahl der Dosierung beitragen, wenn eine Dosierung „nach individueller Beurteilung des thromboembolischen Risikos und des Blutungsrisikos“ empfohlen wird,</li>
            <li class="listenpunkt">kann nützlich sein, um modifizierbare Risikofaktoren zu identifizieren, die behoben werden können, um damit die Therapie sicherer zu machen.</li>
          </ul>
          <br>
          Die akkurate Anwendung setzt eine Kenntnis der genauen Kriterien voraus.<br>
          <ul>
            <li class="listenpunkt"><b>Hypertonus</b><br>
              Insbesondere bei systolischem Blutdruck > 160 mmHg.
            </li>
            <li class="listenpunkt"><b>Abnorme Nierenfunktion</b><br>
              Chron. Dialyse, Nierentransplantat oder Serum-Kreatinin > 2,26 mg/dl (> 200 µmol/l).
            </li>
            <li class="listenpunkt"><b>Abnorme Leberfunktion</b><br>
              Chron. Leberkrankheit (z. B. Zirrhose) oder signifikante biochemische Abnormalität (z. B. Bilirubin > 2 x OGW; ASAT = GOT, ALAT = GPT oder Alkalische Phosphatase > 3 x OGW).
            </li>
            <li class="listenpunkt"><b>Schlaganfall</b><br>
              Früherer Schlaganfall (ischämisch oder hämorrhagisch). Bei hämorrrhagischem Schlaganfall wird zusätzlich Blutungsneigung ausgewählt.
            </li>
            <li class="listenpunkt"><b>Blutungsneigung</b><br>
              Vorausgegangene schwere Blutung oder Prädisposition (z. B. schwere Thrombozytopenie).
            </li>
            <li class="listenpunkt"><b>Labile INR-Werte unter Vitamin K-Antagonisten</b><br>
              Labile INR oder Zeit im therapeutischen Bereich &lt; 60 %.
            </li>
            <li class="listenpunkt"><b>Elderly</b><br>
              Alter > 65 Jahre.
            </li>
            <li class="listenpunkt"><b>Drugs: Plättchenaggregationshemmer oder NSAR</b><br>
              Für Blutungen prädisponierende Medikamente, wie Thrombozytenaggregationshemmer und nicht-steroidale Antirheumatika (NSAR).
            </li>
            <li class="listenpunkt"><b>Drugs: Alkoholabusus</b><br>
              Übermäßiger Alkoholkonsum.
            </li>
          </ul>
          <br>
          <u>Abkürzungen</u><br>
        </span>
        <span class="literatur">
          ALT: Alanin-Aminotransferase, AP: alkalische Phosphatase, AST: Aspartat-Aminotransferase, GOT: Glutamat-Oxalacetat-Transaminase, GPT = Glutamat-Pyruvat-Transaminase, NSAR: nicht-steroidale Antirheumatika, OGW: Oberer Grenzwert
          <br><br>
        </span>
        <span class="feldbeschreibung">
          <u>Literatur</u><br>
        </span>
        <span class="literatur">
          Abumuaileq RR, Abu-Assi E, Raposeiras-Roubin S, López-López A, Redondo-Diéguez A, Álvarez-Iglesias D, Rodríguez-Mañero M, Peña-Gil C, González-Juanatey JR. Comparative evaluation of HAS-BLED and ATRIA scores by investigating the full potential of their bleeding prediction schemes in non-valvular atrial fibrillation patients on vitamin-K antagonists. Int J Cardiol 2014;176:1259-61.<br><br>
          Hindricks G, Potpara T, Dagres N, Arbelo E, Bax JJ, Blomström-Lundqvist C, Boriani G, Castella M, Dan GA, Dilaveris PE, Fauchier L, Filippatos G, Kalman JM, La Meir M, Lane DA, Lebeau JP, Lettino M, Lip GYH, Pinto FJ, Thomas GN, Valgimigli M, Van Gelder IC, Van Putte BP, Watkins CL. 2020 ESC Guidelines for the diagnosis and management of atrial fibrillation developed in collaboration with the European Association of Cardio-Thoracic Surgery (EACTS). Eur Heart J 2020 (ePub ahead of print).<br><br>
          Pisters R, Lane DA, Nieuwlaat R, de Vos CB, Crijns HJ, Lip GY. A novel user-friendly score (HAS-BLED) to assess 1-year risk of major bleeding in patients with atrial fibrillation: the Euro Heart Survey. Chest 2010;138:1093-100.<br><br>
          Yao X, Gersh BJ, Sangaralingham LR, Kent DM, Shah ND, Abraham NS, Noseworthy PA. Comparison of the CHA2DS2-VASc, CHADS2, HAS-BLED, ORBIT, and ATRIA risk scores in predicting non-vitamin K antagonist oral anticoagulants-associated bleeding in patients with atrial fibrillation. Am J Cardiol 2017;120:1549-56.<br><br>
          Zhu W, He W, Guo L, Wang X, Hong K. The HAS-BLED score for predicting major bleeding risk in anticoagulated patients with atrial fibrillation: a systematic review and meta-analysis. Clin Cardiol 2015;38:555-61.
        </span>
      </div>
    </div>
</template>

<script>
export default {
  name: 'HinweisHASBLED'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.modalContentDosierungHeader {
  background-color: rgb(21, 101, 192);
  color:white;
  padding:10px;
  box-shadow: 3px 3px 3px silver;
}

.trBorderNone {
  border: none;
}

.modalDosierungTd {
  width:25%;
  vertical-align:top;
  padding-top:7px;
  padding-bottom:7px;
}

.modalDosierungTd2 {
  width:50%;
  vertical-align:top;
  padding-top:7px;
  padding-bottom:7px;
}

.closeModalTd {
  vertical-align:top;
  padding-top:9px;
  padding-bottom:7px;
}

.widthClose {
  width:25px;
}

.modalDosierungSpan {
  font-size:18px;
  font-weight:bold;
}

.hilfeContent {
  margin-top: 5px;
  height: 52vh;
  overflow-x: auto;
  padding:13px;
}
.feldbeschreibung {
  font-size: calc(6px + 2.5vw) !important;
}
.literatur {
  font-size: calc(4px + 2vw) !important;
}
.listenpunkt {
  list-style-type: disc !important;
  margin-left: 20px;
}
@media screen and (min-width: 320px) {
  .feldbeschreibung {
    font-size: calc(6px + 2.5vw) !important;
  }
  .literatur {
    font-size: calc(4px + 2vw) !important;
  }
}
@media screen and (min-width: 600px) {
  .feldbeschreibung {
    font-size: 16px !important;
  }
  .literatur {
    font-size: 14px !important;
  }
  .hilfeContent {
    margin-top: 5px;
    height: 60vh;
    overflow-x: auto;
    padding:13px;
  }
}
</style>
