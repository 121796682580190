<template>
  <div id="app">
    <header>
      <nav class="nav-wrapper blue darken-3">
        <div class="container">
          <router-link to="/" class="app_title" >
            <table class="left" id="tableLogo">
              <tr id="trLogo">
                <td id="tdLogo">
                  <img id="imgLogo" src="/img/logo.gif" :style="'margin-left:' +  pixelLeftLogo +  'px;'">
                </td>
                <td id="tdAppName">
                  <span>{{ phrasen.appName }}</span>
                </td>
              </tr>
            </table>
          </router-link>
          <a href="#" class="sidenav-trigger right" data-target="mobile-menu" id="aMenu" :style="'margin-right: ' +  pixelRightMenu +  'px !important;margin-top:3.5px;'">
            <span style="font-weight:normal;font-size:25px;">&equiv;</span>
          </a>
          <div href="#" v-if="status == 'online'" class="green right onlineDiv" :style="'margin-right: ' +  pixelRightOnline +  'px !important;'"><span class="onlineDivSpan">online</span></div>
          <a href="#" v-if="status == 'offline'" class="red darken-3 right onlineDiv" :style="'margin-right: ' +  pixelRightOnline +  'px !important;'"><span class="onlineDivSpan">offline</span></a>
          <router-link to="/" id="linkStartseite"><img src="/img/haus.gif" class="right headerHome2 hide-on-large-only"></router-link>
          <ul class="right hide-on-med-and-down">
            <li class="width115"><img src="/img/haus.gif" class="left headerHome"><router-link to="/" id="linkStartseite">{{ phrasen.home }}</router-link></li>
            <li v-on:click="loggeDaten('feedback_aufruf')" class="width120"><img src="/img/feedback.gif" class="left headerFeedback"><router-link to="/feedback" id="linkStartseite">{{ phrasen.feedback }}</router-link></li>
            <li v-on:click="filterEntfernen(null)" class="width143"><img src="/img/delete.gif" class="left headerDelete"><a id="linkStartseite">{{ phrasen.erase_all_filter }}</a></li>
          </ul>
          <ul class="sidenav white" id="mobile-menu">
            <li v-on:click="menuSchliessen" id="liMobileMenu"><router-link to="/" id="aMobileMenu">{{ phrasen.home }}</router-link></li>
            <li v-on:click="menuSchliessen;loggeDaten('feedback_aufruf')" id="liMobileMenu"><router-link to="/feedback" id="aMobileMenu">{{ phrasen.feedback }}</router-link></li>
            <li v-on:click="filterEntfernen(null)" id="liMobileMenu"><a id="aMobileMenu">{{ phrasen.erase_all_filter }}</a></li>
            <li v-on:click="menuSchliessen" id="liMobileMenu"><a id="aMobileMenu">-------------------------</a></li>
            <li v-on:click="menuSchliessen" id="liMobileMenu"><a id="aMobileMenu">{{ phrasen.version }} {{ versionsnummer }}</a></li>
          </ul>
        </div>
      </nav>
      <nav v-if="deferredPrompt" ref="add_button1" class="nav-wrapper blue darken-3">
        <center @click="install">{{ phrasen.install_app }} <a href="#" class="red darken-3" id="installButton"><span >{{ phrasen.install }}</span></a></center>
      </nav>
      <nav v-if="updateExists" class="nav-wrapper blue darken-3">
        <center>{{ phrasen.new_version_available }} <a @click="refreshApp" class="red darken-3" id="installButton"><span >{{ phrasen.refresh }}</span></a></center>
      </nav>
    </header>
    <main class="paddingNull" :style="'margin-top:' +  pixelTopMain +  'px;'" >
      <div :class="hauptDivKlasse">
        <router-view :bestaetigungArzt="bestaetigungArzt" :phrasen="phrasen" :daten="daten"  :sperre="appSperren" :hashAPI="hashAPI" :apiURL="apiURL" v-if="bestaetigungReady && phrasenReady && datenReady && sperreFertig" />
        <div id="modal11" class="modal">
          <div class="modal-content">
            <h5 class="blue-text text-darken-3">{{ phrasen.version_history }}</h5>
            <p>{{ phrasen.this_version }} {{ versionsnummer }} ({{ versionsdatum }})
              <br>{{ phrasen.changes }}:
              <span v-for="(aenderung) in aenderungen" v-bind:key="aenderung">
                <br>- {{ aenderung }}
              </span>
            </p>
            <div v-if="Object.keys(vorgaengerversionen).length > 0">
              <h5 class="blue-text text-darken-3">{{ phrasen.previous_versions }}</h5>
              <p v-for="(datum, index) in vorgaengerversionen" v-bind:key="index">
                {{ index }} ({{ datum }})
                <span v-if="vorgaengeraenderungen[index][1] !== 'Initialversion'"><br>{{ phrasen.changes }}:</span>
                <span v-for="(aenderung) in vorgaengeraenderungen[index]" v-bind:key="aenderung">
                  <br>- {{ aenderung }}
                </span>
              </p>
            </div>
            <h5 class="blue-text text-darken-3">{{ phrasen.browsers }}</h5>
            <p>- Chrome 80+<br>
              - Firefox 76+<br>
              - Safari 10+<br>
              - Opera 58+<br>
              - IE 11+ (nur online)<br>
              - Edge
            </p>
            <h5 class="blue-text text-darken-3">{{ phrasen.min_displaysize }}</h5>
            <p>{{ phrasen.width }}: 360px<br>
              {{ phrasen.height }}: 640px
            </p>
          </div>
          <div class="modal-footer">
          <a href="#!" class="modal-close btn-flat" v-on:click="overflowEntfernen">{{ phrasen.ok }}</a>
          </div>
        </div>
      </div>
    </main>
    <footer class="page-footer blue darken-3">
      <div class="container paddingBottomFooter">
        <div id="kontaktFooter"><a href="/dokumente/easyDOAC_Benutzerhandbuch.pdf" target="_blank" class="white-text floatLeft" :style="'margin-left: ' +  pixelLeftKontakt +  'px;'" v-on:click="loggeDaten('benutzer_hb_aufruf')"><span v-html="phrasen.manual"></span></a></div>
        <div id="impressumFooter"><center><router-link to="/datenschutz" class="white-text"><span v-on:click="loggeDaten('datenschutz_aufruf')">{{ phrasen.data_protection }}</span></router-link></center></div>
        <div id="impressumFooter2"><center><router-link to="/impressum" class="white-text"><span v-on:click="loggeDaten('impressum_aufruf')">{{ phrasen.imprint }} / {{ phrasen.agbs }}</span></router-link></center></div>
        <div id="datenschutzFooter"><router-link to="/kontakt" class="white-text" :style="'float:right;margin-right: ' +  pixelRightDatenSchutz +  'px;'"><span v-on:click="loggeDaten('kontakt_aufruf')">{{ phrasen.contact }}</span></router-link></div>
      </div>
        <br><br>
      <div class="footer-copyright blue darken-4">
        <div class="container center-align">
          <div class="marginLeft40">&#169; 2022 Universitätsklinikum Heidelberg</div>
          <table>
            <tr class="trBorderNone">
              <td class="CETableLeft">
                <a class="modal-trigger" href="#modal11" v-on:click="loggeDaten('versionspopup_aufruf')">{{ phrasen.version }} {{ versionsnummer }}</a>
              </td>
              <td class="CETableMiddle">
                <img class="widthClose" src="/img/ce.gif">
              </td>
              <td class="CETableRight">
                <span class="blue-text">{{ phrasen.status }} {{ datumDaten }}</span>
              </td>
            </tr>
          </table>
          <a href="/dokumente/DOAK.pdf" target="_blank" v-on:click="loggeDaten('doak_pdf_aufruf')">DOAK Datenübersicht (PDF)</a>
        </div>
      </div>
    </footer>
    <div v-if="meldungGroesse || datenZuAltMeldung || datenZuAltHinweis || updateErfolgreich || versionVeraltet" class="black overflowGrau" ></div>
    <div v-if="meldungGroesse" id="modalGroesse" class="modal">
      <div class="modal-content padding5">
        <div class="modalContentDosierungHeader">
          <table>
            <tr class="trBorderNone">
              <td class="modalDosierungTd">
                <span class="modalDosierungSpan">{{ phrasen.note }}</span>
              </td>
              <td class="modalDosierungTd2">
              </td>
              <td class="closeModalTd">
                <img href="#!" class="modal-close right widthClose" v-on:click="fensterGroesseSchliessen" src="/img/close.gif">
              </td>
            </tr>
          </table>
        </div>
        <br>
        <div class="padding13">{{ phrasen.text_display_size }}</div>
        <br>
      </div>
    </div>
    <div v-if="datenZuAltMeldung" id="modalGroesse" class="modal">
      <div class="modal-content padding5">
        <div class="modalContentDosierungHeader">
          <table>
            <tr class="trBorderNone">
              <td class="modalDosierungTd">
                <span class="modalDosierungSpan">{{ phrasen.note }}</span>
              </td>
              <td class="modalDosierungTd2">
              </td>
              <td class="closeModalTd">
                <img href="#!" class="modal-close right widthClose" v-on:click="fensterDatenZuAltSchliessen" src="/img/close.gif">
              </td>
            </tr>
          </table>
        </div>
        <br>
        <div class="padding13">{{ phrasen.data_to_old_note }}</div>
        <br>
      </div>
    </div>
    <div v-if="versionVeraltet" id="modalGroesse" class="modal">
      <div class="modal-content padding5">
        <div class="modalContentDosierungHeader">
          <table>
            <tr class="trBorderNone">
              <td class="modalDosierungTd">
                <span class="modalDosierungSpan">{{ phrasen.note }}</span>
              </td>
              <td class="modalDosierungTd2">
              </td>
              <td class="closeModalTd">
              </td>
            </tr>
          </table>
        </div>
        <br>
        <div class="padding13">
          {{ phrasen.version_old_note }}<br><br>
          <a class="btn blue darken-3 right updateCompleteButton" v-on:click="fensterVersionVeraltetSchliessen">{{ phrasen.update_now }}</a>
        </div>
        <br>
      </div>
    </div>
    <div v-if="datenZuAltHinweis" id="modalGroesse" class="modal">
      <div class="modal-content padding5">
        <div class="modalContentDosierungHeader">
          <table>
            <tr class="trBorderNone">
              <td class="modalDosierungTd">
                <span class="modalDosierungSpan">{{ phrasen.note }}</span>
              </td>
              <td class="modalDosierungTd2">
              </td>
              <td class="closeModalTd">
                <img href="#!" class="modal-close right widthClose" v-on:click="fensterDatenHinweisSchliessen" src="/img/close.gif">
              </td>
            </tr>
          </table>
        </div>
        <br>
        <div class="padding13">{{ phrasen.data_to_old_note2 }}</div>
        <br>
      </div>
    </div>
    <div v-if="updateErfolgreich" id="modalGroesse" class="modal">
      <div class="modal-content padding5">
        <div class="modalContentDosierungHeader">
          <table>
            <tr class="trBorderNone">
              <td class="modalDosierungTd">
                <span class="modalDosierungSpan">{{ phrasen.note }}</span>
              </td>
              <td class="modalDosierungTd2">
              </td>
              <td class="closeModalTd">
                <img href="#!" class="modal-close right widthClose" v-on:click="fensterUpdateErfolgreichSchliessen" src="/img/close.gif">
              </td>
            </tr>
          </table>
        </div>
        <br>
        <div class="padding13"><span style="font-size: 140%;">{{ phrasen.update_succesfull }}</span><br><br>{{ phrasen.update_succesfull1 }}<br>{{ phrasen.update_succesfull2 }} <a style="cursor:pointer;" class="blue-text text-darken-3" v-on:click="fensterUpdateErfolgreichSchliessenPlusDetails">{{ phrasen.update_succesfull3 }}</a> {{ phrasen.update_succesfull4 }}</div>
        <a class="btn blue darken-3 right updateCompleteButton" v-on:click="fensterUpdateErfolgreichSchliessen">OK</a>
        <br>
      </div>
    </div>
  </div>
</template>

<script>
import M from 'materialize-css/dist/js/materialize.min'

export default {
  name: 'App1',
  data: function () {
    return {
      pixelTopMain: 0,
      pixelLeftLogo: 0,
      pixelRightMenu: 0,
      pixelRightOnline: 0,
      pixelLeftKontakt: 0,
      pixelRightDatenSchutz: 0,
      hauptDivKlasse: '',
      status: 'offline',
      versionsnummer: '1.11',
      versionsdatum: '20.10.2023',
      aenderungen: {
        1: 'Update der Markt- und Wissensdaten für DOAC und Komedikationseinträge'
      },
      vorgaengerversionen: { 'Version 1.10': '18.07.2023', 'Version 1.9': '27.03.2023', 'Version 1.8': '19.12.2022', 'Version 1.7': '13.10.2022', 'Version 1.6': '04.08.2022', 'Version 1.5': '08.04.2022', 'Version 1.4': '17.12.2021', 'Version 1.3': '09.09.2021', 'Version 1.2': '24.06.2021', 'Version 1.1': '14.04.2021', 'Version 1.0': '02.03.2021' },
      vorgaengeraenderungen: {
        'Version 1.10': {
          1: 'Update der Markt- und Wissensdaten für DOAC und Komedikationseinträge'
        },
        'Version 1.9': {
          1: 'Update der Markt- und Wissensdaten für DOAC und Komedikationseinträge'
        },
        'Version 1.8': {
          1: 'Update der Markt- und Wissensdaten für DOAC und Komedikationseinträge'
        },
        'Version 1.7': {
          1: 'Update der Markt- und Wissensdaten für DOAC und Komedikationseinträge',
          2: 'Update der Fachinformationen',
          3: 'Optimierung: Bei den Therapiekosten und Angaben zur Nutzenbewertung wird jetzt deutlicher gekennzeichnet, dass es sich um Übersichtsinformationen handelt und ggf. gesetzte Filter hier keinen Einfluss haben',
          4: 'Bugfix: Die textuellen Hervorhebungen bei den Indikationen wurden in der Dosierungsanzeige nicht korrekt dargestellt'
        },
        'Version 1.6': {
          1: 'Optimierung: easyDOAC steht nun auch in den Appstores von Google und Apple zur Verfügung',
          2: 'Optimierung: In den Dosierungshinweisen und den Hinweisen zu Kontraindikationen werden die Hinweistexte nun alphabetisch zur Komedikation sortiert',
          3: 'Optimierung: Sollte easyDOAC Probleme haben, auf die Offlinedaten zuzugreifen, wird easyDOAC nun automatisch neu geladen',
          4: 'Optimierung: easyDOAC aktualisiert sich selbstständig. Hat man easyDOAC aber länger nicht benutzt und liegt eine deutlich neuere Version vor, muss man nun erst die neue Version aktivieren, bevor man easyDOAC weiter nutzen kann'
        },
        'Version 1.5': {
          1: 'Update der Markt- und Wissensdaten für DOAC und Komedikationseinträge',
          2: 'Update der Fachinformationen',
          3: 'Optimierung: Bei der Auswahl der Nierenfunktion wird ein Hilfetext jetzt auch am Nierenreiter angezeigt und nicht nur innerhalb der Schätzfunktion',
          4: 'Optimierung: Bei der Auswahl der Nierenfunktion wird ein eingegebener Kreatininwert bzw. die geschätzte Clearance nun direkt und übersichtlicher am Button für die Nierenfunktionsschätzung angezeigt',
          5: 'Optimierung: Es erscheint nun ein kurzer Hinweis, wenn eine neue Version von easyDOAC nach Update aktiv ist'
        },
        'Version 1.4': {
          1: 'Update der Markt- und Wissensdaten für DOAC und Komedikationseinträge',
          2: 'Update der Fachinformationen',
          3: 'Erweiterung des Impressum'
        },
        'Version 1.3': {
          1: 'Update der Markt- und Wissensdaten für DOAC und Komedikationseinträge',
          2: 'Update der Fachinformationen und Dokumente zur Nutzenbewertung',
          3: 'Optimierung: für Apixaban wurde bei fehlendem Kreatininwert ein Hinweis auf eine mögliche Dosisreduktion ergänzt',
          4: 'Bugfix: eine falsche Sprachangabe führte in manchen Browsern zu einer Übersetzung einzelner Wörter',
          5: 'Bugfix: eine Kombination, die nicht eingesetzt werden sollte, wurde nicht grau dargestellt'
        },
        'Version 1.2': {
          1: 'Umfangreiche Optimierung der Auswahl von Komedikationseinträgen sowohl für die mobile Verwendung als auch in der Desktopversion',
          2: 'Einbettung aller Fachinformationen direkt als offline aufrufbare PDF-Dokumente',
          3: 'Umfangreiches Update der Markt- und Wissensdaten für DOAC und Komedikationseinträge',
          4: 'Optimierung des Layouts (Hilfebuttons, Kreatininwerte, Therapiekostenbutton)',
          5: 'Bugfix: in der Nierenfunktionsschätzung führte der Klick auf das Infoicon zum Löschen aller Eingaben',
          6: 'Bugfix: in der mobilen Ansicht führte das Löschen aller Filter zur Anzeige eines weißen Bildschirms',
          7: 'Bugfix: der Hinweis auf einen zu kleinen Bildschirm wurde im Grenzbereich zu oft angezeigt'
        },
        'Version 1.1': {
          1: 'Erweiterung der statistischen Datenerhebung'
        },
        'Version 1.0': {
          1: 'Initialversion'
        }
      },
      datumDaten: '',
      deferredPrompt: null,
      mindestBreite: 360,
      mindestHoehe: 640,
      meldungGroesse: false,
      bestaetigungArzt: false,
      bestaetigungReady: false,
      phrasen: {},
      phrasenReady: false,
      dataToSend: {},
      apiURL: '',
      tageOffline: 90, // solange ist es erlaubt, die App offline zu nutzen
      datenZuAltMeldung: false, // Meldung zur Zwangsaktualisierung
      registration: null, // nachdem festgestellt wurde, dass es einen neuen SW gibt, werden dessen Details hier abgelegt
      updateExists: false, // diese Variable schaltet den "Button" zum Aktualisieren der App frei
      tageOfflineHinweis: 30, // Tage offline bis zum Aktualisierungshinweis
      datenZuAltHinweis: false, // Hinweis zur baldigen Aktualisierung
      daten: {}, // hierher werden die Daten aus der daten.json eingelesen
      datenReady: false, // sobald Daten aus der daten.json geladen, wird diese Variable auf true gesetzt
      appSperren: false, // wird auf true gesetzt, wenn eine App-Sperre über API erfolgt
      appSperreIntervalMinuten: 30, // Intervall zum Prüfen des SecurityStops
      appSperreText: '', // Der Text, der bei der App-Sperre angezeigt werden soll. Wird über die API übergeben
      sperreFertig: false, // Zeigt an, ob bereits gecheckt wurde, ob eine Sperre vorliegt
      hashAPI: '2e01473b125f53483408800b557144f67810789de449179e1be98ea9d74040da', // wird zur Kommunikation mit dem Backend gebraucht
      hashLogging: '', // dieser Wert wird beim Starten der App neu erstellt und für die Identifiaktion beim Loggen der Daten benutzt
      updateErfolgreich: false, // zeigt an, ob ein Update erfolgreich stattgefunden hat
      versionVeraltet: false // gibt an, ob die verwendete Version mehr als ein Release zurück liegt.
    }
  },
  methods: {
    // Lade alle Textbausteine (Vorbereitung für Übersetzung)
    holePhrasen: function () {
      if (!('fetch' in window)) {
        var comp = this
        var xhttp = new XMLHttpRequest()
        xhttp.onreadystatechange = function () {
          if (this.readyState === 4 && this.status === 200) {
            comp.phrasen = JSON.parse(this.responseText)
            comp.phrasenReady = true
          }
        }
        xhttp.open('GET', 'phrasen.json')
        xhttp.send()
      } else {
        fetch('phrasen.json')
          .then((response) => response.json())
          .then(result => {
            this.phrasen = result
            this.phrasenReady = true
          })
      }
    },
    // Lade Wissensbasis-Daten
    holeDaten: function () {
      if (!('fetch' in window)) {
        var comp = this
        var xhttp = new XMLHttpRequest()
        xhttp.onreadystatechange = function () {
          if (this.readyState === 4 && this.status === 200) {
            comp.daten = JSON.parse(this.responseText)
            comp.datumDaten = comp.daten.datenstand
            comp.datenReady = true
          }
        }
        xhttp.open('GET', 'daten.json')
        xhttp.send()
      } else {
        fetch('daten.json')
          .then((response) => response.json())
          .then(result => {
            this.daten = result
            this.datumDaten = this.daten.datenstand
            this.datenReady = true
          })
      }
    },
    // Lade Konfigurationsdaten
    configDatenLaden: function () {
      if (!('fetch' in window)) {
        var comp = this
        var xhttp = new XMLHttpRequest()
        xhttp.onreadystatechange = function () {
          if (this.readyState === 4 && this.status === 200) {
            var daten = JSON.parse(this.responseText)
            comp.apiURL = daten.apiURL
            localStorage.umgebung = daten.umgebung
            localStorage.maxLogEintraege = parseInt(daten.maxLogEintraege)
            comp.checkSperre()
            comp.checkVersion()
            comp.sendeFeedback()
            comp.sendeLogDaten()
          }
        }
        xhttp.open('GET', 'config.json')
        xhttp.send()
      } else {
        fetch('config.json')
          .then((response) => response.json())
          .then(result => {
            this.apiURL = result.apiURL
            localStorage.umgebung = result.umgebung
            localStorage.maxLogEintraege = parseInt(result.maxLogEintraege)
            this.checkSperre()
            this.checkVersion()
            this.sendeFeedback()
            this.sendeLogDaten()
          })
      }
    },
    menuSchliessen: function () {
      var elems = document.querySelectorAll('.sidenav')
      var instances1 = M.Sidenav.init(elems, [])
      instances1[0].close()
    },
    gebeStatus: function (event) {
      if (navigator.onLine) {
        this.status = 'online'
        const date1 = new Date()
        localStorage.letzterCheckOnline = date1
        localStorage.removeItem('datenZuAltHinweisErhalten')
      } else {
        this.status = 'offline'
        if (localStorage.letzterCheckOnline != null) {
          const date1 = new Date()
          const date2 = new Date(localStorage.letzterCheckOnline)
          var differenz = Math.round((date1 - date2) / 1000)
          var tageOfflineSekunden = this.tageOffline * 24 * 60 * 60
          if (differenz >= tageOfflineSekunden) {
            // viel zu alt. App wird gesperrt. SW wird gelöscht. Reload-Hinweis angezeigt.
            this.datenZuAltMeldung = true
            if ('serviceWorker' in navigator) {
              navigator.serviceWorker.ready
                .then(registration => registration.active.postMessage('loesche_assets'))
            }
          }
          if (localStorage.datenZuAltHinweisErhalten == null) {
            var tageOfflineHinweisSekunden = this.tageOfflineHinweis * 24 * 60 * 60
            if (differenz >= tageOfflineHinweisSekunden) {
              this.datenZuAltHinweis = true
            }
          }
        }
      }
    },
    overflowEntfernen: function () {
      var elem1 = document.body
      elem1.style.overflow = ''
    },
    CSSAnpassen: function () {
      if (window.innerWidth < 600) {
        this.pixelTopMain = 0
        this.pixelLeftLogo = -10
        this.pixelRightMenu = -7
        this.pixelRightOnline = 5
        this.pixelLeftKontakt = -7
        this.pixelRightDatenSchutz = -7
        this.hauptDivKlasse = ''
      } else if (window.innerWidth >= 600 && window.innerWidth < 993) {
        this.pixelTopMain = 3
        this.pixelLeftLogo = 27
        this.pixelRightMenu = 30
        this.pixelRightOnline = 5
        this.pixelLeftKontakt = 10
        this.pixelRightDatenSchutz = 8
        this.hauptDivKlasse = 'container'
      } else {
        this.pixelTopMain = 3
        this.pixelLeftLogo = 20
        this.pixelRightOnline = 10
        this.pixelLeftKontakt = 18
        this.pixelRightDatenSchutz = 8
        this.hauptDivKlasse = 'container'
      }
    },
    fensterGroesseSchliessen: function () {
      this.meldungGroesse = false
      localStorage.fensterGroesseSchliessen = true
    },
    fensterDatenZuAltSchliessen: function () {
      window.location.reload()
    },
    fensterVersionVeraltetSchliessen: function () {
      window.location.reload()
    },
    fensterDatenHinweisSchliessen: function () {
      this.datenZuAltHinweis = false
      localStorage.datenZuAltHinweisErhalten = true
    },
    fensterUpdateErfolgreichSchliessen: function () {
      this.updateErfolgreich = false
      localStorage.removeItem('updateErfolgreich')
    },
    fensterUpdateErfolgreichSchliessenPlusDetails: function () {
      this.updateErfolgreich = false
      localStorage.removeItem('updateErfolgreich')
      var singleModalElem = document.querySelector('#modal11')
      var instance = M.Modal.getInstance(singleModalElem)
      instance.open()
    },
    // In dieser Funktion wird überprüft, ob es an die API zu sendende Daten gibt und gegebenfalls versendet.
    sendeDaten: async function (dataToSend) {
      if (Object.keys(dataToSend).length > 0) {
        const formData = new FormData()
        for (const [key1, value1] of Object.entries(dataToSend)) {
          formData.append(key1, value1)
        }
        var xhttp = new XMLHttpRequest()
        xhttp.onreadystatechange = function () {
          if (this.readyState === 4 && this.status === 200) {
            // var daten = JSON.parse(this.responseText)
            // console.log(daten)
          }
        }
        xhttp.open('POST', this.apiURL + '?funktion=sendeDaten')
        xhttp.send(formData)
      }
    },
    updateAvailable (event) {
      this.registration = event.detail
      this.updateExists = true
    },
    refreshApp () {
      this.updateExists = false
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) return
      // send message to SW to skip the waiting and activate the new SW
      localStorage.updateErfolgreich = true
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    },
    async install () {
      this.deferredPrompt.prompt()
    },
    filterEntfernen: function (id) {
      if (id == null) {
        this.loggeDaten('filter_entfernen_global')
      }
      var usgewaehlteDatenJson2 = JSON.stringify([])
      localStorage.ausgewaehlte_daten = usgewaehlteDatenJson2
      localStorage.ausgewaehlte_daten_gruppen = usgewaehlteDatenJson2
      localStorage.kreatinin = null
      localStorage.removeItem('kreatinin_clearance')
      localStorage.alter_nierenberechnung = null
      localStorage.gewicht_nierenberechnung = null
      localStorage.geschlecht_nierenberechnung = null
      this.menuSchliessen()
      var event
      if (typeof (Event) === 'function') {
        event = new Event('filterEntfernt')
      } else {
        event = document.createEvent('Event')
        event.initEvent('filterEntfernt', true, true)
      }
      document.dispatchEvent(event)
    },
    // hier wird geprüft, ob die benutze Version bereits veraltet ist
    checkVersion: function () {
      var comp = this
      var xhttp = new XMLHttpRequest()
      xhttp.onreadystatechange = function () {
        if (this.readyState === 4 && this.status === 200) {
          var data = JSON.parse(this.responseText)
          if (data.veraltet !== null && data.veraltet === 1) {
            comp.versionVeraltet = true
            if ('serviceWorker' in navigator) {
              navigator.serviceWorker.ready
                .then(registration => registration.active.postMessage('loesche_assets'))
            }
          }
        }
      }
      xhttp.open('GET', this.apiURL + '?funktion=checkVersion' + this.hashAPI + '&version=' + this.versionsnummer)
      xhttp.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')
      xhttp.send()
    },
    // hier wird geprüft, ob eine App-Sperre erfolgen soll
    checkSperre: function () {
      // hier wird im Localstorage geschaut, ob bereits eine Sperre vorliegt
      if (localStorage.letzterCheckSperre != null && localStorage.letzterCheckSperre === 'true') {
        this.appSperren = true
        this.appSperreText = localStorage.letzterCheckSperreText
      } else {
        this.appSperren = false
      }

      if (this.status === 'online') {
        var checkDate = new Date()
        var checkDateLetzterCheck = 0
        var differenz = (this.appSperreIntervalMinuten + 1) * 1000 * 60
        // Prüfe, ob schon mal nach einer Sperre geschaut wurde und rechne ggf. die Different aus
        if (localStorage.letzterCheckSperreZeit != null) {
          checkDateLetzterCheck = new Date(localStorage.letzterCheckSperreZeit)
          differenz = (checkDate - checkDateLetzterCheck)
        }
        var diffMins = Math.round(differenz / 1000 / 60)
        // ist die App gesperrt bzw. sind 30 Minuten seit dem letzten Check vergangen
        if (diffMins >= this.appSperreIntervalMinuten || this.appSperren) {
          var xhttp = new XMLHttpRequest()
          var comp = this
          xhttp.onreadystatechange = function () {
            if (this.readyState === 4 && this.status === 200) {
              var data = JSON.parse(this.responseText)
              if (data.sperre != null) {
                localStorage.letzterCheckSperre = data.sperre
                localStorage.letzterCheckSperreZeit = checkDate
                if (data.sperre === true) {
                  comp.appSperren = true
                  if (data.sperreText != null) {
                    comp.appSperreText = data.sperreText
                    localStorage.letzterCheckSperreText = data.sperreText
                  }
                } else {
                  comp.appSperren = false
                }
              }
              comp.sperreFertig = true
            }
            if (this.readyState === 4 && this.status !== 200) {
              comp.sperreFertig = true
            }
          }
          xhttp.open('GET', this.apiURL + '?funktion=checkSperre' + this.hashAPI)
          xhttp.send()
        } else {
          this.sperreFertig = true
        }
      } else {
        this.sperreFertig = true
      }
    },
    // hier werden Feedback-Daten an das Backend gesendet. Bei Erfolg werden diese aus dem LocalStorage gelöscht
    sendeFeedback: async function () {
      if (navigator.onLine) {
        if (localStorage.feedback != null) {
          var xhttp = new XMLHttpRequest()
          xhttp.onreadystatechange = function () {
            if (this.readyState === 4 && this.status === 200) {
              var data = JSON.parse(this.responseText)
              if (data.erfolgreich === 1) {
                localStorage.removeItem('feedback')
              }
            }
          }
          xhttp.open('POST', this.apiURL + '?funktion=feedback' + this.hashAPI)
          xhttp.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')
          xhttp.send('feedback=' + localStorage.feedback + '&app_version=' + this.versionsnummer)
        }
      }
    },
    // hier werden bestimmte Aktionen aus diesem Vue geloggt und im LocalStorage abgelegt
    loggeDaten: function (id) {
      // hier wird geprüft, ob der User gerade online oder offline ist.
      var statusJetzt = 'offline'
      if (navigator.onLine) {
        statusJetzt = 'online'
      }
      // hier werden die LoggDaten aus dem LocalStorage in ein Array eingelesen und eine Kopie von den LoggDaten aus dem LocalStorage erstellt.
      var loggDaten = []
      var localStorageLoggDatenKopie = null
      if (localStorage.loggDaten != null) {
        loggDaten = JSON.parse(localStorage.loggDaten)
        localStorageLoggDatenKopie = localStorage.loggDaten
      }
      var maxLogEintraege = parseInt(localStorage.maxLogEintraege)
      // wenn die Loggsaten, die Maximallänge nicht überschreiten, dann wird der neue Eintrag in die Daten eingefügt.
      if (loggDaten.length < maxLogEintraege) {
        var neuerLogg = { aktion: id, datum: new Date(), status: statusJetzt }
        loggDaten.push(JSON.stringify(neuerLogg))
        var loggDatenAktuell = JSON.stringify(loggDaten)
        // hier wird versucht, die erweiterten Loggdaten in den LocalStorage zu schreiben. Wenn das schiefläuft, dann werden die alten Loggdaten in den LocalStorage geschrieben.
        try {
          localStorage.loggDaten = loggDatenAktuell
        } catch (e) {
          localStorage.loggDaten = localStorageLoggDatenKopie
        }
      }
    },
    // hier werden die im LocalStorage gesammelten Loggdaten an das Backend gesendet
    sendeLogDaten: async function () {
      if (navigator.onLine) {
        if (localStorage.loggDaten != null) {
          var loggDatenTemp = localStorage.loggDaten
          localStorage.removeItem('loggDaten')
          var userAgentTemp = ''
          if (localStorage.userAgent != null) {
            userAgentTemp = '&useragent=' + localStorage.userAgent
            localStorage.removeItem('userAgent')
          }
          var xhttp = new XMLHttpRequest()
          xhttp.onreadystatechange = function () {
            if (this.readyState === 4 && this.status === 200) {
              var data = JSON.parse(this.responseText)
              if (data.erfolgreich !== 1) {
                var loggDaten2 = []
                if (localStorage.loggDaten != null) {
                  loggDaten2 = JSON.parse(localStorage.loggDaten)
                }
                var gesendeteDaten2 = JSON.parse(loggDatenTemp)
                for (var i2 = 0; i2 < gesendeteDaten2.length; i2++) {
                  loggDaten2.push(gesendeteDaten2[i2])
                }
                var loggDatenAktuell2 = JSON.stringify(loggDaten2)
                localStorage.loggDaten = loggDatenAktuell2
              }
            } else if (this.readyState === 4 && this.status !== 200) {
              var loggDaten = []
              if (localStorage.loggDaten != null) {
                loggDaten = JSON.parse(localStorage.loggDaten)
              }
              var gesendeteDaten = JSON.parse(loggDatenTemp)
              for (var i = 0; i < gesendeteDaten.length; i++) {
                loggDaten.push(gesendeteDaten[i])
              }
              var loggDatenAktuell = JSON.stringify(loggDaten)
              localStorage.loggDaten = loggDatenAktuell
            }
          }
          xhttp.open('POST', this.apiURL + '?funktion=loggdaten' + this.hashAPI)
          xhttp.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')
          xhttp.send('loggdaten=' + loggDatenTemp + '&hashLogging=' + this.hashLogging + '&app_version=' + this.versionsnummer + userAgentTemp)
        }
      }
    }
  },
  created () {
    window.addEventListener('beforeinstallprompt', e => {
      e.preventDefault()
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e
    })
    window.addEventListener('appinstalled', () => {
      this.deferredPrompt = null
    })
    // Listen for our custom event from the SW registration
    document.addEventListener('swUpdated', this.updateAvailable, { once: true })

    // Prevent multiple refreshes
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return
      this.refreshing = true
      // Here the actual reload of the page occurs
      window.location.reload()
    })
  },
  mounted () {
    // hier wird ein Hash erstellt, der für die Identifiaktion beim Loggen der Daten benutzt wird
    var currentDate = (new Date()).valueOf().toString()
    var random = (Math.round(Math.random() * 10000000000) / 10000000000).toString()
    this.hashLogging = currentDate + random
    localStorage.userAgent = window.navigator.userAgent

    M.AutoInit()
    this.filterEntfernen('start')
    this.holePhrasen()
    this.holeDaten()
    this.configDatenLaden()
    this.gebeStatus()
    // Hier ist ein Intervat festgelegt, in dem die Loggdaten an das Backend gesendet werden sollen
    setInterval(this.sendeLogDaten, 30000)
    window.addEventListener('online', this.gebeStatus)
    window.addEventListener('online', this.sendeDaten(this.dataToSend))
    window.addEventListener('offline', this.gebeStatus)
    window.addEventListener('resize', this.CSSAnpassen)
    window.addEventListener('feedback', this.sendeFeedback)
    window.addEventListener('online', this.sendeFeedback)
    this.CSSAnpassen()
    if ((screen.width < this.mindestBreite || screen.height < this.mindestHoehe) && localStorage.fensterGroesseSchliessen == null) {
      this.meldungGroesse = true
    }
    if (localStorage.bestaetigungArzt != null) {
      this.bestaetigungArzt = true
      this.bestaetigungReady = true
    } else {
      this.bestaetigungReady = true
    }

    if (localStorage.updateErfolgreich != null) {
      this.updateErfolgreich = true
    }
  }
}
</script>

<style>
.updateCompleteButton {
  margin-right:13px;
  margin-bottom:20px;
}

#tableLogo {
  margin-top: 8px;
  line-height: 0px;
  width:100px;
}

#trLogo {
  line-height: 0px;
  border:none;
}

#imgLogo {
  width:50px;
}

#tdLogo {
  padding:0px;
  padding-right:10px;
  line-height: 0px
}

.marginLeft40 {
  margin-left: 40px;
}

.paddingBottomFooter {
  padding-bottom: 15px;
}

.CETableLeft {
  text-align:right;
  width:48%;
  padding:0px;
  padding-right:10px;
}

.CETableMiddle {
  width:26px;
  padding:0px;
}

.CETableRight {
  text-align:left;
  padding:0px;
  padding-left:10px;
}

#tdAppName {
  padding:0px;
  vertical-align:middle;
}

#aMenu {
  margin-left: 12px !important;
}

.width115 {
  width: 115px;
}

.width120 {
  width: 120px;
}

.width143 {
  width: 143px;
}

.headerDelete {
  width:15px;
  margin-top:18px;
  margin-right:7px;
}

.headerFeedback {
  width:25px;
  margin-top:22px;
  margin-right:7px;
}

.headerHome {
  width:20px;
  margin-top:19px;
  margin-right:7px;
}

.headerHome2 {
  width:20px;
  margin-top:19px;
  margin-right:14px;
}

.onlineDiv {
  height: 20px !important;
  width: 50px !important;
  margin-top: 22px;
  line-height:20px;
  border-radius: 5px;
}

.modalContentDosierungHeader {
  background-color: rgb(21, 101, 192);
  color:white;
  padding:10px;
  box-shadow: 3px 3px 3px silver;
}

.trBorderNone {
  border: none;
}

.modalDosierungTd {
  width:40%;
  vertical-align:top;
  padding-top:7px;
  padding-bottom:7px;
}

.modalDosierungTd2 {
  width:50%;
  vertical-align:top;
  padding-top:7px;
  padding-bottom:7px;
}

.closeModalTd {
  vertical-align:top;
  padding-top:9px;
  padding-bottom:7px;
}

.widthClose {
  width:25px;
}

.modalDosierungSpan {
  font-size:18px;
  font-weight:bold;
}

.padding5 {
  padding: 5px !important;
}

.padding13 {
  padding: 13px;
}

.onlineDivSpan {
  margin-left:6px;
}

#mobile-menu {
  width:50% !important;
}

#aMobileMenu {
  color:#1565C0;
  padding-left:20px;
}

#liMobileMenu {
  height:60px;
}

.paddingNull {
  padding-top:0px !important;
}

#kontaktFooter {
  width:22%;
  float:left;
}

#impressumFooter {
  width:33%;
  float:left;
}

#impressumFooter2 {
  width:23%;
  float:left;
}

.floatLeft {
  float:left;
}

#datenschutzFooter {
  width:22%;
  float:left;
}

#installButton {
  height: 20px !important;
  width: 250px !important;
  margin-top: 18px;
  line-height:20px;
  border-radius: 5px;
  padding:5px;
  cursor: pointer;
}

.overflowGrau {
  height:100vh;
  width:100%;
  left:0;
  position:absolute;
  z-index: 1004;
  top:0px;
  opacity: 0.5;
}

#modalGroesse {
  z-index: 1005;
  display: block;
  opacity: 1;
  top: 10%;
  transform: scaleX(1) scaleY(1);
}

.app_title {
  font-size: calc(12px + 2.3vw) !important;
}
@media screen and (min-width: 320px) {
  .app_title {
    font-size: calc(12px + 2.3vw) !important;
  }
}
@media screen and (min-width: 600px) {
  .app_title {
    font-size: 22px !important;
  }
}

@media screen and (min-width: 689px) {
  .paddingBottomFooter {
    padding-bottom: 0px;
  }
}
[type="checkbox"].filled-in:checked + span:not(.lever):after {
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid rgb(112, 166, 228);
  background-color: #1565C0;
  z-index: 0;
}
</style>
