<template>
    <div>
      <div class="modalContentDosierungHeader">
        <table>
          <tr class="trBorderNone">
            <td>
              <span class="modalDosierungSpan">{{ phrasen.guess_kidney_function }}</span>
            </td>
            <td>
            </td>
            <td class="closeModalTd">
              <img href="#!" class="modal-close right widthClose modal-close" src="/img/close.gif" @click="schliesseNierePopup">
            </td>
          </tr>
        </table>
      </div>
      <div class="hilfeContent">
          <span class="feldbeschreibung">
            {{ phrasen.hilfetext_niere[1] }}
            <br><br>
            {{ phrasen.hilfetext_niere[2] }}<br>
            <ul>
              <li class="listenpunkt">{{ phrasen.hilfetext_niere[3] }}</li>
              <li class="listenpunkt">{{ phrasen.hilfetext_niere[4] }}</li>
              <li class="listenpunkt">{{ phrasen.hilfetext_niere[5] }}</li>
            </ul>
            <br>
            {{ phrasen.hilfetext_niere[6] }}<br>
            <ul>
              <li class="listenpunkt">{{ phrasen.hilfetext_niere[7] }}</li>
              <li class="listenpunkt">{{ phrasen.hilfetext_niere[8] }}</li>
              <li class="listenpunkt">{{ phrasen.hilfetext_niere[9] }}</li>
            </ul>
            <br>
            {{ phrasen.hilfetext_niere[10] }}<br>
            <ul>
              <li class="listenpunkt">{{ phrasen.hilfetext_niere[11] }}</li>
              <li class="listenpunkt">{{ phrasen.hilfetext_niere[12] }}</li>
              <li class="listenpunkt">{{ phrasen.hilfetext_niere[13] }}</li>
              <li class="listenpunkt">{{ phrasen.hilfetext_niere[14] }}</li>
              <li class="listenpunkt">{{ phrasen.hilfetext_niere[15] }}</li>
              <li class="listenpunkt">{{ phrasen.hilfetext_niere[16] }}</li>
            </ul>
            {{ phrasen.hilfetext_niere[17] }}
            <br><br>
            <u>{{ phrasen.hilfetext_niere[18] }}</u>
          </span>
          <br><br>
          <span class="literatur">
            {{ phrasen.hilfetext_niere[19] }}
            <br><br>
            {{ phrasen.hilfetext_niere[20] }}
            <br><br>
            {{ phrasen.hilfetext_niere[21] }}
          </span>
      </div>
    </div>
</template>

<script>
export default {
  name: 'HinweisNiere',
  props: ['phrasen'],
  methods: {
    schliesseNierePopup: function () {
      var event
      if (typeof (Event) === 'function') {
        event = new Event('schliesseNierePopup')
      } else {
        event = document.createEvent('Event')
        event.initEvent('schliesseNierePopup', true, true)
      }
      document.dispatchEvent(event)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hilfeContent {
  margin-top: 5px;
  height: 52vh;
  overflow-x: auto;
  padding:13px;
}
.feldbeschreibung {
  font-size: calc(6px + 2.5vw) !important;
}
.literatur {
  font-size: calc(4px + 2vw) !important;
}
.listenpunkt {
  list-style-type: disc !important;
  margin-left: 20px;
}
@media screen and (min-width: 320px) {
  .feldbeschreibung {
    font-size: calc(6px + 2.5vw) !important;
  }
  .literatur {
    font-size: calc(4px + 2vw) !important;
  }
}
@media screen and (min-width: 600px) {
  .feldbeschreibung {
    font-size: 16px !important;
  }
  .literatur {
    font-size: 14px !important;
  }
  .hilfeContent {
    margin-top: 5px;
    height: 60vh;
    overflow-x: auto;
    padding:13px;
  }
}
</style>
