<template>
  <div class="home">
    <div class="marginLeftRight">
      <div class="row">
        <router-link to="/therapie">
          <div class="buttonStartseite" v-on:click="loggeDaten('doakuebersicht_aufruf')">
            <div class="hoehe_button divIcon">
                <span class="helper"></span><img src="/img/tablette.gif" class="buttonIcon"/>
            </div>
            <div class="divButtonName">
              <h5 class="white-text buttonTitel">{{ phrasen.therapie_titel }}</h5>
              <p class="white-text buttonUntertitel">{{ phrasen.therapie_untertitel }}</p>
            </div>
            <div class="hoehe_button divButtonPfeil">
              <span class="helper"></span><img class="image_pfeil buttonPfeil" src="/img/pfeil.png" />
            </div>
          </div>
        </router-link>
        <router-link to="/chads-vasc-score">
          <div class="buttonStartseite" v-on:click="loggeDaten('vasc_score_aufruf')">
            <div class="hoehe_button divIcon">
                <span class="helper"></span><img src="/img/gehirn.gif" class="buttonIcon"/>
            </div>
            <div class="divButtonName">
              <h5 class="white-text marginButtonName">CHA<sub>2</sub>DS<sub>2</sub>-VASc-Score</h5>
            </div>
            <div class="hoehe_button divButtonPfeil">
              <span class="helper"></span><img class="image_pfeil buttonPfeil" src="/img/pfeil.png" />
            </div>
          </div>
        </router-link>
        <router-link to="/has-bled-score">
          <div class="buttonStartseite" v-on:click="loggeDaten('bled_score_aufruf')">
            <div class="hoehe_button divIcon">
                <span class="helper"></span><img src="/img/tropfen.gif" class="buttonIcon"/>
            </div>
            <div class="divButtonName">
              <h5 class="white-text marginButtonName">HAS-BLED-Score</h5>
            </div>
            <div class="hoehe_button divButtonPfeil">
              <span class="helper"></span><img class="image_pfeil buttonPfeil" src="/img/pfeil.png" />
            </div>
          </div>
        </router-link>
        <router-link to="/nierenberechnung">
          <div class="buttonStartseite" v-on:click="loggeDaten('nierenberechnung_aufruf_start')">
            <div class="hoehe_button divIcon">
                <span class="helper"></span><img src="/img/niere.gif" class="buttonIcon"/>
            </div>
            <div class="divButtonName">
              <h5 class="white-text buttonTitel">{{ phrasen.guess_kidney_function }}</h5>
              <p class="white-text buttonUntertitel">{{ phrasen.cockcroft }}</p>
            </div>
            <div class="hoehe_button divButtonPfeil">
              <span class="helper"></span><img class="image_pfeil buttonPfeil" src="/img/pfeil.png" />
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <div v-if="!fensterSchliessen" class="black hintergrundGrau"></div>
    <div v-if="!fensterSchliessen" id="modal_starttext" class="modal modalBegruessung" style="">
      <div class="modal-content">
        <h4>{{ phrasen.doak_therapy }}</h4>
        <p>{{ phrasen.start_text }}</p>
      </div>
      <div class="modal-footer">
      <a href="#!" class="btn-flat" v-on:click="fensterSchliessenAction">{{ phrasen.do_not_show }}</a>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  props: ['bestaetigungArzt', 'phrasen', 'sperre'],
  data () {
    return {
      fensterSchliessen: false
    }
  },
  methods: {
    // Die Funktion wird aufgerufen, wenn das Fenster mit dem Begrüßungstext aufgerufen wird. Und sorgt für keinen wiederholten Aufruf des Begrüßungstextes
    fensterSchliessenAction: function () {
      localStorage.fensterSchliessen = true
      this.fensterSchliessen = true
    },
    // hier werden bestimmte Aktionen aus diesem Vue geloggt und im LocalStorage abgelegt
    loggeDaten: function (id) {
      // hier wird geprüft, ob der User gerade online oder offline ist.
      var statusJetzt = 'offline'
      if (navigator.onLine) {
        statusJetzt = 'online'
      }
      // hier werden die LoggDaten aus dem LocalStorage in ein Array eingelesen und eine Kopie von den LoggDaten aus dem LocalStorage erstellt.
      var loggDaten = []
      var localStorageLoggDatenKopie = null
      if (localStorage.loggDaten != null) {
        loggDaten = JSON.parse(localStorage.loggDaten)
        localStorageLoggDatenKopie = localStorage.loggDaten
      }
      var maxLogEintraege = parseInt(localStorage.maxLogEintraege)
      // wenn die Loggsaten, die Maximallänge nicht überschreiten, dann wird der neue Eintrag in die Daten eingefügt.
      if (loggDaten.length < maxLogEintraege) {
        var neuerLogg = { aktion: id, datum: new Date(), status: statusJetzt }
        loggDaten.push(JSON.stringify(neuerLogg))
        var loggDatenAktuell = JSON.stringify(loggDaten)
        // hier wird versucht, die erweiterten Loggdaten in den LocalStorage zu schreiben. Wenn das schiefläuft, dann werden die alten Loggdaten in den LocalStorage geschrieben.
        try {
          localStorage.loggDaten = loggDatenAktuell
        } catch (e) {
          localStorage.loggDaten = localStorageLoggDatenKopie
        }
      }
    }
  },
  mounted () {
    if (this.bestaetigungArzt === false) {
      this.$router.push({ name: 'Disclaimer' })
    }
    if (this.sperre === true) {
      this.$router.push({ name: 'Sperre' })
    }
    if (localStorage.fensterSchliessen != null) {
      this.fensterSchliessen = true
    }
  }
}
</script>

<style scoped>
.hintergrundGrau {
  height:100vh;
  width:100%;
  left:0;
  position:absolute;
  z-index: 1004;
  top:0px;
  opacity: 0.5;
}

.modalBegruessung {
  z-index: 1005;
  display: block;
  opacity: 1;
  top: 10%;
  transform: scaleX(1) scaleY(1);
}

.marginLeftRight {
  margin-left: 10px;
  margin-right: 10px;
}

.buttonStartseite {
  background-color:#1565C0;
  float:left;
  width:100%;
  margin-top: 10px;
}

.buttonTitel {
  margin-bottom:0px;
  margin-top: 12px;
}

.buttonUntertitel {
  margin-bottom: 0px;
  margin-top: 8px;
}

.divIcon {
  width:20%;
  float:left;
}

.divButtonName {
  width:67%;
  float:left;
}

.marginButtonName{
  margin-top: 35px;
}

.divButtonPfeil {
  width:11%;
  float:left;
}

.buttonIcon {
  width:100%;
  height:100%;
}

.buttonPfeil {
  width:70%;
  height:80%;
  float:right;
  margin-top:18px;
}

.helper {
     display: inline-block;
     height: 100%;
     vertical-align: middle;
}

img {
     vertical-align: middle;
     max-height: 65px;
     max-width: 65px;
}
.hoehe_button {
     height: 100px;
}
.image_pfeil {
     vertical-align: middle;
     max-height: 65px;
     max-width: 65px;
}

.dosierungsPopup {
  width: 107% !important;
}

@media screen and (min-width: 320px) {
  .dosierungsPopup {
    width: 107% !important;
  }
  .helper {
     display: inline-block;
     height: 100%;
     vertical-align: middle;
  }
  .hoehe_button {
     height: 100px;
  }

  img {
      vertical-align: middle;
      max-height: 65px;
      max-width: 65px;
  }

  .image_pfeil {
     vertical-align: middle;
     max-height: 65px;
     max-width: 65px;
  }

}

@media screen and (min-width: 600px) {
  .marginLeftRight {
    margin-left: 25px;
    margin-right: 30px;
  }
  .dosierungsPopup {
     width: 102.5% !important;
  }
  .buttonTitel {
    margin-bottom:0px;
    margin-top: 22px;
  }
  .helper {
     display: inline-block;
     height: 100%;
     vertical-align: middle;
  }
  .hoehe_button {
    padding-left: 20px;
     height: 100px;
  }

  img {
      vertical-align: middle;
      max-height: 80px;
      max-width: 80px;
  }

  .image_pfeil {
     vertical-align: middle;
     max-height: 65px !important;
     max-width: 35px !important;
  }
}

@media screen and (min-width: 1000px) {
  .marginLeftRight {
    margin-left: 20px;
    margin-right: 10px;
  }
}
</style>
