import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import HASBLEDScore from '../views/BLED-Score.vue'
import CHADSVAScScore from '../views/VASc-Score.vue'
import Kontakt from '../views/Kontakt.vue'
import Impressum from '../views/Impressum.vue'
import Datenschutz from '../views/Datenschutz.vue'
import Disclaimer from '../views/Disclaimer.vue'
import Therapie from '../views/Therapieinhalt.vue'
import Nierenberechnung from '../views/Nierenberechnung.vue'
import Sperre from '../views/Sperre.vue'
import Feedback from '../views/Feedback.vue'

Vue.use(VueRouter)

const routes = [
  {
    name: '/',
    path: '/index.html',
    component: Home,
    alias: '/'
  },
  {
    path: '/kontakt',
    name: 'Kontakt',
    component: Kontakt
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: Impressum
  },
  {
    path: '/datenschutz',
    name: 'Datenschutz',
    component: Datenschutz
  },
  {
    path: '/disclaimer',
    name: 'Disclaimer',
    component: Disclaimer
  },
  {
    path: '/has-bled-score',
    name: 'HAS-BLED-Score',
    component: HASBLEDScore
  },
  {
    path: '/chads-vasc-score',
    name: 'CHADS-VASc-Score',
    component: CHADSVAScScore
  },
  {
    path: '/therapie',
    name: 'Therapie',
    component: Therapie
  },
  {
    path: '/nierenberechnung',
    name: 'Nierenberechnung',
    component: Nierenberechnung
  },
  {
    path: '/sperre',
    name: 'Sperre',
    component: Sperre
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: Feedback
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
