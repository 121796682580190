<template>
  <div class="home container containerMaxWidth">
    <br>
    <div class="blue-text text-darken-3 hasBledTitle">CHA<sub>2</sub>DS<sub>2</sub>-VASc-Score</div>
    <div class="blue-text text-darken-3 hasBledUnderTitle">{{ phrasen.vasc_undertitle }}</div>
    <br>
    <div class="row feldBezeichnungen">
      <div class="col s6 m7 feldBezeichnungen1">
        <b>{{ phrasen.criteria }}</b>
      </div>
      <div class="col s4 feldBezeichnungen2">
        <b>{{ phrasen.available }}</b>
      </div>
      <div class="col s2 m1">
        <p class="right feldBezeichnungen3">{{ phrasen.points }}</p>
      </div>
    </div>
    <center>
    <div v-on:click.stop.prevent="checkBoxSwitchen(1)" class="row btn startseite_button2 blue lighten-5 blue-text text-darken-3 feldHellBlau">
      <div class="col s7 feldBezeichnungen1">
        <span v-html="phrasen.vasc_1"></span>
      </div>
      <div class="col s4 switch doak divJaNein">
        <label>
          <span class="jaNeinColor">{{ phrasen.no }}</span>
          <input type="checkbox" id="vasc_1" ref="vasc_1" v-model="scoreArray" value="1">
          <span class="lever schalterMargin"></span>
          <span class="jaNeinColor">{{ phrasen.yes }}</span>
        </label>
      </div>
      <div class="col s1 paddingPunkte">
        <p class="right punkteStyle"><b>1</b></p>
      </div>
    </div>
    <div v-on:click.stop.prevent="checkBoxSwitchen(2)" class="row btn startseite_button2 blue lighten-5 blue-text text-darken-3 feldHellBlau">
      <div class="col s7 feldBezeichnungen1">
        <span v-html="phrasen.vasc_2"></span>
      </div>
      <div class="col s4 switch doak divJaNein">
        <label>
          <span class="jaNeinColor">{{ phrasen.no }}</span>
          <input type="checkbox" id="vasc_2" ref="vasc_2" v-model="scoreArray" value="2">
          <span class="lever schalterMargin"></span>
          <span class="jaNeinColor">{{ phrasen.yes }}</span>
        </label>
      </div>
      <div class="col s1 paddingPunkte">
        <p class="right punkteStyle"><b>1</b></p>
      </div>
    </div>
    <div v-on:click.stop.prevent="checkBoxSwitchen(3)" class="row btn startseite_button2 blue lighten-5 blue-text text-darken-3 feldHellBlau">
      <div class="col s7 feldBezeichnungen1">
        <span v-html="phrasen.vasc_3"></span>
      </div>
      <div class="col s4 switch doak divJaNein">
        <label>
          <span class="jaNeinColor">{{ phrasen.no }}</span>
          <input type="checkbox" id="vasc_3" ref="vasc_3" v-model="scoreArray" value="3">
          <span class="lever schalterMargin"></span>
          <span class="jaNeinColor">{{ phrasen.yes }}</span>
        </label>
      </div>
      <div class="col s1 paddingPunkte">
        <p class="right punkteStyle"><b>2</b></p>
      </div>
    </div>
    <div v-on:click.stop.prevent="checkBoxSwitchen(4)" class="row btn startseite_button2 blue lighten-5 blue-text text-darken-3 feldHellBlau">
      <div class="col s7 feldBezeichnungen1">
        <span v-html="phrasen.vasc_4"></span>
      </div>
      <div class="col s4 switch doak divJaNein">
        <label>
          <span class="jaNeinColor">{{ phrasen.no }}</span>
          <input type="checkbox" id="vasc_4" ref="vasc_4" v-model="scoreArray" value="4">
          <span class="lever schalterMargin"></span>
          <span class="jaNeinColor">{{ phrasen.yes }}</span>
        </label>
      </div>
      <div class="col s1 paddingPunkte">
        <p class="right punkteStyle"><b>1</b></p>
      </div>
    </div>
    <div v-on:click.stop.prevent="checkBoxSwitchen(5)" class="row btn startseite_button2 blue lighten-5 blue-text text-darken-3 feldHellBlau">
      <div class="col s7 feldBezeichnungen1">
        <span v-html="phrasen.vasc_5"></span>
      </div>
      <div class="col s4 switch doak divJaNein">
        <label>
          <span class="jaNeinColor">{{ phrasen.no }}</span>
          <input type="checkbox" id="vasc_5" ref="vasc_5" v-model="scoreArray" value="5">
          <span class="lever schalterMargin"></span>
          <span class="jaNeinColor">{{ phrasen.yes }}</span>
        </label>
      </div>
      <div class="col s1 paddingPunkte">
        <p class="right punkteStyle"><b>2</b></p>
      </div>
    </div>
    <div v-on:click.stop.prevent="checkBoxSwitchen(6)" class="row btn startseite_button2 blue lighten-5 blue-text text-darken-3 feldHellBlau">
      <div class="col s7 feldBezeichnungen1">
        <span v-html="phrasen.vasc_6"></span>
      </div>
      <div class="col s4 switch doak divJaNein">
        <label>
          <span class="jaNeinColor">{{ phrasen.no }}</span>
          <input type="checkbox" id="vasc_6" ref="vasc_6" v-model="scoreArray" value="6">
          <span class="lever schalterMargin"></span>
          <span class="jaNeinColor">{{ phrasen.yes }}</span>
        </label>
      </div>
      <div class="col s1 paddingPunkte">
        <p class="right punkteStyle"><b>1</b></p>
      </div>
    </div>
    <div v-on:click.stop.prevent="checkBoxSwitchen(7)" class="row btn startseite_button2 blue lighten-5 blue-text text-darken-3 feldHellBlau">
      <div class="col s7 feldBezeichnungen1">
        <span v-html="phrasen.vasc_7"></span>
      </div>
      <div class="col s4 switch doak divJaNein">
        <label>
          <span class="jaNeinColor">{{ phrasen.no }}</span>
          <input type="checkbox" id="vasc_7" ref="vasc_7" v-model="scoreArray" value="7">
          <span class="lever schalterMargin"></span>
          <span class="jaNeinColor">{{ phrasen.yes }}</span>
        </label>
      </div>
      <div class="col s1 paddingPunkte">
        <p class="right punkteStyle"><b>1</b></p>
      </div>
    </div>
    <div v-on:click.stop.prevent="checkBoxSwitchen(8)" class="row btn startseite_button2 blue lighten-5 blue-text text-darken-3 feldHellBlau">
      <div class="col s7 feldBezeichnungen1">
        <span v-html="phrasen.vasc_8"></span> <b id="sternGeschlecht">*</b>
      </div>
      <div class="col s4 switch doak divJaNein" style="margin-left: -5px;">
        <label>
          <span class="jaNeinColor">{{ phrasen.sex_m }}</span>
          <input type="checkbox" id="vasc_8" ref="vasc_8" v-model="scoreArray" value="8">
          <span class="lever schalterMargin"></span>
          <span class="jaNeinColor">{{ phrasen.sex_w }}</span>
        </label>
      </div>
      <div class="col s1 paddingPunkte">
        <p class="right punkteStyle"><b>1</b></p>
      </div>
    </div>
    </center>
    <div id="ergebnisFenster">
      {{ phrasen.score }}: {{ score }}
      <hr id="auswertungLinie" />
      <span id="auswertungText">
        {{ phrasen.stroke_risk }}:&nbsp;
        <span id="auswertung">
          <span id="noWrap">
            {{ auswertung }} %
          </span>
        </span>
      </span>
    </div>
    <div id="modalHilfeVASc" class="modal">
      <div class="modal-content contentHinweis padding5">
        <HinweisVASc />
      </div>
    </div>
    <center>
      <router-link to="/"><button class="btn blue darken-3 backButton">{{phrasen.back}}</button></router-link>
      <button class="btn blue darken-3 modal-trigger helpButton" href="#modalHilfeVASc"><img href="#modalnieretexte" class="left infoImg" src="/img/info.gif">{{phrasen.help_text}}</button>
    </center>
    <div id="textGeschlecht">
        <b id="sternGeschlecht" class="blue-text text-darken-3">*</b> {{phrasen.text_geschlecht}}
    </div>
  </div>
</template>

<script>
import M from 'materialize-css/dist/js/materialize.min'
import HinweisVASc from '@/components/HinweisVASc.vue'
export default {
  name: 'CHADS-VASc-Score',
  props: ['bestaetigungArzt', 'phrasen', 'sperre'],
  data: function () {
    return {
      scoreArray: [],
      score: 0,
      auswertung: '0,2'
    }
  },
  components: {
    HinweisVASc
  },
  methods: {
    overflowEntfernen: function () { // Diese Funktion wird benutzt, um das Scroll-Problem in den Griff zu bekommen.
      document.body.style.overflow = ''
    },
    auswertungGenerieren: function () {
      if (this.scoreArray[this.scoreArray.length - 1] === 3) {
        if (this.scoreArray.includes(7)) {
          this.scoreArray.splice(this.scoreArray.indexOf(7), 1)
        }
      }
      if (this.scoreArray[this.scoreArray.length - 1] === 7) {
        if (this.scoreArray.includes(3)) {
          this.scoreArray.splice(this.scoreArray.indexOf(3), 1)
        }
      }
      this.score = this.scoreArray.length
      if (this.scoreArray.includes(3)) {
        this.score += 1
      }
      if (this.scoreArray.includes(5)) {
        this.score += 1
      }
      if (this.score >= 9) {
        this.auswertung = '12'
      } else {
        switch (this.score) {
          case 0:
            this.auswertung = '0,2'
            break
          case 1:
            this.auswertung = '0,6'
            break
          case 2:
            this.auswertung = '2,2'
            break
          case 3:
            this.auswertung = '3,2'
            break
          case 4:
            this.auswertung = '4,8'
            break
          case 5:
            this.auswertung = '7,2'
            break
          case 6:
            this.auswertung = '9,7'
            break
          case 7:
            this.auswertung = '11'
            break
          case 8:
            this.auswertung = '11'
            break
          default:
            break
        }
      }
    },
    checkBoxSwitchen: function (id) {
      if (this.scoreArray.includes(id)) {
        this.scoreArray.splice(this.scoreArray.indexOf(id), 1)
      } else {
        this.scoreArray.push(id)
      }
    }
  },
  mounted () {
    M.AutoInit() // Gewährleistet die Funktionalität von Materialize in dieser Vue-Datei
    if (this.bestaetigungArzt === false) {
      this.$router.push({ name: 'Disclaimer' })
    }
    if (this.sperre === true) {
      this.$router.push({ name: 'Sperre' })
    }
  },
  watch: {
    scoreArray: function () {
      this.auswertungGenerieren()
    }
  }
}
</script>

<style scoped>
.containerMaxWidth {
    max-width: 620px;
}

.infoImg {
  width: 25px;
  margin-top:5px;
  margin-right:10px;
}

.hasBledTitle {
    font-size:21px;
    margin-bottom:5px;
}

.padding5 {
  padding: 5px !important;
}

.hasBledUnderTitle {
    font-size:16px;
}

.feldBezeichnungen {
    max-width: 600px;
    margin-bottom:5px;
    margin-top:5px;
}

.feldBezeichnungen1 {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.feldBezeichnungen2 {
    padding-left:15px;
}

.feldBezeichnungen3 {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
    font-weight:bold;
}

.feldHellBlau {
    width:100%;
    max-width: 600px;
    text-align:left;
    padding-left:5px !important;
    padding-right:5px !important;
    margin-top:5px;
    margin-bottom:5px;
}

.ersterBuchstabe {
    font-size:17px;
    line-height:1 !important;
}

.divJaNein {
    padding-left:3px;
    padding-right:3px;
}

.jaNeinColor {
    color:#1565C0 !important;
}

.schalterMargin {
    margin-left:5px !important;
    margin-right:5px !important;
}

.paddingPunkte {
    height:30px;
}

.punkteStyle {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
}

#ergebnisFenster {
    margin:auto;
    width:80%;
    max-width:320px;
    text-align:center;
    border-style: solid;
    border-width: 2px;
    border-color:#1565C0;
    padding:8px;
    font-size:20px;
    margin-top:40px;
    margin-bottom:5px;
    box-shadow: 0px 2px 2px silver;
}

.backButton {
    margin-top:7px;
    text-transform: none !important;
    margin-bottom:20px;
}

.helpButton {
    margin-top:7px;
    text-transform: none !important;
    margin-bottom:20px;
    margin-left:7px;
}

#auswertungLinie {
    width:60%;
    color: #1565C0;
    background: #1565C0;
    border-color:#1565C0;
}

#sternGeschlecht{
    font-size: 19px;
    line-height:1 !important;
}

#textGeschlecht {
    font-size: 12px;
    margin-bottom: 10px;
}

#noWrap {
    white-space: nowrap;
}

#auswertungText {
    font-size:16px;
    font-weight:normal;
}

@media screen and (min-width: 600px) {
    .hasBledTitle {
        font-size:21px;
        margin-left:10px;
        margin-bottom:5px;
    }

    .hasBledUnderTitle {
        font-size:16px;
        margin-left: 10px;
    }

    .feldBezeichnungen2 {
        padding-left:0px;
    }

    .paddingPunkte {
        height:30px;
        padding-right:25px;
    }
}
</style>
