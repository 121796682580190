import { render, staticRenderFns } from "./BLED-Score.vue?vue&type=template&id=6a22377f&scoped=true&"
import script from "./BLED-Score.vue?vue&type=script&lang=js&"
export * from "./BLED-Score.vue?vue&type=script&lang=js&"
import style0 from "./BLED-Score.vue?vue&type=style&index=0&id=6a22377f&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a22377f",
  null
  
)

export default component.exports