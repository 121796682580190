<template>
    <div>
      <div class="modalContentDosierungHeader">
        <table>
          <tr class="trBorderNone">
            <td>
              <span class="modalDosierungSpan">CHA<sub>2</sub>DS<sub>2</sub>-VASc-Score</span>
            </td>
            <td>
            </td>
            <td class="closeModalTd">
              <img href="#!" class="modal-close right widthClose modal-close" src="/img/close.gif">
            </td>
          </tr>
        </table>
      </div>
      <div class="hilfeContent">
        <span class="feldbeschreibung">
          Eine Abschätzung des Risikos für ischämische Insulte als Entscheidungskriterium für die Indikationsstellung zu einer DOAK-Therapie ist fester Bestandteil der Guidelines für die Behandlung von Vorhofflimmern [Hindricks 2020].
          <br><br>
          Die akkurate Anwendung setzt eine Kenntnis der genauen Kriterien voraus.
          <br>
          <ul>
            <li class="listenpunkt"><b>Herzinsuffizienz</b><br>
              Zeichen / Symptome einer Herzinsuffizienz oder objektiver Nachweis für eine mittel- bis hochgradig verminderte links-ventrikuläre Auswurffraktion (LVEF ≤ 40 %) oder hypertrophe Kardiomyopathie.
            </li>
            <li class="listenpunkt"><b>Hypertonie</b><br>
              Ruheblutdruck > 140/90 mmHg bei mindestens zwei Messungen oder aktuelle antihypertensive Medikation.
            </li>
            <li class="listenpunkt"><b>Diabetes mellitus</b><br>
              Nüchtern-Blutzucker > 125 mg/dl (> 7 mmol/l) oder Behandlung mit oralen Antidiabetika und / oder Insulin.
            </li>
            <li class="listenpunkt"><b>Schlaganfall / TIA / Thromboembolie</b><br>
              Früherer Schlaganfall, transitorische ischämische Attacke (TIA) oder Thromboembolie.
            </li>
            <li class="listenpunkt"><b>Vaskuläre Vorerkrankung (z. B. KHK, pAVK oder Aortenplaque)</b><br>
              Angiographisch signifikante KHK, früherer Herzinfarkt, periphere arterielle Verschlusskrankheit (pAVK) oder komplexer Aortenplaque.
            </li>
          </ul>
          <br>
          Der Wert für das geschätzte Schlaganfallsrisiko entstammt einer großen Kohorten-Studie [Friberg 2012] und beziehen sich auf das nicht-adjustierte Risiko für einen ischämischen Schlaganfall (d. h. nicht adjustiert für eine mögliche Anwendung von Aspirin). Das tatsächliche Risiko in einer heutigen Kohorte kann abweichend sein.
          <br><br>
          <u>Abkürzungen</u>
          <br>
        </span>
        <span class="literatur">
          DOAK: Direkte orale Antikoagulanzien, KHK: Koronare Herzkrankheit, pAVK: periphere arterielle Verschlusskrankheit, TIA: transiente ischämische Attacke.
          <br><br>
        </span>
        <span class="feldbeschreibung">
          <u>Literatur</u><br>
        </span>
        <span class="literatur">
          Friberg L, Rosenqvist M, Lip GY. Evaluation of risk stratification schemes for ischaemic stroke and bleeding in 182 678 patients with atrial fibrillation: the Swedish Atrial Fibrillation cohort study. Eur Heart J 2012;33:1500-10.<br><br>
          Hindricks G, Potpara T, Dagres N, Arbelo E, Bax JJ, Blomström-Lundqvist C, Boriani G, Castella M, Dan GA, Dilaveris PE, Fauchier L, Filippatos G, Kalman JM, La Meir M, Lane DA, Lebeau JP, Lettino M, Lip GYH, Pinto FJ, Thomas GN, Valgimigli M, Van Gelder IC, Van Putte BP, Watkins CL. 2020 ESC Guidelines for the diagnosis and management of atrial fibrillation developed in collaboration with the European Association of Cardio-Thoracic Surgery (EACTS). Eur Heart J 2020 (ePub ahead of print).
        </span>
      </div>
    </div>
</template>

<script>
export default {
  name: 'HinweisHASBLED'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.modalContentDosierungHeader {
  background-color: rgb(21, 101, 192);
  color:white;
  padding:10px;
  box-shadow: 3px 3px 3px silver;
}

.trBorderNone {
  border: none;
}

.modalDosierungTd {
  width:25%;
  vertical-align:top;
  padding-top:7px;
  padding-bottom:7px;
}

.modalDosierungTd2 {
  width:50%;
  vertical-align:top;
  padding-top:7px;
  padding-bottom:7px;
}

.closeModalTd {
  vertical-align:top;
  padding-top:9px;
  padding-bottom:7px;
}

.widthClose {
  width:25px;
}

.modalDosierungSpan {
  font-size:18px;
  font-weight:bold;
}

.hilfeContent {
  margin-top: 5px;
  height: 52vh;
  overflow-x: auto;
  padding:13px;
}
.feldbeschreibung {
  font-size: calc(6px + 2.5vw) !important;
}
.literatur {
  font-size: calc(4px + 2vw) !important;
}
.listenpunkt {
  list-style-type: disc !important;
  margin-left: 20px;
}
@media screen and (min-width: 320px) {
  .feldbeschreibung {
    font-size: calc(6px + 2.5vw) !important;
  }
  .literatur {
    font-size: calc(4px + 2vw) !important;
  }
}
@media screen and (min-width: 600px) {
  .feldbeschreibung {
    font-size: 16px !important;
  }
  .literatur {
    font-size: 14px !important;
  }
  .hilfeContent {
    margin-top: 5px;
    height: 60vh;
    overflow-x: auto;
    padding:13px;
  }
}
</style>
