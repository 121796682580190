<template>
  <div class="home container">
      <div class="hauptPadding">
        <div class="niereHeader">
          <img href="#!" class="right deleteButton" src="/img/delete.gif" style="" v-on:click="filterEntfernen">
          <img href="#modalnieretexte" class="right infoButton modal-trigger" src="/img/info.gif">
          <div class="niereHeader2">
              {{ phrasen.cockcroft }}
          </div>
          {{ phrasen.all_fields }}
        </div>
        <div class="row rowKreatinin">
          <div class="col s12 m12 l8 kreatinin1">
            <div class="niereBewertung blue lighten-5 blue-text text-darken-3">
              <table ref="kreatininTable1">
                <tr class="trBorderNone">
                  <td class="eingabeTd1">
                    <span v-html="phrasen.plasma_creatinin"></span>
                  </td>
                  <td class="eingabeTd2">
                    mg/dl
                  </td>
                  <td class="eingabeTd3">
                    <input id="kreatinin_1" type="number" ref="kreatinin_1" :value="kreatininSpeicher" v-on:keyup="kreatininAbgleichen1" v-on:change="kreatininAbgleichen1" class="niereFeld">
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="col s12 m12 l4 kreatinin2">
            <div class="niereBewertung blue lighten-5 blue-text text-darken-3">
              <table class="kreatininTable2">
                <tr class="trBorderNone">
                  <td class="eingabeTd4">
                    µmol/l
                  </td>
                  <td class="eingabeTd5">
                    <input id="kreatinin_2" type="number" ref="kreatinin_2" v-on:keyup="kreatininAbgleichen2" v-on:change="kreatininAbgleichen2" class="niereFeld">
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="niereHeaderMeldung grey" v-if="keine_nummer_kreatin || keine_nummer_kreatin2">
          {{ phrasen.meldung_creatinin }}
        </div>
        <div class="niereHeaderMeldung grey" v-if="kreatinFalsch">
          {{ phrasen.meldung_creatinin2  }}
        </div>
        <div class="row rowKreatinin">
          <div class="col s12 m12 l8 kreatinin1">
            <div class="niereBewertung blue lighten-5 blue-text text-darken-3">
              <table ref="kreatininTable1">
                <tr class="trBorderNone">
                  <td class="eingabeTd1">
                    {{ phrasen.alter_field1  }}
                  </td>
                  <td class="eingabeTd2">
                    {{ phrasen.years  }}
                  </td>
                  <td class="eingabeTd3">
                    <input type="number" ref="alter" :value="alterSpeicher" v-on:keyup="alterPruefen" v-on:change="alterPruefen" class="niereFeld">
                  </td>
                </tr>
              </table>
            </div>
            <div class="niereHeaderMeldung2 grey" v-if="keinKomma">
              {{ phrasen.meldung_alter  }}
            </div>
            <div class="niereHeaderMeldung2 grey" v-if="alterZuJung">
              {{ phrasen.meldung_alter2  }}
            </div>
            <div class="niereBewertung blue lighten-5 blue-text text-darken-3">
              <table ref="kreatininTable1">
                <tr class="trBorderNone">
                  <td class="eingabeTd1">
                    {{ phrasen.gewicht_field1 }}
                  </td>
                  <td class="eingabeTd2">
                    kg
                  </td>
                  <td class="eingabeTd3">
                    <input id="gewicht" type="number" ref="gewicht" :value="gewichtSpeicher" v-on:keyup="gewichtPruefen" v-on:change="gewichtPruefen" class="niereFeld">
                  </td>
                </tr>
              </table>
            </div>
            <div class="niereHeaderMeldung hide-on-large-only grey" v-if="gewichtZuHoch">
              {{ phrasen.meldung_gewicht }}
            </div>
          </div>
          <div class="col s12 m12 l4 kreatinin2">
            <div class="niereBewertung blue lighten-5 blue-text text-darken-3">
              <table ref="kreatininTable1">
                <tr class="trBorderNone">
                  <td class="eingabeTd6" v-if="!keinKomma && !alterZuJung">
                      <label class="marginLeft5">
                        <input v-if="geschlechtSpeicher != 'false'" class="with-gap" name="sex" type="radio" ref="mann" value="1" v-on:click="ErgebnisBerechnen" />
                        <input v-if="geschlechtSpeicher == 'false'" class="with-gap" name="sex" type="radio" ref="mann" value="1" checked="checked" v-on:click="ErgebnisBerechnen" />
                        <span class="feldbeschreibung blue-text text-darken-3">{{ phrasen.male }}</span>
                      </label>
                  </td>
                  <td class="eingabeTd7" v-if="keinKomma || alterZuJung">
                      <label class="marginLeft5">
                        <input v-if="geschlechtSpeicher != 'false'" class="with-gap" name="sex" type="radio" ref="mann" value="1" v-on:click="ErgebnisBerechnen" />
                        <input v-if="geschlechtSpeicher == 'false'" class="with-gap" name="sex" type="radio" ref="mann" value="1" checked="checked" v-on:click="ErgebnisBerechnen" />
                        <span class="feldbeschreibung blue-text text-darken-3">{{ phrasen.male }}</span>
                      </label>
                  </td>
                </tr>
                <tr class="trBorderNone">
                  <td class="eingabeTd6" v-if="!keinKomma && !alterZuJung">
                    <label class="marginLeft5">
                      <input v-if="geschlechtSpeicher != 'true'" class="with-gap" name="sex" type="radio" ref="frau"  value="2" v-on:click="ErgebnisBerechnen" />
                      <input v-if="geschlechtSpeicher == 'true'" class="with-gap" name="sex" type="radio" ref="frau"  checked="checked" value="2" v-on:click="ErgebnisBerechnen" />
                      <span class="feldbeschreibung blue-text text-darken-3">{{ phrasen.female }}</span>
                    </label>
                  </td>
                  <td class="eingabeTd7" v-if="keinKomma || alterZuJung">
                    <label class="marginLeft5">
                      <input v-if="geschlechtSpeicher != 'true'" class="with-gap" name="sex" type="radio" ref="frau"  value="2" v-on:click="ErgebnisBerechnen" />
                      <input v-if="geschlechtSpeicher == 'true'" class="with-gap" name="sex" type="radio" ref="frau"  checked="checked" value="2" v-on:click="ErgebnisBerechnen" />
                      <span class="feldbeschreibung blue-text text-darken-3">{{ phrasen.female }}</span>
                    </label>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="niereHeaderMeldung hide-on-med-and-down grey" v-if="gewichtZuHoch">
          {{ phrasen.meldung_gewicht }}
        </div>
        <div class="row rowKreatinin">
          <div class="col s12 m12 l4 kreatinin1">
            <button v-if="aufDieStartseite && zurueckKnopfFertig" class="btn blue darken-3 backKnopf" v-on:click="startZurueck">{{ phrasen.back }}</button>
            <button v-if="!aufDieStartseite && zurueckKnopfFertig" class="btn blue darken-3 backKnopf" v-on:click="niereZurueck">{{ phrasen.back }}</button><br>
            <button v-if="wert == 0" class="btn blue darken-3 modal-trigger uebernehmenKnopf" disabled="disabled" href="#modalniererberechnen">{{ phrasen.accept }}</button>
            <button v-if="wert != 0" class="btn blue darken-3 modal-trigger uebernehmenKnopf" href="#modalniererberechnen">{{ phrasen.accept }}</button>
          </div>
          <div class="col s12 m12 l8 kreatinin2">
            <div class="niereHeader3">
              <center>
                <span class="wertHeader1">{{ phrasen.guess_kidney_function3 }}</span><br>
                <table>
                  <tr class="trBorderNone">
                    <td class="padding8">
                      <div v-if="wert !== 0" name="wert" class="niereFeld wertWidth" type="text">{{ wert }}</div>
                      <div v-if="wert === 0" name="wert" class="niereFeld wertWidth" type="text">{{ wert }}</div>
                    </td>
                    <td class="padding8">
                      <span class="wertHeader">ml/min</span>
                    </td>
                  </tr>
                </table>
              </center>
            </div>
          </div>
        </div>
        <div class="niereHeaderMeldung grey" v-if="kreatinFalsch1">
          {{ phrasen.meldung_creatinin3 }}
        </div>
        <div class="niereHeaderMeldung grey" v-if="kreatinFalsch2">
          {{ phrasen.meldung_creatinin4 }}
        </div>
        <div class="niereHeaderMeldung grey" v-if="alterZuAlt">
          {{ phrasen.meldung_alter3 }}
        </div>
        <div class="niereHeaderMeldung grey" v-if="GewichtZuNiedrig">
          {{ phrasen.meldung_gewicht2 }}
        </div>
        <div class="niereHeaderMeldung grey" v-if="gewichtZuHoch2">
          {{ phrasen.meldung_gewicht3 }}
        </div>
        <br>
      <br>
      <div id="modalnieretexte" class="modal">
        <div class="modal-content padding5">
           <HinweisNiere :phrasen="phrasen" />
        </div>
      </div>
      <div id="modalniererberechnen" class="modal">
        <div class="modal-content padding5">
          <div class="modalContentDosierungHeader">
            <table>
              <tr class="trBorderNone">
                <td class="modalDosierungTd">
                  <span class="modalDosierungSpan">{{ phrasen.niere_1 }}</span>
                </td>
                <td class="modalDosierungTd2">
                </td>
                <td class="closeModalTd">
                  <img href="#!" class="modal-close right widthClose" v-on:click="ErgebnisBerechnenUndUebernehmen" src="/img/close.gif">
                </td>
              </tr>
            </table>
          </div>
          <br>
          <div class="padding13">{{ phrasen.niere_2 }}</div>
          <br>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import M from 'materialize-css/dist/js/materialize.min'
import HinweisNiere from '@/components/HinweisNiere.vue'
export default {
  name: 'Nierenberechnung',
  props: ['bestaetigungArzt', 'phrasen', 'sperre'],
  data () {
    return {
      wert: 0,
      ausgewaehlte_daten: [], // Analog zu den Ausgewählten Daten in der DOAK-Übersicht
      keine_nummer_kreatin: false, // Kreatitn Feld mg/dl ist keine Nummer
      keine_nummer_kreatin2: false, // Kreatitn Feld µmol/l ist keine Nummer
      keinKomma: false, // Ist Alter eine Kommezahl?
      gewichtZuHoch: false, // Gewicht unter 30 oder höher als 200kg
      GewichtZuNiedrig: false, // Gewicht unter 40kg
      gewichtZuHoch2: false, // Gewicht über 100 kg
      alterZuJung: false, // Alter ist entwedet keine Nummer oder < 18 oder > 120 Jahre
      alterZuAlt: false, // Alter ist über 90 Jahre
      kreatinFalsch: false, // Kreatinin unter 0.2 oder über 20
      kreatinFalsch1: false, // Kreatinin unter 0.4
      kreatinFalsch2: false, // Kreatinin über 10
      aufDieStartseite: false, // Beim klicken auf zurück soll auf die Startseite weitergeleitet werden
      zurueckKnopfFertig: false, // Zurück Knopf wurde geladen
      geschlechtSpeicher: null, // Geschlecht aus dem LocalStorage
      alterSpeicher: null, // Alter aus dem LocalStorage
      gewichtSpeicher: null, // Gewicht aus dem LocalStorage
      kreatininSpeicher: null // Kreatinin aus dem LocalStorage
    }
  },
  components: {
    HinweisNiere
  },
  methods: {
    overflowEntfernen: function () {
      var elem1 = document.body
      elem1.style.overflow = ''
    },
    // Hier findet die Prüfung für die Altersangabe statt
    alterPruefen: function () {
      this.alterSpeicher = this.$refs.alter.value
      this.wert = 0
      this.keinKomma = false
      this.alterZuJung = false
      this.alterZuAlt = false
      if (this.$refs.alter.value !== '') {
        var alter = String(this.$refs.alter.value)
        var n = alter.indexOf('.')
        var n2 = alter.indexOf(',')
        if (n !== -1 || n2 !== -1) {
          this.keinKomma = true
          return
        }
        if (isNaN(alter)) {
          this.alterZuJung = true
          return
        }
        var alterTemp = parseInt(alter)
        if (alterTemp < 18) {
          this.alterZuJung = true
          return
        }
        if (alterTemp > 120) {
          this.alterZuJung = true
          return
        }
        if (alterTemp > 90) {
          this.alterZuAlt = true
        }
      }
      this.ErgebnisBerechnen()
    },
    // Hier findet die Prüfung für die Gewichtsangabe statt
    gewichtPruefen: function () {
      this.gewichtSpeicher = this.$refs.gewicht.value
      this.wert = 0
      this.gewichtZuHoch = false
      this.GewichtZuNiedrig = false
      this.gewichtZuHoch2 = false
      if (this.$refs.gewicht.value !== '') {
        var gewicht = this.$refs.gewicht.value.replace(',', '.')
        if (isNaN(gewicht)) {
          this.gewichtZuHoch = true
          return
        }
        var gewichtTemp = parseFloat(gewicht)
        if (gewichtTemp < 30) {
          this.gewichtZuHoch = true
          return
        }
        if (gewichtTemp > 200) {
          this.gewichtZuHoch = true
          return
        }
        if (gewichtTemp < 40) {
          this.GewichtZuNiedrig = true
        }
        if (gewichtTemp > 100) {
          this.gewichtZuHoch2 = true
        }
      }
      this.ErgebnisBerechnen()
    },
    // Hier findet die Prüfung für die Kreatininangabe statt. Und es findet eine Umrechnung von mg/dl auf µmol/l statt
    kreatininAbgleichen1: function () {
      this.kreatininSpeicher = this.$refs.kreatinin_1.value
      this.keine_nummer_kreatin = false
      this.wert = 0
      this.kreatinFalsch = false
      this.kreatinFalsch1 = false
      this.kreatinFalsch2 = false
      if (this.$refs.kreatinin_1.value !== '') {
        var kreatininTemp = this.$refs.kreatinin_1.value.replace(',', '.')
        if (isNaN(kreatininTemp)) {
          this.keine_nummer_kreatin = true
          this.$refs.kreatinin_2.value = ''
          return
        } else {
          if (kreatininTemp < 0.2) {
            this.kreatinFalsch = true
            this.$refs.kreatinin_2.value = ''
            return
          }
          if (kreatininTemp > 20) {
            this.kreatinFalsch = true
            this.$refs.kreatinin_2.value = ''
            return
          }
          kreatininTemp = parseFloat(kreatininTemp)
          if (kreatininTemp < 0.4) {
            this.kreatinFalsch1 = true
          }
          if (kreatininTemp > 10) {
            this.kreatinFalsch2 = true
          }
        }
        this.$refs.kreatinin_2.value = Math.round(kreatininTemp * 88.4)
      }
      if (this.$refs.kreatinin_1.value === '') {
        this.$refs.kreatinin_2.value = ''
        this.kreatinFalsch = false
      }
      this.ErgebnisBerechnen()
    },
    // Hier findet die Prüfung für die Kreatininangabe statt. Und es findet eine Umrechnung von µmol/l auf mg/dl statt
    kreatininAbgleichen2: function () {
      this.kreatininSpeicher = this.$refs.kreatinin_1.value
      this.keine_nummer_kreatin2 = false
      this.wert = 0
      this.keinKomma = false
      this.kreatinFalsch = false
      this.kreatinFalsch1 = false
      this.kreatinFalsch2 = false
      if (this.$refs.kreatinin_2.value !== '') {
        var kreatininTemp = this.$refs.kreatinin_2.value.replace(',', '.')
        if (isNaN(kreatininTemp)) {
          this.keine_nummer_kreatin2 = true
          this.$refs.kreatinin_1.value = ''
          this.kreatininSpeicher = this.$refs.kreatinin_1.value
          return
        } else {
          kreatininTemp = parseFloat(kreatininTemp)
          if ((kreatininTemp / 88.4) < 0.2) {
            this.kreatinFalsch = true
            this.$refs.kreatinin_1.value = ''
            this.kreatininSpeicher = this.$refs.kreatinin_1.value
            return
          }
          if ((kreatininTemp / 88.4) > 20) {
            this.kreatinFalsch = true
            this.$refs.kreatinin_1.value = ''
            this.kreatininSpeicher = this.$refs.kreatinin_1.value
            return
          }
          if ((kreatininTemp / 88.4) < 0.4) {
            this.kreatinFalsch1 = true
          }
          if ((kreatininTemp / 88.4) > 10) {
            this.kreatinFalsch2 = true
          }
        }
        var valueTemp = Math.round((kreatininTemp / 88.4) * 100) / 100
        this.$refs.kreatinin_1.value = valueTemp.toString()
        this.kreatininSpeicher = this.$refs.kreatinin_1.value
      }
      if (this.$refs.kreatinin_2.value === '') {
        this.$refs.kreatinin_1.value = ''
        this.kreatininSpeicher = ''
        this.kreatinFalsch = false
      }
      this.ErgebnisBerechnen()
    },
    // Hier wird das Ergebnis berechnet
    ErgebnisBerechnen: function () {
      this.wert = 0
      var zusatz = 1
      if (this.$refs.frau.checked) {
        zusatz = 0.85
      }

      if (this.$refs.alter.value === '' || this.$refs.gewicht.value === '' || this.$refs.kreatinin_1.value === '' || (!this.$refs.frau.checked && !this.$refs.mann.checked)) {
        return
      }
      var alter = String(this.$refs.alter.value)
      var n = alter.indexOf('.')
      var n2 = alter.indexOf(',')
      if (n !== -1 || n2 !== -1) {
        this.keinKomma = true
        return
      }
      alter = this.$refs.alter.value
      var gewicht = this.$refs.gewicht.value.replace(',', '.')
      var kreatinin = this.$refs.kreatinin_1.value.replace(',', '.')
      if (isNaN(alter) || isNaN(gewicht) || isNaN(kreatinin)) {
        this.keine_nummer = true
        return
      }
      if (this.alterZuJung || this.gewichtZuHoch || this.kreatinFalsch) {
        return
      }
      this.keine_nummer = false
      this.wert = (((140 - parseInt(alter, 10)) * parseFloat(gewicht)) * zusatz) / (72 * kreatinin)
      this.wert = Math.round(this.wert)
      var wertString = this.wert.toString()
      var wertMitKomma = wertString.replace('.', ',')
      this.wert = wertMitKomma
    },
    // Hier wird das Ergebnis berechnet die Ergebnisse werden im Localstorage gespeichert
    ErgebnisBerechnenUndUebernehmen: function () {
      var zusatz = 1
      if (this.$refs.frau.checked) {
        zusatz = 0.85
      }
      if (this.$refs.alter.value === '' || this.$refs.gewicht.value === '' || this.$refs.kreatinin_1.value === '') {
        return
      }
      var alter = String(this.$refs.alter.value)
      var n = alter.indexOf('.')
      if (n !== -1) {
        this.alterZuAlt = true
        return
      }

      alter = this.$refs.alter.value.replace(',', '.')
      var gewicht = this.$refs.gewicht.value.replace(',', '.')
      var kreatinin = this.$refs.kreatinin_1.value.replace(',', '.')
      if (isNaN(alter) || isNaN(gewicht) || isNaN(kreatinin)) {
        this.keine_nummer = true
        return
      }
      this.keine_nummer = false
      this.wert = (((140 - parseInt(alter, 10)) * parseFloat(gewicht, 10)) * zusatz) / (72 * kreatinin)
      if (localStorage.ausgewaehlte_daten != null) {
        var ausgewaehlteDatenTemp = JSON.parse(localStorage.ausgewaehlte_daten)
        for (var i = ausgewaehlteDatenTemp.length - 1; i >= 0; i--) {
          this.ausgewaehlte_daten.push(ausgewaehlteDatenTemp[i])
        }
      }
      this.loggeDaten('nierenberechnung_uebernahme')
      for (var j = 1; j <= 4; j++) {
        if (this.ausgewaehlte_daten.includes('n' + j)) {
          this.ausgewaehlte_daten.splice(this.ausgewaehlte_daten.indexOf('n' + j), 1)
        }
      }
      if (this.wert >= 50) {
        this.ausgewaehlte_daten.push('n1')
        this.loggeDaten('niere_gewaehlt_ueber50')
      }
      if (this.wert >= 30 && this.wert < 50) {
        this.ausgewaehlte_daten.push('n2')
        this.loggeDaten('niere_gewaehlt_ueber30')
      }
      if (this.wert >= 15 && this.wert < 30) {
        this.ausgewaehlte_daten.push('n3')
        this.loggeDaten('niere_gewaehlt_ueber15')
      }
      if (this.wert < 15) {
        this.ausgewaehlte_daten.push('n4')
        this.loggeDaten('niere_gewaehlt_unter15')
      }

      for (var j1 = 1; j1 <= 3; j1++) {
        if (this.ausgewaehlte_daten.includes('a' + j1)) {
          this.ausgewaehlte_daten.splice(this.ausgewaehlte_daten.indexOf('a' + j1), 1)
        }
      }
      if (this.$refs.alter.value < 75) {
        this.ausgewaehlte_daten.push('a1')
        this.loggeDaten('alter_gewaehlt_unter75')
      }
      if (this.$refs.alter.value >= 75 && this.$refs.alter.value < 80) {
        this.ausgewaehlte_daten.push('a2')
        this.loggeDaten('alter_gewaehlt_ueber75')
      }
      if (this.$refs.alter.value >= 80) {
        this.ausgewaehlte_daten.push('a3')
        this.loggeDaten('alter_gewaehlt_ueber80')
      }

      for (var j2 = 1; j2 <= 2; j2++) {
        if (this.ausgewaehlte_daten.includes('g' + j2)) {
          this.ausgewaehlte_daten.splice(this.ausgewaehlte_daten.indexOf('g' + j2), 1)
        }
      }
      if (this.$refs.gewicht.value <= 60) {
        this.ausgewaehlte_daten.push('g1')
        this.loggeDaten('gewicht_gewaehlt_unter60')
      } else {
        this.ausgewaehlte_daten.push('g2')
        this.loggeDaten('gewicht_gewaehlt_ueber60')
      }
      localStorage.kreatinin = kreatinin
      localStorage.alter_nierenberechnung = this.$refs.alter.value
      localStorage.gewicht_nierenberechnung = this.$refs.gewicht.value
      localStorage.geschlecht_nierenberechnung = this.$refs.frau.checked
      var usgewaehlteDatenJson2 = JSON.stringify(this.ausgewaehlte_daten)
      localStorage.ausgewaehlte_daten = usgewaehlteDatenJson2
      localStorage.niereberechnet = '1'
      localStorage.kreatinin_clearance = this.wert
      this.$router.push('/therapie')
    },
    // hier findet eine Weiterleitung zur DOAK-Übersicht statt
    niereZurueck: function () {
      localStorage.niereZurueck = '1'
      this.$router.push('/therapie')
    },
    // hier findet eine Weiterleitung zur Startseite statt
    startZurueck: function () {
      localStorage.vonDerStartseite = null
      this.$router.push('/')
    },
    // Hier werden alle gespeicherten Ergebnisse gelöscht
    filterEntfernen: function () {
      this.kreatininSpeicher = null
      this.alterSpeicher = null
      this.gewichtSpeicher = null
      this.geschlechtSpeicher = null
      this.wert = 0
      if (this.$refs.kreatinin_2 != null) {
        this.$refs.kreatinin_2.value = ''
        this.keine_nummer_kreatin = false
        this.keine_nummer_kreatin2 = false
        this.kreatinFalsch = false
        this.kreatinFalsch1 = false
        this.kreatinFalsch2 = false
      }
      if (this.$refs.kreatinin_1 != null) {
        this.$refs.kreatinin_1.value = ''
      }
      if (this.$refs.gewicht != null) {
        this.$refs.gewicht.value = ''
        this.gewichtZuHoch = false
        this.GewichtZuNiedrig = false
        this.gewichtZuHoch2 = false
      }
      if (this.$refs.alter != null) {
        this.$refs.alter.value = ''
        this.keinKomma = false
        this.alterZuJung = false
        this.alterZuAlt = false
      }
      if (this.$refs.mann != null) {
        this.$refs.mann.checked = false
      }
      if (this.$refs.frau != null) {
        this.$refs.frau.checked = false
      }
    },
    // hier werden bestimmte Aktionen aus diesem Vue geloggt und im LocalStorage abgelegt
    loggeDaten: function (id) {
      // hier wird geprüft, ob der User gerade online oder offline ist.
      var statusJetzt = 'offline'
      if (navigator.onLine) {
        statusJetzt = 'online'
      }
      // hier werden die LoggDaten aus dem LocalStorage in ein Array eingelesen und eine Kopie von den LoggDaten aus dem LocalStorage erstellt.
      var loggDaten = []
      var localStorageLoggDatenKopie = null
      if (localStorage.loggDaten != null) {
        loggDaten = JSON.parse(localStorage.loggDaten)
        localStorageLoggDatenKopie = localStorage.loggDaten
      }
      var maxLogEintraege = parseInt(localStorage.maxLogEintraege)
      // wenn die Loggsaten, die Maximallänge nicht überschreiten, dann wird der neue Eintrag in die Daten eingefügt.
      if (loggDaten.length < maxLogEintraege) {
        var neuerLogg = { aktion: id, datum: new Date(), status: statusJetzt }
        loggDaten.push(JSON.stringify(neuerLogg))
        var loggDatenAktuell = JSON.stringify(loggDaten)
        // hier wird versucht, die erweiterten Loggdaten in den LocalStorage zu schreiben. Wenn das schiefläuft, dann werden die alten Loggdaten in den LocalStorage geschrieben.
        try {
          localStorage.loggDaten = loggDatenAktuell
        } catch (e) {
          localStorage.loggDaten = localStorageLoggDatenKopie
        }
      }
    }
  },
  updated () {
    M.AutoInit() // That way, it is only initialized when the component is mounted
  },
  mounted () {
    M.AutoInit() // That way, it is only initialized when the component is mounted
    if (this.bestaetigungArzt === false) {
      this.$router.push({ name: 'Disclaimer' })
    }
    if (this.sperre === true) {
      this.$router.push({ name: 'Sperre' })
    }
    if (localStorage.aufrufNierenberechnungCount != null) {
      var nierenCount = parseInt(localStorage.aufrufNierenberechnungCount, 10)
      nierenCount++
      localStorage.aufrufNierenberechnungCount = nierenCount
    } else {
      localStorage.aufrufNierenberechnungCount = 1
    }
    const con = this
    setTimeout(function () {
      if (localStorage.vonDerStartseite != null && localStorage.vonDerStartseite === 'true') {
        con.aufDieStartseite = true
      }
      con.zurueckKnopfFertig = true
    }, 50)

    if (localStorage.kreatinin != null) {
      this.kreatininSpeicher = localStorage.kreatinin
    }
    if (localStorage.alter_nierenberechnung != null) {
      this.alterSpeicher = localStorage.alter_nierenberechnung
    }
    if (localStorage.gewicht_nierenberechnung != null) {
      this.gewichtSpeicher = localStorage.gewicht_nierenberechnung
    }
    if (localStorage.geschlecht_nierenberechnung != null) {
      this.geschlechtSpeicher = localStorage.geschlecht_nierenberechnung
    }

    setTimeout(function () {
      if (localStorage.kreatinin != null &&
        localStorage.alter_nierenberechnung != null &&
        localStorage.gewicht_nierenberechnung != null &&
        localStorage.geschlecht_nierenberechnung != null) {
        con.ErgebnisBerechnen()
        con.kreatininAbgleichen1()
      }
    }, 100)

    var tempThis = this
    document.addEventListener('filterEntfernt', function () {
      tempThis.filterEntfernen()
    })
  }
}
</script>

<style scoped>

.niereHeader {
    background-color:#1565C0;
    width: 100%;
    margin-top: 20px;
    color: white;
    padding: 15px;
    box-shadow: 3px 3px 3px silver;
    font-size: 14px;
}

.niereHeaderMeldung {
    background-color:#1565C0;
    width: 100%;
    margin-top: 5px;
    color: white;
    padding: 15px;
    box-shadow: 3px 3px 3px silver;
    font-size: 14px;
    margin-bottom: 10px;
}

.infoButton {
  margin-top: 5px;
  margin-right: 35px;
  width: 37px;
  cursor: pointer;
}

.niereHeaderMeldung2 {
    background-color:#1565C0;
    width: 100%;
    margin-top: 5px;
    color: white;
    padding: 15px;
    box-shadow: 3px 3px 3px silver;
    font-size: 14px;
    margin-bottom: 15px;
}

.wertHeader {
  font-size: 14px;
  margin-left: 5px;
}

.niereHeader3 {
    background-color:#1565C0;
    width: 100%;
    margin-top: 5px;
    color: white;
    padding: 15px;
    box-shadow: 3px 3px 3px silver;
    font-size: 14px;
}

.niereHeader2 {
    font-size: 18px;
    font-weight: bold;
}

.niereBewertung {
    width: 100%;
    margin-top: 7px;
    padding: 15px;
    box-shadow: 3px 3px 3px silver;
    font-size: 16px;
}

.kreatinin1 {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.kreatinin2 {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.niereFeld {
  background-color: white !important;
  border: none !important;
  text-align: right;
  font-size: 22px !important;
  letter-spacing: 0px;
  color: #1565C0;
  min-width: 90px;
}

input[type="text"][disabled] {
   color: #1565C0;
   width: 30%;
   margin-top: 5px;
}

.eingabeTd1 {
  width: 65%;
  padding-bottom: 0px;
  padding-top: 0px;
}
.eingabeTd2 {
  width: 15%;
  padding-bottom: 0px;
  padding-top: 0px;
}
.eingabeTd3 {
  width: 20%;
  padding-bottom: 0px;
  padding-top: 0px;
}

.eingabeTd4 {
  width: 80%;
  padding-bottom: 0px;
  padding-top: 0px;
  text-align: right;
  padding-right: 30px;
}

.eingabeTd5 {
  width: 50%;
  padding-bottom: 0px;
  padding-top: 0px;
}

.kreatininTable2 {
  height: 90px;
}

.rowKreatinin{
 margin-bottom: 0px;
}

.wertWidth {
  margin-top: 5px;
  width: 40%;
  text-align: center;
  float:right;
}

.padding8 {
  padding: 8px;
}

.deleteButton {
  width:25px;
  margin-top:5px;
  cursor:pointer
}

.helpButton {
  font-size:40px;
  margin-right:50px;
  margin-top: -4px;
  cursor:pointer
}

.hauptPadding {
  padding-top:10px;
}

.niereTitel {
  font-size:21px;
  margin-left:11px;
  margin-bottom:5px;
}

.ergebnisLi {
  font-size:14px;
  list-style-type: disc !important;
}

.backKnopf {
  margin-top:5px;
  text-transform: none !important;
  width: 100%;
}

.uebernehmenKnopf {
  margin-top:5px;
  text-transform: none !important;
  width: 100%;
}

.hilfeKnopf {
  margin-left: 7px;
  margin-top:7px;
  text-transform: none !important;
}

.feldbeschreibung {
  font-size: calc(6px + 2.5vw) !important;
}
@media screen and (min-width: 320px) {
  .feldbeschreibung {
    font-size: calc(6px + 2.5vw) !important;
  }
  .kreatininTable2 {
    height: 50px;
  }
  .eingabeTd6 {
    height: 30px;
  }
  .eingabeTd7 {
    height: 30px;
  }
}
@media screen and (min-width: 600px) {
  .feldbeschreibung {
    font-size: 20px !important;
  }
}
.literatur {
  font-size: calc(4px + 2vw) !important;
}
@media screen and (min-width: 320px) {
  .literatur {
    font-size: calc(4px + 2vw) !important;
  }
}
@media screen and (min-width: 600px) {
  .literatur {
    font-size: 14px !important;
  }
}
.listenpunkt {
  list-style-type: disc !important;
  margin-left: 20px;
}
.kreatinFalsch1 {
  line-height: 1 !important;
}

@media screen and (min-width: 1000px) {

  .niereFeld {
    background-color: white !important;
    border: none !important;
    text-align: right;
    font-size: 24px !important;
    letter-spacing: 5px;
    color: #1565C0;
  }

  .infoButton {
    margin-top: 10px;
    margin-right: 50px;
    width: 44px;
    cursor: pointer;
  }

  .wertWidth {
    margin-top: 5px;
    width: 40%;
    text-align: center;
    font-size: 20px;
    float:right;
  }

  .eingabeTd4 {
    width: 58%;
    padding-bottom: 0px;
    padding-top: 0px;
    text-align: right;
    padding-right: 0px;
    padding-left: 0px;
  }
  .niereHeader {
        background-color:#1565C0;
        width: 100%;
        margin-top: 20px;
        color: white;
        padding: 15px;
        box-shadow: 3px 3px 3px silver;
        font-size: 18px;
    }

    .eingabeTd7 {
      height: 106px;
    }

    .deleteButton {
      width:30px;
      margin-top:10px;
      cursor:pointer;
    }

    .helpButton {
      font-size:45px;
      margin-right:50px;
      margin-top: 0px;
      cursor:pointer
    }

    .niereHeaderMeldung {
        background-color:#1565C0;
        width: 100%;
        margin-top: 5px;
        color: white;
        padding: 15px;
        box-shadow: 3px 3px 3px silver;
        font-size: 18px;
    }

    .niereHeaderMeldung2 {
        background-color:#1565C0;
        width: 100%;
        margin-top: 5px;
        color: white;
        padding: 15px;
        box-shadow: 3px 3px 3px silver;
        font-size: 18px;
    }

    .backKnopf {
      margin-top:5px;
      text-transform: none !important;
      font-size: 20px;
      width: 100%;
      height: 56px;
    }

    .uebernehmenKnopf {
      margin-top:5px;
      text-transform: none !important;
      width: 100%;
      height: 56px;
      font-size: 20px;
    }

    .wertHeader {
      font-size: 20px;
    }

    .wertHeader1 {
      font-size: 20px;
    }

    .niereHeader2 {
        font-size: 24px;
        font-weight: bold;
    }

    .niereBewertung {
        width: 100%;
        margin-top: 7px;
        padding: 15px;
        box-shadow: 3px 3px 3px silver;
        font-size: 20px;
    }

    .kreatinin1 {
      padding-left: 0px !important;
      padding-right: 3px !important;
    }

    .kreatinin2 {
      padding-left: 3px !important;
      padding-right: 0px !important;
    }
    .kreatininTable2 {
      height: 120px;
    }
    .eingabeTd6 {
      height: 75px;
    }

}

@media screen and (min-width: 1084px) {
  .kreatininTable2 {
      height: 90px;
    }
    .eingabeTd6 {
    height: 70px;
  }
}

@media screen and (min-width: 1124px) {
  .kreatininTable2 {
      height: 60px;
    }
}

@media screen and (min-width: 1200px) {
  .eingabeTd6 {
      height: 71.5px;
    }

    .eingabeTd4 {
    width: 58%;
    padding-bottom: 0px;
    padding-top: 0px;
    text-align: right;
    padding-right: 20px;
  }
}
</style>
