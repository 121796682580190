<template>
  <div>
  <div v-if="fachinfo_offen['Eliquis'] || fachinfo_offen['Lixiana'] || fachinfo_offen['Pradaxa'] || fachinfo_offen['Xarelto']" class="black overflowGrau"></div>
    <div v-if="indikation_ausgewaehlt === false" class="hide-on-med-and-down indikationWaehlen">
      <table>
        <tr class="trBorderNone">
          <td class="trPaddingNull">
            <img src="/img/pfeil_blau_back.png" class="pfeilBack">
          </td>
          <td class="tdIndikationMeldung">
            {{ phrasen.indication_select }}
          </td>
        </tr>
      </table>
    </div>
    <div v-if="niere_ausgewaehlt === false" class="'hide-on-med-and-down niereWaehlen" :style="'top:' + (posNiereTop+3) + 'px;'">
      <table>
        <tr class="trBorderNone">
          <td class="trPaddingNull">
            <img src="/img/pfeil_blau_back.png" class="pfeilBack">
          </td>
          <td class="tdIndikationMeldung">
            {{ phrasen.kidney_select2 }}
          </td>
        </tr>
      </table>
    </div>
    <div v-if="indikation_ausgewaehlt !== false && niere_ausgewaehlt !== false" >
      <template v-for="(ausgabeTexteTemp, key3) in ausgabe_texte">
        <div v-if="fachinfo_offen[key3]" id="modalGroesse" class="modal" v-bind:key="key3 + '_fachinfo'">
          <div class="modal-content padding5">
            <div class="modalContentDosierungHeader">
              <table>
                <tr class="trBorderNone">
                  <td class="modalDosierungTd6">
                    <span class="modalDosierungSpan">{{ phrasen.available_FIs }} {{ key3 }}</span>
                  </td>
                  <td class="modalDosierungTd7">
                  </td>
                  <td class="closeModalTd">
                    <img href="#!" class="modal-close right widthClose" v-on:click="fensterFachinfoSchliessen(key3)" src="/img/close.gif">
                  </td>
                </tr>
              </table>
            </div>
            <div class="fachinfoDiv">
              <br>
              <div class="row fachinfoDiv2">
                <template v-for="(pdf_temp, key4) in ausgabeTexteTemp.pdf">
                  <div class="col s6 m6 l6" v-bind:key="key4">
                    <a target="_blank" :href="pdf_temp" class="fachinfoLinkDesktop">
                      <div class="" style="margin-right:7px;">
                        <center><img class="fachinfoDesktop2" src="/img/fachinfos.gif"></center>
                        <center class="blue-text text-darken-3 fachinfo_link">{{ key4 }}</center>
                      </div>
                    </a>
                    <br>
                  </div>
                </template>
              </div>
              <div class="fachinfoNote">{{ phrasen.fachinfo_note }}</div>
              <br>
            </div>
          </div>
        </div>
        <div class="col s6 m6 l6 hauptDiv" v-bind:key="key3">
          <div class="blue lighten-5 divDOAKUndWirkstoff">
            <a v-if="key3 !== 'Lixiana'" target="_blank" v-on:click="fensterFachinfoOeffnen(key3);loggeDaten('fachinfo_aufruf_'+ key3);" class="fachinfoLinkDesktop">
              <div class="right" style="margin-right:7px;">
                <center><img class="fachinfoDesktop" src="/img/fachinfos.gif"></center>
                <center class="blue-text text-darken-3 fachinfo_link">{{ phrasen.information }}</center>
              </div>
            </a>
            <a v-if="key3 === 'Lixiana'"  :href="ausgabeTexteTemp.pdf" target="_blank" v-on:click="loggeDaten('fachinfo_aufruf_'+ key3);" class="fachinfoLinkDesktop">
              <div class="right" style="margin-right:7px;">
                <center><img class="fachinfoDesktop" src="/img/fachinfos.gif"></center>
                <center class="blue-text text-darken-3 fachinfo_link">{{ phrasen.information }}</center>
              </div>
            </a>
            <span class="doak_name blue-text text-darken-3 uppercase">{{ key3 }} </span><br class="doakName">
            <span class="doak_wirkstoff blue-text text-darken-3 fontWeightNormal">({{ ausgabeTexteTemp.wirkstoff }})</span>
          </div>
          <a class="modal-trigger" :href="'#modal_niere_'+ key3" v-on:click="loggeDaten('dosierungspopup_aufruf_'+ key3)">
          <div v-if="(ausgabeTexteTemp.dosierung != 'Kontraindiziert'
          && !ausgabeTexteTemp.kontraVorhandenKomed)
          && ausgabeTexteTemp.dosierung !== 'Nicht zugelassen'
          && ausgabeTexteTemp.dosierung !== 'Nicht empfohlen'
          && !ausgabeTexteTemp.komedNichtEmpfohlen
          && !ausgabeTexteTemp.vermeidenVorhandenKomed
          && !ausgabeTexteTemp.nichtEinsetzenVorhandenKomed" class="blue darken-3 dosierungsDivBlau">
            <table cellspacing="0" cellpadding="0" class="dosierungsTable">
              <tr class="trBorderNone">
                <td class="dosierungsTd">
                  <span class="dosierungsSpan" v-html="phrasen.vorschlaege"></span>
                </td>
                <td class="dosierungsPfeilTd">
                  <img src="/img/pfeil.png" class="dosierungsPfeil">
                </td>
              </tr>
            </table>
          </div>
          <div v-if="(ausgabeTexteTemp.dosierung == 'Kontraindiziert' || ausgabeTexteTemp.kontraVorhandenKomed)
          && ausgabeTexteTemp.dosierung !== 'Nicht zugelassen'" class="grey dosierungsDivBlau">
            <table cellspacing="0" cellpadding="0" class="dosierungsTable">
              <tr class="trBorderNone">
                <td class="dosierungsTd">
                  <span class="dosierungsSpan black-text" v-html="phrasen.contraindicated2"></span>
                </td>
                <td class="dosierungPfeilTd2">
                  <img src="/img/pfeil_schwarz.png" class="dosierungsPfeil">
                </td>
              </tr>
            </table>
          </div>
          <div v-if="ausgabeTexteTemp.dosierung === 'Nicht zugelassen'" class="grey dosierungsDivBlau">
            <table cellspacing="0" cellpadding="0" class="dosierungsTable">
              <tr class="trBorderNone">
                <td class="dosierungsTd">
                  <span class="dosierungsSpan">{{ phrasen.not_authorized }}</span>
                </td>
                <td class="dosierungPfeilTd2">
                  <img src="/img/pfeil.png" class="dosierungsPfeil">
                </td>
              </tr>
            </table>
          </div>
          <div v-if="(ausgabeTexteTemp.dosierung === 'Nicht empfohlen' || ausgabeTexteTemp.komedNichtEmpfohlen)
          && ausgabeTexteTemp.dosierung != 'Kontraindiziert'
          && !ausgabeTexteTemp.kontraVorhandenKomed
          && !ausgabeTexteTemp.vermeidenVorhandenKomed
          && !ausgabeTexteTemp.nichtEinsetzenVorhandenKomed
          && ausgabeTexteTemp.dosierung !== 'Nicht zugelassen'" class="grey dosierungsDivBlau">
            <table cellspacing="0" cellpadding="0" class="dosierungsTable">
              <tr class="trBorderNone">
                <td class="dosierungsTd">
                  <span class="dosierungsSpan">{{ phrasen.not_recommended }}</span>
                </td>
                <td class="dosierungPfeilTd2">
                  <img src="/img/pfeil.png" class="dosierungsPfeil">
                </td>
              </tr>
            </table>
          </div>
          <div v-if="ausgabeTexteTemp.vermeidenVorhandenKomed
          && ausgabeTexteTemp.dosierung != 'Kontraindiziert'
          && !ausgabeTexteTemp.kontraVorhandenKomed
          && !ausgabeTexteTemp.nichtEinsetzenVorhandenKomed
          && ausgabeTexteTemp.dosierung !== 'Nicht zugelassen'" class="grey dosierungsDivBlau">
            <table cellspacing="0" cellpadding="0" class="dosierungsTable">
              <tr class="trBorderNone">
                <td class="dosierungsTd">
                  <span class="dosierungsSpan">{{ phrasen.avoid }}</span>
                </td>
                <td class="dosierungPfeilTd2">
                  <img src="/img/pfeil.png" class="dosierungsPfeil">
                </td>
              </tr>
            </table>
          </div>
          <div v-if="ausgabeTexteTemp.nichtEinsetzenVorhandenKomed
          && ausgabeTexteTemp.dosierung != 'Kontraindiziert'
          && !ausgabeTexteTemp.kontraVorhandenKomed
          && ausgabeTexteTemp.dosierung !== 'Nicht zugelassen'" class="grey dosierungsDivBlau">
            <table cellspacing="0" cellpadding="0" class="dosierungsTable">
              <tr class="trBorderNone">
                <td class="dosierungsTd">
                  <span class="dosierungsSpan">{{ phrasen.dont_use2 }}</span>
                </td>
                <td class="dosierungPfeilTd2">
                  <img src="/img/pfeil.png" class="dosierungsPfeil">
                </td>
              </tr>
            </table>
          </div>
          </a>
          <a v-if="ausgabeTexteTemp != null" class="modal-trigger" :href="'#modal_komedKontra_'+ key3" v-on:click="loggeDaten('kontrapopup_aufruf_'+ key3)">
          <div class="blue lighten-5 kontraDiv">
            <table cellspacing="0" cellpadding="0" class="dosierungsTable">
              <tr class="trBorderNone">
                <td class="dosierungsTd">
                  <span class="blue-text text-darken-3 kontraSpan" v-html="phrasen.contraindications"></span>
                </td>
                <td class="dosierungPfeilTd2">
                  <img src="/img/pfeil_blau.png" class="dosierungsPfeil">
                </td>
              </tr>
            </table>
          </div>
          </a>
          <!--

                Dosierungsinfo-Popup

          -->
          <div :id="'modal_niere_'+ key3" class="modal modal_eigen">
            <!--
                Dosierungsinfo für Desktops
            -->
            <div class="modal-content hide-on-small-only modalContentDosierung">
              <div class="modalContentDosierungHeader">
                <table>
                  <tr class="trBorderNone">
                    <td class="modalDosierungTd">
                      <span class="modalDosierungSpan">{{ phrasen.indication }}</span>
                    </td>
                    <td class="modalDosierungTd2" v-html="filter_texte.indikation">
                    </td>
                    <td class="closeModalTd">
                      <img href="#!" class="modal-close right widthClose" src="/img/close.gif">
                    </td>
                  </tr>
                </table>
              </div>
              <div class="divModalDosierung">
                <table>
                  <tr class="trBorderNone">
                    <td class="modalDosierungTd3">
                      <b>{{ phrasen.dosage_according_information }}</b>
                    </td>
                    <td class="modalDosierungTd4">
                      <center>
                        <span v-if="!ausgabeTexteTemp.vermeidenVorhandenKomed &&
                        !ausgabeTexteTemp.nichtEinsetzenVorhandenKomed &&
                        !ausgabeTexteTemp.kontraVorhandenKomed &&
                        ausgabeTexteTemp.dosierung != null &&
                        ausgabeTexteTemp.dosierung != 'Nicht zugelassen' &&
                        !ausgabeTexteTemp.komedNichtEmpfohlen &&
                        ausgabeTexteTemp.dosierung !== 'Nicht empfohlen' &&
                        ausgabeTexteTemp.dosierung !== 'Kontraindiziert'" class="modalDosierungSpan2">{{ ausgabeTexteTemp.dosierung }} {{ ausgabeTexteTemp.wirkstoff }}</span>
                        <span v-if="!ausgabeTexteTemp.kontraVorhandenKomed && ausgabeTexteTemp.dosierung != null && ausgabeTexteTemp.dosierung == 'Nicht zugelassen'" class="modalDosierungSpan2">{{ ausgabeTexteTemp.wirkstoff }} {{ phrasen.is_not_authorized }}</span>
                        <span v-if="(!ausgabeTexteTemp.kontraVorhandenKomed && ausgabeTexteTemp.dosierung != null && ausgabeTexteTemp.dosierung == 'Kontraindiziert') || ausgabeTexteTemp.kontraVorhandenKomed" class="modalDosierungSpan2">{{ ausgabeTexteTemp.wirkstoff }} {{ phrasen.is_contraindicated }}
                          <span v-if="ausgabeTexteTemp.dosierung == 'Kontraindiziert' && !ausgabeTexteTemp.kontraVorhandenKomed">(Nierenfunktion <span class="boldNoWrap">{{ausgabeTexteTemp.kontraNiereText}}</span>)</span>
                          <span v-if="ausgabeTexteTemp.dosierung != 'Kontraindiziert' && ausgabeTexteTemp.kontraVorhandenKomed">({{ausgabeTexteTemp.kontraKomedText}})</span>
                          <span v-if="ausgabeTexteTemp.dosierung == 'Kontraindiziert' && ausgabeTexteTemp.kontraVorhandenKomed">(Nierenfunktion <span class="boldNoWrap">{{ausgabeTexteTemp.kontraNiereText}}</span>, {{ausgabeTexteTemp.kontraKomedText}})</span>
                        </span>
                        <span v-if="!ausgabeTexteTemp.vermeidenVorhandenKomed &&
                        !ausgabeTexteTemp.nichtEinsetzenVorhandenKomed &&
                        !ausgabeTexteTemp.kontraVorhandenKomed &&
                        ausgabeTexteTemp.dosierung != 'Kontraindiziert' &&
                        ausgabeTexteTemp.dosierung != 'Nicht Zugelassen' &&
                        ausgabeTexteTemp.dosierung != null &&
                        (ausgabeTexteTemp.dosierung === 'Nicht empfohlen' || ausgabeTexteTemp.komedNichtEmpfohlen)" class="modalDosierungSpan2">{{ ausgabeTexteTemp.wirkstoff }} {{ phrasen.is_not_recommended }}</span>
                        <span v-if="ausgabeTexteTemp.vermeidenVorhandenKomed &&
                        !ausgabeTexteTemp.nichtEinsetzenVorhandenKomed &&
                        !ausgabeTexteTemp.kontraVorhandenKomed &&
                        ausgabeTexteTemp.dosierung != 'Kontraindiziert' &&
                        ausgabeTexteTemp.dosierung != 'Nicht Zugelassen' &&
                        ausgabeTexteTemp.dosierung != null" class="modalDosierungSpan2">{{ ausgabeTexteTemp.wirkstoff }} {{ phrasen.avoid2 }}</span>
                        <span v-if="ausgabeTexteTemp.nichtEinsetzenVorhandenKomed &&
                        !ausgabeTexteTemp.kontraVorhandenKomed &&
                        ausgabeTexteTemp.dosierung != 'Kontraindiziert' &&
                        ausgabeTexteTemp.dosierung != 'Nicht Zugelassen' &&
                        ausgabeTexteTemp.dosierung != null" class="modalDosierungSpan2">{{ ausgabeTexteTemp.wirkstoff }} {{ phrasen.dont_use }}</span>
                      </center>
                    </td>
                    <td class="modalDosierungTd5">
                    </td>
                  </tr>
                </table>
              </div>
              <div class="modalHinweiseDiv">
                <table class="borderCollapse">
                  <tr class="trBorderNone">
                    <td class="modalHinweiseTd">
                      <b>{{ phrasen.notes }}</b>
                    </td>
                    <td class="verticalAlignTop">
                      <div class="lineHeight11">
                        <span v-if="!ausgabeTexteTemp.kontraVorhandenKomed && ausgabeTexteTemp.hinweis != null" v-html="ausgabeTexteTemp.hinweis" class="dosierungsvor fontWeightNormal"></span>
                        <br v-if="ausgabeTexteTemp.hinweis != ''"><br v-if="ausgabeTexteTemp.hinweis != ''">
                        <template v-for="(komedikation_text, key2) in ausgabeTexteTemp.komedikation">
                            <div v-bind:key="key2" class="dosierungsvor fontWeightNormal">
                              <b>{{ key2 }}: </b>
                              <span v-html="komedikation_text" v-if="komedikation_text.includes('Kontraindiziert')" class="fontBold"></span>
                              <span v-html="komedikation_text" v-if="!komedikation_text.includes('Kontraindiziert')"></span>
                              <div class="height7"></div>
                            </div>
                        </template>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
              <div class="filterDiv">
                <span class="filterSpan">{{ phrasen.selected_filters }}</span>
                <table class="borderCollapse">
                  <tr class="trBorderNone">
                    <td v-if="ausgabeTexteTemp.kreatininAusgabe == 'null' || ausgabeTexteTemp.kreatininAusgabe == 0 || ausgabeTexteTemp.kreatininAusgabe == 'NaN'" class="filterNierenFunktionTd">
                      <span class="grey-text text-lighten-1">{{ phrasen.kidney_function }}:</span><br>
                      <template v-for="(filter_text, key) in filter_texte.niere">
                        &nbsp;<span v-bind:key="key" v-html="filter_text" v-if="key !== 'vorhanden'" class="dosierungsvor boldNoWrap"></span>
                      </template>
                      <span v-if="!filter_texte.niere.vorhanden" class="boldNiWrap">&nbsp;?</span>
                    </td>
                    <td v-if="ausgabeTexteTemp.kreatininAusgabe != 'null' && ausgabeTexteTemp.kreatininAusgabe != 0 && ausgabeTexteTemp.kreatininAusgabe != 'NaN'" class="filterNierenFunktionTd2">
                      <span class="grey-text text-lighten-1">{{ phrasen.kidney_function }}:</span><br>
                      <template v-for="(filter_text, key) in filter_texte.niere">
                        &nbsp;<span v-bind:key="key" v-html="filter_text" v-if="key !== 'vorhanden'" class="dosierungsvor boldNoWrap"></span>
                      </template>
                      <span v-if="!filter_texte.niere.vorhanden" class="boldNoWrap">&nbsp;?</span>
                    </td>
                    <td class="filterTd">
                      <span class="grey-text text-lighten-1">{{ phrasen.creatinine }}: </span><br>
                      <span v-if="ausgabeTexteTemp.kreatininAusgabe != 'null' && ausgabeTexteTemp.kreatininAusgabe != 0 && ausgabeTexteTemp.kreatininAusgabe != 'NaN'" class="boldNoWrap dosierungsvor">{{ ausgabeTexteTemp.kreatininAusgabe }} mg/dl</span>
                      <span v-if="ausgabeTexteTemp.kreatininAusgabe == 'null' || ausgabeTexteTemp.kreatininAusgabe == 0 || ausgabeTexteTemp.kreatininAusgabe == 'NaN'" class="boldNoWrap dosierungsvor">?</span>
                    </td>
                    <td class="filterTd">
                      <span class="grey-text text-lighten-1">{{ phrasen.weight }}: </span><br>
                      <span v-if="filter_texte.gewicht !== false" v-html="filter_texte.gewicht" class="boldNoWrap dosierungsvor"></span>
                      <span v-if="filter_texte.gewicht === false" class="boldNoWrap dosierungsvor">?</span>
                    </td>
                    <td class="filterTd">
                      <span class="grey-text text-lighten-1">{{ phrasen.age }}: </span><br>
                      <span v-if="filter_texte.alter !== false" v-html="filter_texte.alter" class="boldNoWrap dosierungsvor"></span>
                      <span v-if="filter_texte.alter === false" class="boldNoWrap dosierungsvor">?</span>
                    </td>
                  </tr>
                </table>
                <div class="marginLeft5"><span v-if="!ausgabeTexteTemp.kontraVorhandenKomed && ausgabeTexteTemp.begruendung != null" v-html="ausgabeTexteTemp.begruendung" class="dosierungsvor fontWeightNormal" /></div>
              </div>
            </div>
            <!--
                Dosierungsinfo für Smartphones
            -->
            <div class="modal-content hide-on-med-and-up modalContentDosierungMobil">
              <div class="modalContentDosierungHeader">
                <table>
                  <tr class="trBorderNone">
                    <td class="modalContentDosierungMobilIndikation" v-html="filter_texte.indikation">
                    </td>
                    <td class="modalContentDosierungMobilClose">
                      <img href="#!" class="modal-close right closeGifMobil" src="/img/close.gif" >
                    </td>
                  </tr>
                </table>
              </div>
              <div class="divModalDosierung">
                <table class="borderCollapse">
                  <tr class="trBorderNone">
                    <td class="modalContentDosierungMobilDosis">
                      <b>{{ phrasen.dosage_according_information }}</b><br>
                        <span v-if="!ausgabeTexteTemp.vermeidenVorhandenKomed &&
                        !ausgabeTexteTemp.nichtEinsetzenVorhandenKomed &&
                        !ausgabeTexteTemp.kontraVorhandenKomed &&
                        ausgabeTexteTemp.dosierung != null &&
                        ausgabeTexteTemp.dosierung != 'Nicht zugelassen' &&
                        !ausgabeTexteTemp.komedNichtEmpfohlen &&
                        ausgabeTexteTemp.dosierung !== 'Nicht empfohlen' &&
                        ausgabeTexteTemp.dosierung !== 'Kontraindiziert'" class="modalDosierungMobileFont">{{ ausgabeTexteTemp.dosierung }} {{ ausgabeTexteTemp.wirkstoff }}</span>
                        <span v-if="!ausgabeTexteTemp.kontraVorhandenKomed && ausgabeTexteTemp.dosierung != null && ausgabeTexteTemp.dosierung == 'Nicht zugelassen'" class="modalDosierungMobileFont">{{ ausgabeTexteTemp.wirkstoff }} {{ phrasen.is_not_authorized }}</span>
                        <span v-if="(!ausgabeTexteTemp.kontraVorhandenKomed && ausgabeTexteTemp.dosierung != null && ausgabeTexteTemp.dosierung == 'Kontraindiziert') || ausgabeTexteTemp.kontraVorhandenKomed" class="modalDosierungMobileFont">{{ ausgabeTexteTemp.wirkstoff }} {{ phrasen.is_contraindicated }}
                          <span v-if="ausgabeTexteTemp.dosierung == 'Kontraindiziert' && !ausgabeTexteTemp.kontraVorhandenKomed">(Nierenfunktion <span class="boldNoWrap">{{ausgabeTexteTemp.kontraNiereText}}</span>)</span>
                          <span v-if="ausgabeTexteTemp.dosierung != 'Kontraindiziert' && ausgabeTexteTemp.kontraVorhandenKomed">({{ausgabeTexteTemp.kontraKomedText}})</span>
                          <span v-if="ausgabeTexteTemp.dosierung == 'Kontraindiziert' && ausgabeTexteTemp.kontraVorhandenKomed">(Nierenfunktion <span class="boldNoWrap">{{ausgabeTexteTemp.kontraNiereText}}</span>, {{ausgabeTexteTemp.kontraKomedText}})</span>
                        </span>
                        <span v-if="!ausgabeTexteTemp.vermeidenVorhandenKomed &&
                        !ausgabeTexteTemp.nichtEinsetzenVorhandenKomed &&
                        !ausgabeTexteTemp.kontraVorhandenKomed &&
                        ausgabeTexteTemp.dosierung != 'Kontraindiziert' &&
                        ausgabeTexteTemp.dosierung != 'Nicht Zugelassen' &&
                        ausgabeTexteTemp.dosierung != null &&
                        (ausgabeTexteTemp.dosierung === 'Nicht empfohlen' || ausgabeTexteTemp.komedNichtEmpfohlen)" class="modalDosierungMobileFont">{{ ausgabeTexteTemp.wirkstoff }} {{ phrasen.is_not_recommended }}</span>
                        <span v-if="ausgabeTexteTemp.vermeidenVorhandenKomed &&
                        !ausgabeTexteTemp.nichtEinsetzenVorhandenKomed &&
                        !ausgabeTexteTemp.kontraVorhandenKomed &&
                        ausgabeTexteTemp.dosierung != 'Kontraindiziert' &&
                        ausgabeTexteTemp.dosierung != 'Nicht Zugelassen' &&
                        ausgabeTexteTemp.dosierung != null" class="modalDosierungMobileFont">{{ ausgabeTexteTemp.wirkstoff }} {{ phrasen.avoid2 }}</span>
                        <span v-if="ausgabeTexteTemp.nichtEinsetzenVorhandenKomed &&
                        !ausgabeTexteTemp.kontraVorhandenKomed &&
                        ausgabeTexteTemp.dosierung != 'Kontraindiziert' &&
                        ausgabeTexteTemp.dosierung != 'Nicht Zugelassen' &&
                        ausgabeTexteTemp.dosierung != null" class="modalDosierungMobileFont">{{ ausgabeTexteTemp.wirkstoff }} {{ phrasen.dont_use }}</span>
                    </td>
                  </tr>
                </table>
              </div>
              <div class="modalHinweiseDiv">
                <table class="borderCollapse">
                  <tr class="trBorderNone">
                    <td class="hinweiseMobileTd">
                      <b>{{ phrasen.notes }}</b><br>
                      <div class="modalHinweiseDivTd">
                      </div>
                    </td>
                    <td class="modalContentDosierungMobilIndikation">
                      <div class="lineHeight11">
                        <span v-if="!ausgabeTexteTemp.kontraVorhandenKomed && ausgabeTexteTemp.hinweis != ''" v-html="ausgabeTexteTemp.hinweis" class="dosierungsvor fontWeightNormal" />
                        <br v-if="ausgabeTexteTemp.hinweis != ''"><br v-if="ausgabeTexteTemp.hinweis != ''">
                        <template v-for="(komedikation_text, key2) in ausgabeTexteTemp.komedikation">
                            <div v-bind:key="key2" class="dosierungsvor komedDesktop">
                              <b>{{ key2 }}: </b>
                              <span v-html="komedikation_text" v-if="komedikation_text.includes('Kontraindiziert')" class="fontBold"></span>
                              <span v-html="komedikation_text" v-if="!komedikation_text.includes('Kontraindiziert')"></span>
                              <div class="height7"></div>
                            </div>
                        </template>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
              <div class="filterDiv">
                <span class="filterMobile">{{ phrasen.selected_filters }}</span><br>
                <span class="grey-text text-lighten-1 dosierungsvor filterMobile2">{{ phrasen.kidney_function }}:
                <template v-for="(filter_text, key) in filter_texte.niere">
                  <span v-bind:key="key" v-html="filter_text" v-if="key !== 'vorhanden'" class="filterMobile3 dosierungsvor"></span>
                </template>
                <span v-if="!filter_texte.niere.vorhanden" class="filterMobile4">&nbsp;?</span>
                </span>
                &shy;<span class="filterMobile2 grey-text text-lighten-1 dosierungsvor">{{ phrasen.creatinine }}:
                  <span v-if="ausgabeTexteTemp.kreatininAusgabe != 'null' && ausgabeTexteTemp.kreatininAusgabe != 0 && ausgabeTexteTemp.kreatininAusgabe != 'NaN'" class="filterMobile3 dosierungsvor">{{ ausgabeTexteTemp.kreatininAusgabe }} mg/dl</span>
                  <span v-if="ausgabeTexteTemp.kreatininAusgabe == 'null' || ausgabeTexteTemp.kreatininAusgabe == 0 || ausgabeTexteTemp.kreatininAusgabe == 'NaN'" class="filterMobile3 dosierungsvor">?</span>
                </span>
                &shy;<span class="filterMobile2 grey-text text-lighten-1 dosierungsvor">{{ phrasen.weight }}:
                  <span v-if="filter_texte.gewicht !== false" v-html="filter_texte.gewicht" class="filterMobile3 dosierungsvor"></span>
                  <span v-if="filter_texte.gewicht === false" class="filterMobile3 dosierungsvor">?</span>
                </span>
                &shy;<span class="filterMobile2 grey-text text-lighten-1 dosierungsvor">{{ phrasen.age }}:
                  <span v-if="filter_texte.alter !== false" v-html="filter_texte.alter" class="filterMobile3 dosierungsvor"></span>
                  <span v-if="filter_texte.alter === false" class="filterMobile3 dosierungsvor">?</span>
                </span>
                <div class="filterBegruendung">
                <span v-if="!ausgabeTexteTemp.kontraVorhandenKomed && ausgabeTexteTemp.begruendung != null" v-html="ausgabeTexteTemp.begruendung" class="dosierungsvor fontWeightNormal" />
                </div>
              </div>
            </div>
              <!--

                Kontraindikations-Popup

              -->
          </div>
          <div :id="'modal_komedKontra_'+ key3" class="modal modal_eigen">
            <!--
                Kontraindikations-Anzeige für Desktops
            -->
            <div class="modal-content hide-on-small-only modalContentDosierung">
              <div class="modalContentDosierungHeader">
                <table>
                  <tr class="trBorderNone">
                    <td class="kontraHeader">
                      <span class="modalDosierungTrDesktop">{{ phrasen.komed_to }} {{ ausgabeTexteTemp.wirkstoff }}</span><br>
                      ({{ phrasen.filter_independent }})
                    </td>
                    <td class="closeTd">
                      <img href="#!" class="modal-close right kontraClose" src="/img/close.gif">
                    </td>
                  </tr>
                </table>
              </div>
              <div v-if="ausgabeTexteTemp.kontraindikationen.niere != null" class="closeDiv">
                <table class="borderCollapse">
                  <tr class="trBorderNone">
                    <td class="kontraTd">
                      <b>{{ phrasen.is_contraindicated_with }}:</b>
                    </td>
                    <td class="kontraNiere">
                      <span class="dosierungsvor kontraSpan2"><div>{{ phrasen.kidney_function }} {{ ausgabeTexteTemp.kontraindikationen.niere }}</div></span>
                    </td>
                  </tr>
                </table>
              </div>
              <div class="closeDiv">
                <table class="borderCollapse">
                  <tr class="trBorderNone">
                    <td class="kontraTd">
                      <b>{{ phrasen.contraindicated_komed }}:</b>
                    </td>
                    <td class="kontraKomed">
                      <template v-for="(komedikation_text, key2) in ausgabeTexteTemp.kontraindikationen.komedNamen">
                        <div v-bind:key="key2" class="marginTop3">
                          <span class="dosierungsvor fontWeightNormal">{{ komedikation_text }}<br></span>
                        </div>
                      </template>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <!--
                Kontraindikations-Anzeige für Smartphones
            -->
            <div class="modal-content hide-on-med-and-up modalContentDosierung">
              <div class="modalContentDosierungHeader">
                <table>
                  <tr class="trBorderNone">
                    <td class="modalContentDosierungMobilClose">
                      <span class="modalDosierungTr">{{ phrasen.komed_to }} {{ ausgabeTexteTemp.wirkstoff }}</span><br>
                      ({{ phrasen.filter_independent }})
                    </td>
                    <td class="modalContentDosierungMobilClose">
                      <img href="#!" class="modal-close right kontraClose2" src="/img/close.gif">
                    </td>
                  </tr>
                </table>
              </div>
              <div v-if="ausgabeTexteTemp.kontraindikationen.niere != null" class="modalHinweiseDiv">
                <table class="borderCollapse">
                  <tr class="trBorderNone">
                    <td class="modalContentDosierungMobilClose">
                      <b>{{ phrasen.is_contraindicated_with }}:</b><br>
                      <span class="dosierungsvor fontWeightNormal">{{ phrasen.kidney_function }} {{ ausgabeTexteTemp.kontraindikationen.niere }}<br></span>
                    </td>
                  </tr>
                </table>
              </div>
              <div class="kontraDiv2">
                <table class="borderCollapse">
                  <tr class="trBorderNone">
                    <td class="modalContentDosierungMobilClose">
                      <b>{{ phrasen.contraindicated_komed }}:</b><br>
                      <template v-for="(komedikation_text, key2) in ausgabeTexteTemp.kontraindikationen.komedNamen">
                        <div v-bind:key="key2">
                          <span class="dosierungsvor fontWeightNormal">{{ komedikation_text }}<br></span>
                        </div>
                      </template>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import M from 'materialize-css/dist/js/materialize.min'
export default {
  data () {
    return {
      kontraNiere: false,
      fachinfo_offen: { Eliquis: false, Lixiana: false, Pradaxa: false, Xarelto: false }
    }
  },
  methods: {
    // hier werden bestimmte Aktionen aus dieser Komponente geloggt und im LocalStorage abgelegt
    loggeDaten: function (id) {
      // hier wird geprüft, ob der User gerade online oder offline ist.
      var statusJetzt = 'offline'
      if (navigator.onLine) {
        statusJetzt = 'online'
      }
      // hier werden die LoggDaten aus dem LocalStorage in ein Array eingelesen und eine Kopie von den LoggDaten aus dem LocalStorage erstellt.
      var loggDaten = []
      var localStorageLoggDatenKopie = null
      if (localStorage.loggDaten != null) {
        loggDaten = JSON.parse(localStorage.loggDaten)
        localStorageLoggDatenKopie = localStorage.loggDaten
      }
      var maxLogEintraege = parseInt(localStorage.maxLogEintraege)
      // wenn die Loggsaten, die Maximallänge nicht überschreiten, dann wird der neue Eintrag in die Daten eingefügt.
      if (loggDaten.length < maxLogEintraege) {
        var neuerLogg = { aktion: id, datum: new Date(), status: statusJetzt }
        loggDaten.push(JSON.stringify(neuerLogg))
        var loggDatenAktuell = JSON.stringify(loggDaten)
        // hier wird versucht, die erweiterten Loggdaten in den LocalStorage zu schreiben. Wenn das schiefläuft, dann werden die alten Loggdaten in den LocalStorage geschrieben.
        try {
          localStorage.loggDaten = loggDatenAktuell
        } catch (e) {
          localStorage.loggDaten = localStorageLoggDatenKopie
        }
      }
    },
    // mit dieser Funktion wird das Fachinfo-Popup zu einem bestimmten DOAK geöffnet
    fensterFachinfoOeffnen: function (key) {
      this.fachinfo_offen[key] = true
    },
    // mit dieser Funktion wird das Fachinfo-Popup zu einem bestimmten DOAK geschlossen
    fensterFachinfoSchliessen: function (key) {
      this.fachinfo_offen[key] = false
    }
  },
  props: ['ausgabe_texte', 'indikation_ausgewaehlt', 'niere_ausgewaehlt', 'filter_texte', 'phrasen', 'posNiereTop'],
  updated () {
    M.AutoInit() // That way, it is only initialized when the component is mounted
  },
  mounted () {
    M.AutoInit() // That way, it is only initialized when the component is mounted
  }
}
</script>

<style scoped>
.modal_eigen{
  max-width: 700px !important;
}

.trBorderNone {
  border: none;
}

.doakName {
  display: inline;
}

.trPaddingNull {
  padding: 0px;
  width: 30px;
}

.kontraDiv2 {
    color: rgb(21, 101, 192);
    background-color:white;
    padding:10px;
    box-shadow: 3px 3px 3px silver;
    margin-top:5px;
    min-height:90px;
}

.pfeilBack {
  height:22px;
  margin-right:10px;
  margin-top:5px;
}

.marginTop3 {
    margin-top:3px;
}

.tdIndikationMeldung {
  padding:0;
  padding-top:0px;
  color:#1565C0;
}

.hauptDiv {
  padding-left:6px;
  padding-right:6px;
  margin-top:12px;
}

.kontraTd {
    width:35%;
    vertical-align:top;
    padding-top:7px;
    padding-bottom:7px;
    font-size:18px;
}

.kontraHeader {
    width:85%;
    vertical-align:top;
    padding-top:10px;
    padding-bottom:7px;
}

.closeTd {
  vertical-align: top;
}

.kontraSpan2 {
    font-weight:normal;
    vertical-align:top;
}

.filterBegruendung {
    margin-left:5px;
    margin-top:5px;
    line-height: 1.1 !important;
}

.divDOAKUndWirkstoff {
  text-align:left;
  padding-left:3%;
  padding-top:12px;
  padding-bottom:12px;
  box-shadow: 3px 3px 3px silver;
  height: 80px;
}

.fontWeightNormal {
  font-weight:normal;
}

.uppercase {
  text-transform: uppercase;
}

.verticalAlignTop {
  vertical-align: top;
}

.kontraKomed {
  vertical-align: top;
  padding-top: 5px;
}

.kontraNiere {
  vertical-align: top;
  padding-top: 10px;
}

.lineHeight11 {
  line-height: 1.1 im !important;
}

.marginTop8 {
  margin-top:8px;
}

.modalDosierungTr {
  font-size: 18px;
}
.modalDosierungTrDesktop {
  font-size: 20px;
}

.dosierungsDivBlau {
  margin-top: 5px;
  text-align:left;
  padding-left:3%;
  box-shadow: 3px 3px 3px silver;
  padding-top: 6px;
  padding-bottom:6px;
}

.komedDesktop {
    font-weight:normal;
    margin-top:2px;
}

.filterMobile {
    margin-left:6px;
    font-weight:bold;
    margin-left:5px;
}

.filterMobile2 {
    margin-left:6px;
    white-space: nowrap;
}

.filterMobile3 {
    font-weight:bold;
    color:white;
}

.filterMobile4 {
    font-weight:bold;
    white-space: nowrap;
    color:white
}

.height7 {
  height: 7px;
}

.dosierungsTd {
  width:80%;
  padding-top: 3px;
  padding-bottom:3px;
  padding-left:0px;
  padding-right:0px;
}

.indikationWaehlen {
    margin-top:14px;
}

.niereWaehlen {
  position:absolute;
  display: none;
}

.kontraClose {
    width:25px;
}

.kontraClose2 {
    width:20px;
}

.filterTd {
    vertical-align:top;
    padding:5px;
}

.filterDiv {
  background-color: rgb(21, 101, 192);
  color:white;
  padding:10px;
  box-shadow: 3px 3px 3px silver;
  margin-top:5px;
}

.closeModalTd {
  vertical-align:top;
  padding-top:9px;
  padding-bottom:7px;
}

.fontsizeFachinfoDesktop {
    font-size: 13px;
}

.fachinfoDiv {
  margin-top: 5px;
  text-align:left;
  padding-left:3%;
  box-shadow: 3px 3px 3px silver;
  width:65%;
  float:left;
}

.fachinfoDiv2 {
  width: 100%;
}

.closeDiv {
    color: rgb(21, 101, 192);
    background-color:white;
    padding:10px;
    box-shadow: 3px 3px 3px silver;
    margin-top:5px;
    min-height:120px;
}

.fachinfoDesktop {
    height: 30px;
}

.fachinfoDesktop2 {
    height: 35px;
}

.widthClose {
  width:25px;
}

.hinweiseMobileTd {
    width:30%;
    vertical-align:top;
    padding-top:0px;
    padding-bottom:0px;
}

.dosierungsSpan {
  color:white;
  line-height:0.5;
  font-size:18px;
}

.closeGifMobil {
    width: 20px;
}

.filterNierenFunktionTd {
    vertical-align:top;
    padding:5px;
    width:36%;
}

.filterNierenFunktionTd2 {
    vertical-align:top;
    padding:5px;
    width:35%;
}

.boldNoWrap {
    font-weight:bold;
    white-space: nowrap;
}

.divModalDosierung {
  color: rgb(21, 101, 192);
  background-color:white;
  padding:10px;
  box-shadow: 3px 3px 3px silver;
  margin-top:5px;
}

.borderCollapse {
  border-collapse: none;
}

.modalHinweiseTd {
  width:25%;
  vertical-align:top;
  font-size:18px;
  line-height: 1.1 !important;
}

.fachinfoTd {
  padding-top:3px;
  padding-bottom:3px;
  vertical-align:middle;
}

.fachinfoTr {
  padding-top:3px;
  padding-bottom:3px;
  vertical-align:middle;
}

.modalHinweiseDiv {
  color: rgb(21, 101, 192);
  background-color:white;
  padding:10px;
  box-shadow: 3px 3px 3px silver;
  margin-top:5px;
  min-height:70px;
}

.modalHinweiseDivTd {
    float:left;
    margin-top:5px;
    margin-left:3px;
}

.modalContentDosierung {
  padding:5px;
  text-align:left;
  font-weight:normal;
}

.modalContentDosierungMobil{
    overflow-x: auto;
    padding:5px;
    text-align:left;
    font-weight:normal;
}

.modalContentDosierungMobilIndikation {
    padding-top:0px;
    padding-bottom:0px;
}

.modalContentDosierungMobilDosis {
    width:30%;
    padding-top:0px;
    padding-bottom:0px;
}

.modalContentDosierungMobilClose {
    vertical-align:top;
    padding-top:0px;
    padding-bottom:0px;
}

.modalDosierungTd3 {
  width:25%;
  vertical-align:top;
  font-size:18px;
}

.modalDosierungTd4 {
  width:50%;
  padding-left:0px;
  padding-right:0px;
}

.modalDosierungTd5 {
  vertical-align:top;
  padding-top:20px;
}

.filterSpan {
  font-weight:bold;
  margin-left:5px;
  font-size:18px;
}

.fontBold {
  font-weight:bold;
}

.modalContentDosierungHeader {
  background-color: rgb(21, 101, 192);
  color:white;
  padding:10px;
  box-shadow: 3px 3px 3px silver;
}

.modalDosierungSpan2 {
  font-size:25px;
  font-weight:bold;
}

.modalDosierungMobileFont {
    font-size:22px;
    font-weight:bold;
}

.modalFachinfo {
  height:35px;
}

.gbaDiv {
  width:33%;
  float:left;
  margin-left:2%;
}

.marginLeft5 {
    margin-left: 5px;
}

.dosierungsPfeilTd {
  padding-top: 8px;
  padding-bottom:8px;
  width:20%;
  padding-left:0px;
  padding-right:5px;
}

.floatRight {
  float: right;
}

.dosierungsPfeil {
  width:20px;
  float:right;
}

.dosierungsTable {
  vertical-align: middle;
  height:60px;
}

.modalDosierungSpan {
  font-size:18px;
  font-weight:bold;
}

.dosierungPfeilTd2 {
  padding-top: 6px;
  padding-bottom:3px;
  width:20%;
  padding-left:0px;
  padding-right:5px;
}

.kontraDiv {
  margin-top: 5px;
  text-align:left;
  padding-left:3%;
  box-shadow: 3px 3px 3px silver;
}

.modalDosierungTd {
  width:25%;
  vertical-align:top;
  padding-top:7px;
  padding-bottom:7px;
}

.modalDosierungTd2 {
  width:50%;
  vertical-align:top;
  padding-top:7px;
  padding-bottom:7px;
}

.kontraSpan {
  line-height:0.5;
  font-size:18px;
}

.kontra_text {
  font-size: 13px;
}

.doak_name {
  font-size: 16px;
  font-weight: bold;
}
.doak_wirkstoff {
  font-size: 12px;
}

.gabe {
  font-size:12px;
}

.fachinfo_link {
 font-size: 13px;
}

.modalDosierungTd6 {
  width:70%;
  vertical-align:top;
  padding-top:7px;
  padding-bottom:7px;
}

.modalDosierungTd7 {
  width:30%;
  vertical-align:top;
  padding-top:7px;
  padding-bottom:7px;
}

#modalGroesse {
  z-index: 1005;
  display: block;
  opacity: 1;
  top: 10%;
  transform: scaleX(1) scaleY(1);
  max-width: 500px;
}

.fachinfoLinkDesktop {
  cursor: pointer;
}

.fachinfoDiv {
  width:100%;
  margin-right:0px;
  margin-left:0px;
  margin-bottom:0px;
}

.fachinfoNote {
  font-size:14px;
  font-weight: normal;
  margin-left: 0px;
  margin-right: 15px;
  text-align: justify;
}

.collapsible-body {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.dosierungsvor {
  font-size: calc(6px + 2vw) !important;
}
@media screen and (min-width: 320px) {
  .dosierungsvor {
    font-size: calc(6px + 2vw) !important;
  }
}
.dosierungsPopup {
  width: 107% !important;
}
.dosierungsvorschlaege_button_Xarelto {
    width:95%;
}

.dosierungsvorschlaege_button_Pradaxa {
    width:98%;
}
@media screen and (min-width: 320px) {
  .dosierungsPopup {
    width: 107% !important;
  }
  .dosierungsvorschlaege_button_Xarelto {
    width:100%;
  }
  .dosierungsvorschlaege_button_Pradaxa {
      width:100%;
  }
}

@media screen and (min-width: 373px) {
  .dosierungsvorschlaege_button_Xarelto {
    width:100%;
  }
  .dosierungsvorschlaege_button_Pradaxa {
    width:100%;
  }
  .kontra_text {
    font-size: 13px;
  }

  .gabe {
    font-size:13px;
  }

  .doak_name {
    font-size: 17px;
    font-weight: bold;
  }
  .doak_wirkstoff {
    font-size: 12px;
  }
}

@media screen and (min-width: 600px) {
  .dosierungsPopup {
     width: 102.5% !important;
  }
  .dosierungsvor {
    font-size: 16px !important;
  }
  .kontra_text {
    font-size: 16px;
  }

  .fachinfo_link {
    font-size: 15px;
    }

  .doak_name {
    font-size: 21px;
    font-weight: bold;
  }
  .doak_wirkstoff {
    font-size: 16px;
  }
  .gabe {
    font-size:15px;
  }

  .fachinfoDesktop {
      height: 38px;
  }
  .fachinfoDesktop2 {
      height: 45px;
  }
  .fachinfoNote {
    font-size:16px;
    font-weight: normal;
  }
}

@media screen and (min-width: 712px) {

  .doakName {
    display: none;
  }
}

@media screen and (min-width: 1000px) {
  .niereWaehlen {
    position:absolute;
    display: block;
  }
}

</style>
