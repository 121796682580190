<template>
  <div id="hauptdiv"><br>
  <a href="javascript:history.back()" class="btn blue darken-3 backKnopf">Zurück</a>
  <br><br>
  <div class="blue-text text-darken-3" id="uberschrift">Impressum</div>
  <div class="leftZehn">
    <br>
    <img src="/img/bayoocare-logo.png" id="logoBayoocare" />
    <br>BAYOOCARE GmbH
    <br>Europaplatz 5
    <br>64293 Darmstadt
    <br><br>Telefon: +49 (0) 6151 – 86 18 – 0
    <br>Fax: +49 (0) 6151 – 86 18 – 150
    <br><br>
    Kontakt: <a href="mailto:info@bayoocare.com">info@bayoocare.com</a>
    <br>Support: <a href="mailto:easyDOAC-Support@bayoocare.com">easyDOAC-Support@bayoocare.com</a>
    <br><br>Handelsregister Frankfurt
    <br>Nr. HRB 82596
    <br><br>
    USt-ID: DE253462525<br>
    <table>
      <tr class="trBorderNone">
        <td class="CEImpressum">
          <img class="CELabel" src="/img/ce-schwarz.png">
        </td>
        <td>
          <b class="blue-text text-darken-3"><i>easyDOAC</i></b> ist als Medizinprodukt der Klasse I angemeldet
        </td>
      </tr>
    </table>
    <br>
    <img src="/img/Daiichi.jpg" id="logoDaiichi" /><br>
    <span class="noBreak">Mit freundlicher Unterstützung</span> <span class="noBreak">der Daiichi Sankyo Deutschland GmbH</span> <span class="noBreak">(Unterstützung bei Vermarktung, Usability </span> & Design sowie Finanzierung)
    <br>
    <br>
    <br>
  </div>
  <div class="blue-text text-darken-3" id="uberschrift">Allgemeinen Geschäftsbedingungen</div>
    <div class="leftZehn"><br>Letzte Aktualisierung: Februar 2021<br><br>
      <b class="blue-text text-darken-3">NUTZUNGSBESTIMMUNGEN DER <i>easyDOAC</i> App</b><br><br>
      <b class="blue-text text-darken-3">1. GELTUNGSBEREICH</b><br><br>
      1.1. Die folgenden Allgemeinen Geschäftsbedingen in ihrer jeweils gültigen Fassung regeln zusammen mit der Einwilligungserklärung Datenschutz (“Datenschutzeinwilligung”) gegenüber Verbrauchern (§ 13 BGB) die Nutzung der von BAYOOCARE (BAYOOCARE GmbH, Europaplatz 5; 64293 Darmstadt Deutschland, “BAYOOCARE”, „wir“ oder „uns“) entwickelten App (nachfolgend <b class="blue-text text-darken-3"><i>easyDOAC</i></b>) und die Inanspruchnahme der von uns angebotenen Dienstleistungen.
      <br><br>1.2. Diese Allgemeinen Geschäftsbedingen gelten ausschließlich.
      <br><br><b class="blue-text text-darken-3">2. VERTRAGSSCHLUSS</b>
      <br><br>2.1. Mit Zustimmung zu den Allgemeinen Geschäftsbedingungen kommt zwischen BAYOOCARE und dem Nutzer ein kostenfreies Vertragsverhältnis zustande, in das diese Allgemeinen Geschäftsbedingungen einbezogen sind.
      <br><br>2.2. Wir gewähren dem Nutzer, vorbehaltlich der hierin dargelegten Bedingungen, eine eingeschränkte, nicht ausschließliche, widerrufbare Lizenz zur Nutzung. Die App wird lizenziert, nicht verkauft.
      <br><br>2.3. Die Einwilligungserklärung Datenschutz ist nicht Bestandteil dieser Allgemeinen Geschäftsbedingungen. Zur Nutzung der App muss der Nutzer der Verarbeitung dessen Daten gemäß der angezeigten Einwilligungserklärung Datenschutz gesondert zustimmen. Darin erklären wir, welche personenbezogenen Daten durch uns oder ggf. darin benannten Dritten gesammelt, verarbeitet oder durch Sie mittels der App bereitgestellt werden. Nach der Zustimmung zu den Allgemeinen Geschäftsbedingungen hat der Nutzer die Möglichkeit kostenfreie Leistungen in Anspruch zu nehmen. Auf diese kostenfreien Leistungen finden diese Allgemeinen Geschäftsbedingen ebenfalls ausschließlich Anwendung.
      <br><br>2.4. Verwendet der Nutzer die App über ein mobiles Netzwerk, können dem Nutzer dabei zusätzliche Kosten entstehen, wie etwa Verbindungsentgelte und Roaminggebühren. Ob und welche Kosten anfallen, richtet sich nach den Vertragsbedingungen des Mobilfunkbetreibers. Die Entstehung dieser Kosten liegt nicht im Einflussbereich der BAYOOCARE. Der Nutzer ist verantwortlich für diese zusätzlichen Kosten.
      <br><br>2.5. Die App kann Inhalte von Dritten oder Links zu Dritten oder Fremdleistungen (zusammen „Dienste Dritter“ oder „Drittanbieter“) enthalten oder auf diese verlinken. Dienste Dritter unterliegen nicht der Kontrolle der BAYOOCARE. BAYOOCARE ist nicht verantwortlich für derartige Inhalte, einschließlich der Datenschutzrichtlinien oder Praktiken der Betreiber dieser Fremdleistungen. Nutzer sollten die Nutzungsbedingungen und Datenschutzrichtlinien dieser Drittanbieter aufmerksam lesen.
      <br><br>2.6. Technische Voraussetzungen: Die App ist für die Verwendung auf einem von der BAYOOCARE für diesen Zweck getesteten und freigegebenen Endgeräte vorgesehen. <b class="blue-text text-darken-3"><i>easyDOAC</i></b> benötigt ein freigegebenes Smartphone, Tablet oder PC mit einem Browser. Die App verfügt über einen Mechanismus, der die Gebrauchstauglichkeit des verwendeten Smartphones in Teilen sicherstellt. Im Falle eines nicht kompatiblen Smartphones oder Betriebssystems, ist die Verwendung der App nicht möglich.
      <br><br><b class="blue-text text-darken-3">3. HINWEISE</b>
      <br><br><b class="blue-text text-darken-3"><i>easyDOAC</i></b> bietet keine direkte Diagnose, ersetzt nicht die ärztliche Therapieentscheidung und richtet sich ausdrücklich nicht an Angehörige von Pflegeberufen, Patienten und deren Angehörige.
      <br><br><b class="blue-text text-darken-3">4. VERTRAGSGEGENSTAND</b>
      <br><br>4.1. Leistungen
      <br><br><b class="blue-text text-darken-3"><i>easyDOAC</i></b> bietet als digitale Anwendung eine Unterstützung durch Darstellung grundlegender pharmakologischer Informationen zur Auswahl, Verschreibung und Anwendung von Arzneimitteln. Diese Unterstützung basiert auf Fachinformationen und peer-reviewten Quellen (validierte Informationen), die vom Arzt oder Apotheker durch Eingabe patientenspezifischer Charakteristika gefiltert werden kann. Diese Filterung basiert auf einem regelbasierten Zugriff auf eine strukturierte Datenbasis. <b class="blue-text text-darken-3"><i>easyDOAC</i></b> bietet darüber hinaus Zugriff auf die Schätzfunktion der Nierenfunktion nach Cockcroft-Gault, eine Schätzung des individuellen Schlaganfallrisikos bei Vorhofflimmern mittels CHA<sub>2</sub>DS<sub>2</sub>-VASc-Score und die Schätzung des individuellen Blutungsrisikos bei Vorhofflimmern mittels HAS-BLED-Score.
      <br><br><b class="blue-text text-darken-3">5. NUTZUNG</b>
      <br><br>5.1. Nutzung der kostenfreien Leistung
      <br><br>a) Die Nutzung der App <b class="blue-text text-darken-3"><i>easyDOAC</i></b> sowie deren Funktionen ist kostenfrei.
      <br><br>b) <b class="blue-text text-darken-3"><i>easyDOAC</i></b> ist als Internetseite frei verfügbar. Eine Freischaltung durch Bestätigung der AGB, des Datenschutzes und der Nutzungseinschränkung für Ärzte und Apotheker ist erforderlich.
      <br><br>5.2. Die App <b class="blue-text text-darken-3"><i>easyDOAC</i></b> ist ein validiertes Medizinprodukt und erfüllt die grundlegenden Anforderungen der Richtlinie 93/42/EWG bzw. ihrer nationalen Umsetzungen.
      <br><br><b class="blue-text text-darken-3">6. WIDERRUF / KÜNDIGUNG</b>
      <br><br>6.1. Widerrufsbelehrung
      <br><br>a) Widerrufsrecht:
      <br><br>Sie haben das Recht jederzeit, ohne Angabe von Gründen, diesen Vertrag zu widerrufen. Um ihr Widerrufsrecht auszuüben müssen sie lediglich die App von ihrem Endgerät löschen .
      <br><br><b class="blue-text text-darken-3">7. UMFANG DER VON <b class="blue-text text-darken-3"><i>easyDOAC</i></b> ZU ERBRINGENDEN LEISTUNGEN</b>
      <br><br>7.1. <b class="blue-text text-darken-3"><i>easyDOAC</i></b> ist nicht verpflichtet, die Richtigkeit, Vollständigkeit und Aktualität der vom Nutzer mitgeteilten Daten zu prüfen oder zu verifizieren. <b class="blue-text text-darken-3"><i>easyDOAC</i></b> darf für die Erbringung von Leistungen unterstellen, dass die vom Nutzer mitgeteilten Daten richtig, vollständig und aktuell sind. Sollten die vom Nutzer mitgeteilten Daten unrichtig, unvollständig und nicht aktuell sein, nimmt der Nutzer automatisch in Kauf, dass die von <b class="blue-text text-darken-3"><i>easyDOAC</i></b> ermittelten und dargestellten Informationen ggf. unzutreffend sein könnten.
      Die angebotenen Schätzfunktionen sind nur begrenzt aussagefähig, benötigen eine ärztliche Interpretation und erheben keinen Anspruch auf Vollständigkeit oder Richtigkeit. <b class="blue-text text-darken-3"><i>easyDOAC</i></b> übernimmt daher keine Gewähr oder Garantie für die Richtigkeit und/oder Vollständigkeit der Informationen, insbesondere nicht in medizinischer oder klinischer Hinsicht.
      <br><br>7.2. Die von <b class="blue-text text-darken-3"><i>easyDOAC</i></b> ermittelten Ergebnisse dienen ausschließlich zu Informationszwecken und stellen keine Diagnose dar.
      Sie geben weder Auskunft über den tatsächlichen Gesundheitszustand des Patienten dessen Daten eingegeben wurden, noch Rat oder Empfehlung zur Behandlung von Krankheiten oder Gebrechen. Solche Auskünfte, Ratschläge oder Empfehlungen kann nur ein Arzt oder sonstiges medizinisch oder therapeutisch geschultes Personal erteilen.
      <b class="blue-text text-darken-3"><i>easyDOAC</i></b> ist es unmöglich, eine ärztliche oder sonstige fachmedizinische oder gesundheitsmäßige Untersuchung vorzunehmen und schuldet diese daher nicht. Die jeweils ermittelten und dargestellten Ergebnisse können die sorgfältige Anamnese, Untersuchung, Diagnose und Therapievorschläge durch einen Arzt nicht ersetzen, ebenso wenig wie Beratung, Betreuung oder Behandlung durch ärztliches oder sonst medizinisch geschultes Fachpersonal, durch das ausschließlich die Erstellung von Diagnosen, die Empfehlung von Behandlungstherapien oder der Einsatz von Medikamenten erfolgt.
      <br><br>7.3. <b class="blue-text text-darken-3"><i>easyDOAC</i></b> prüft mit größtmöglicher Sorgfalt und unter Bezugnahme auf die erkennbaren aktuellen medizinischen Entwicklungen Angaben bei der Leistungserbringung. <b class="blue-text text-darken-3"><i>easyDOAC</i></b> schuldet nicht die Richtigkeit, Vollständigkeit, Aktualität oder Brauchbarkeit dieser Angaben.
      <br><br>7.4. Die Verwendung der Funktionen obliegt dem Nutzer. <b class="blue-text text-darken-3"><i>easyDOAC</i></b> und seine Organe, leitenden Angestellten, Mitarbeiter, Erfüllungsgehilfen, Partner, angeschlossenen Unternehmen und Lizenzgeber  übernehmen keinerlei Verantwortung für Entscheidungen, die Sie gestützt auf den ermittelten Ergebnissen treffen. Sie (der Nutzer) verzichten hiermit auf alle Ansprüche, die Sie gegen <b class="blue-text text-darken-3"><i>easyDOAC</i></b> und seine Organe, leitenden Angestellten, Mitarbeiter, Erfüllungsgehilfen, Partner, angeschlossenen Unternehmen und Lizenzgeber haben oder in Zukunft erwerben und erklären, die Genannten hinsichtlich aller sich im Zusammenhang mit Ihrer Verwendung der Informationen stehenden Ansprüche, Verfahren, Kosten, mittelbaren oder indirekten Schäden schadlos zu halten. Dies gilt auch für entgangenen Gewinn.
      <br><br>7.5. Zwischen <b class="blue-text text-darken-3"><i>easyDOAC</i></b> und dem Nutzer besteht kein Geschäftsbesorgungs- oder ein Auftragsverhältnis.
      <br><br>7.6. <b class="blue-text text-darken-3"><i>easyDOAC</i></b> macht keinerlei Zusagen und gibt keine Garantie, dass die App sicher, unterbrechungs- und fehlerfrei in Betrieb ist. <b class="blue-text text-darken-3"><i>easyDOAC</i></b> übernimmt keine Verpflichtung hinsichtlich der Funktionstauglichkeit oder Funktionsfähigkeit des vom Nutzer verwendeten Smartphones oder sonstiger Hardware.
      <br><br><b class="blue-text text-darken-3">8. PFLICHTEN DES NUTZERS</b>
      <br><br>Der Nutzer ist verantwortlich, die erforderlichen Rahmenbedingungen für den Zugang zur App zu schaffen, was insbesondere das Herstellen einer Internetverbindung für regelmäßige Aktualisierungen umfasst.
      <br><br><b class="blue-text text-darken-3">9. BESCHRÄNKUNGEN DER NUTZUNG, ZUGRIFFSVERBOTE</b>
      <br><br>9.1. Folgende Nutzungs- und Zugriffsarten sind untersagt:
      <br><br>a) Die Nutzung der App für rechtswidrige, insbesondere gesetzes- oder vertragswidrige Zwecke oder
      deren Förderung
      <br><br>b) Der Zugriff auf, die Manipulation von oder die Nutzung von nicht-öffentlichen Bereichen der App, von Computersystemen von <b class="blue-text text-darken-3"><i>easyDOAC</i></b> oder technischen Übertragungssystemen Dritter
      <br><br>c) Die Ausforschung, das Scannen oder die Prüfung von Schwachstellen der App oder die Verletzung oder Umgehung von Sicherheits- und Authentifizierungsmaßnahmen
      <br><br>d) Alle automatischen oder manuellen Versuche, auf die App oder Informationen und Daten zuzugreifen oder die App unter Verwendung von anderen als den aktuell verfügbaren, öffentlichen Schnittstellen von <b class="blue-text text-darken-3"><i>easyDOAC</i></b> zu durchsuchen
      <br><br>e) Jede Störung der oder Unterbrechung des Zugangs zur App durch andere Nutzer sowie entsprechende Versuche
      <br><br>f) Die Nutzung, Erhebung, Ausspähung oder Weitergabe von Daten oder Informationen Dritter ohne deren ausdrückliche Genehmigung, die in jedem Fall gegenüber BAYOOCARE nachzuweisen ist
      <br><br>g) Jede Veränderung, Verlinkung, Deep-Verlinkung, Speicherung, Reproduktion, Duplikation, Kopie, Verkauf oder Handeltreiben mit dem Inhalt oder der grafischen Gestaltung der App oder der auf ihr enthaltenen Informationen
      <br><br>9.2. Des Weiteren ist es dem Nutzer verboten,
      <br><br>a) rassistische, beleidigende, diskriminierende, verleumderische, sexuell explizite, gewaltverherrlichende oder sonstige rechtswidrige Inhalte zu speichern, zu veröffentlichen, zu übermitteln und zu verbreiten
      <br><br>b) störenden Eingriffe mit technischen oder elektronischen Hilfsmitteln in das <b class="blue-text text-darken-3"><i>easyDOAC</i></b>-System durchzuführen oder zu versuchen, insbesondere durch Hacking, Brute-Force, Viren, Würmern oder Trojaner
      <br><br>c) zugängliche Daten ohne ausdrückliche Zustimmung des jeweiligen Rechtsinhabers zu lesen, zu kopieren, zu verbreiten und zu übermitteln oder diese mit technischen Hilfsmitteln (Crawler, Bots) auszulesen
      <br><br>d) Kettenbriefe oder Nachrichten, insbesondere Massenmails oder Spam, an eine- oder mehrere Personen gleichzeitig zu versenden
      <br><br>9.3. <b class="blue-text text-darken-3"><i>easyDOAC</i></b> bietet keine direkte Diagnose, ersetzt nicht die ärztliche Therapieentscheidung und richtet sich ausdrücklich nicht an Angehörige von Pflegeberufen, Patienten und deren Angehörige.
      <br><br>9.4. <b class="blue-text text-darken-3"><i>easyDOAC</i></b> ist im Fall der Verletzung einer der hier genannten Pflichten oder von entsprechenden Versuchen berechtigt, jederzeit Informationen oder sonstige Daten zurückzuhalten oder zu entfernen sowie Leistungen aus wichtigem Grund zu kündigen.
      <br><br><b class="blue-text text-darken-3">10. ÄNDERUNGEN DER APP, INHALTE</b>
      <br><br>10.1. <b class="blue-text text-darken-3"><i>easyDOAC</i></b> behält sich vor, den Inhalt, die grafische Gestaltung oder den Aufbau der App zu ändern. <b class="blue-text text-darken-3"><i>easyDOAC</i></b> behält sich des Weiteren vor, den Zugang zur App, den Betrieb oder deren Funktionen zu diesem Zweck oder aus sonstigen Gründen jederzeit vorübergehend vollständig oder teilweise auszusetzen.
      <br><br>10.2. <b class="blue-text text-darken-3"><i>easyDOAC</i></b> ist für eigene Inhalte in der App nach den allgemeinen Gesetzen verantwortlich.
      <br><br><b class="blue-text text-darken-3"><i>easyDOAC</i></b> ist nicht verpflichtet, übermittelte, verlinkte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung besteht jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung. Werden <b class="blue-text text-darken-3"><i>easyDOAC</i></b> Rechtsverletzungen durch Inhalte bekannt, wird <b class="blue-text text-darken-3"><i>easyDOAC</i></b> diese Inhalte umgehend entfernen.
      <br><br><b class="blue-text text-darken-3">11. ÄNDERUNGEN DIESER BESTIMMUNGEN</b>
      <br><br>11.1. <b class="blue-text text-darken-3"><i>easyDOAC</i></b> behält sich vor diese Allgemeinen Geschäftsbedingungen von Zeit zu Zeit ändern, etwa um gesetzliche Änderungen umzusetzen oder neue Funktionen, Dienstleistungen oder Apps einzuführen.
      <br><br>Solche hierauf bezogenen Änderungen werden wirksam ab dem Zeitpunkt, an dem Nutzer eine Mitteilung darüber gesendet wird, bspw. wenn beim Starten der App mit einer Mitteilung darauf hingewiesen und der Nutzer gebeten wird diese Änderungen zu bestätigen bevor <b class="blue-text text-darken-3"><i>easyDOAC</i></b> weitergenutzt werden kann.
      <br><br>11.2. Sollten Änderungen den Nutzer objektiv schlechter stellen, werden wir diese Änderungen 30 Tage im Voraus ankündigen. Sollte der Nutzer mit einer der Änderungen nicht einverstanden sein, muss dieser mit uns Kontakt aufnehmen, bevor die Änderungen in Kraft treten. Ansonsten werden die Änderungen zum Zeitpunkt des Wirksamwerdens Vertragsbestandteil.
      <br><br>11.3. Wir werden keine Kopien dieser Allgemeinen Geschäftsbedingungen über Ihre Nutzung von <b class="blue-text text-darken-3"><i>easyDOAC</i></b> archivieren. Die aktuell gültige Fassung wird stets über die App verfügbar sein.
      <br><br><b class="blue-text text-darken-3">12. RECHTE AN DER APP SOWIE MARKEN UND NAMEN</b>
      <br><br>12.1. Alle Rechte an der App, insbesondere Urheber-, Marken-, Patent- sowie alle sonstigen Immaterialgüterrechte, sowie alle Rechte an dem Namen <b class="blue-text text-darken-3"><i>easyDOAC</i></b> oder verwechslungsfähiger Derivative, an Kennzeichen, an Domains sowie alle Informationen (mit Ausnahme der vom Nutzer eingegebenen Einzeldaten) stehen und verbleiben stets ausschließlich BAYOOCARE bzw. seinen Lizenzgebern.
      <br><br>12.2. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung von Inhalten oder Bestandteilen der App bedürfen der schriftlichen Zustimmung von BAYOOCARE. Downloads und Kopien der App für den kommerziellen Gebrauch sind nicht gestattet.
      <br><br>12.3. Soweit BAYOOCARE die Inhalte der App nicht erstellt hat, beachtet BAYOOCARE die Urheberrechte Dritter. Inhalte Dritter sind als solche gekennzeichnet. Sollte der Nutzer trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bittet BAYOOCARE um einen entsprechenden Hinweis.
      <br><br>Bei Bekanntwerden von Rechtsverletzungen wird BAYOOCARE derartige Inhalte umgehend entfernen.
      <br><br><b class="blue-text text-darken-3">13. HAFTUNG UND VERANTWORTLICHKEIT VON BAYOOCARE</b>
      <br><br>13.1. BAYOOCARE haftet für Vorsatz und grobe Fahrlässigkeit unbeschränkt. Bei leichter Fahrlässigkeit haftet BAYOOCARE nur und begrenzt auf den vertragstypischen, vorhersehbaren Schaden, soweit BAYOOCARE eine Pflicht verletzt, die für die Erreichung des Vertragszwecks von wesentlicher Bedeutung ist. Unabhängig vom Anspruchsgrund, übernimmt BAYOOCARE keine darüber hinaus gehende Haftung. Die vorstehenden Haftungsbeschränkungen und -ausschlüsse gelten nicht für Ansprüche wegen Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit oder für Ansprüche nach dem Produkthaftungsgesetz. Soweit die Haftung von BAYOOCARE begrenzt oder ausgeschlossen ist, ist die persönliche Haftung der Erfüllungs- und Verrichtungsgehilfen von BAYOOCARE ebenso begrenzt bzw. ausgeschlossen. Die vorstehenden Haftungsbeschränkungen gelten auch gegenüber Dritten, die in den Schutzbereich des Vertrags einbezogen sind.
      <br><br>13.2. BAYOOCARE ist nicht verantwortlich oder haftbar für Schäden am Computersystem des Nutzers, den Verlust von Daten oder anderen Nachteilen, die sich aus dem Zugang zur App oder deren jeweiliger Nutzung ergeben. Dies gilt auch für die Zerstörung oder eine unterbliebene Speicherung oder Übertragung von Daten, Informationen und anderen Mitteilungen.
      <br><br>13.3. <b class="blue-text text-darken-3"><i>easyDOAC</i></b> verwendet die Fachinformationen der dargestellten Arzneimittel im aktuell zur Verfügung stehenden Stand als Datenbasis für die Funktionen der App. Die für das jeweilige Arzneimittel verwendete Version der Fachinformation ist mit ihrem Stand in der App benannt. BAYOOCARE übernimmt keine Haftung durch die verkürzte Darstellung der Fachinformationen und weist den Nutzer ausdrücklich auf seine Pflichten hin.
      <br><br><b class="blue-text text-darken-3">14. ALLGEMEINE HINWEIS ZUM DATENSCHUTZ</b>
      <br><br>14.1. BAYOOCARE ist das Thema Datenschutz im Interesse der Nutzer ein wichtiges Anliegen. Der Nutzer hat die Möglichkeit, in der Datenschutzerklärung von <b class="blue-text text-darken-3"><i>easyDOAC</i></b> weitere Informationen zum Thema Datenschutz nachzulesen. Die Verarbeitung der vom Nutzer zur Verfügung gestellten Daten sowie die Absicherung der Daten erfolgt gemäß der Datenschutzerklärung von <b class="blue-text text-darken-3"><i>easyDOAC</i></b> im Einklang mit den geltenden datenschutzgesetzlichen Bestimmungen.
      <br><br>14.2. Bei <b class="blue-text text-darken-3"><i>easyDOAC</i></b> findet kein Verkauf, Tausch oder sonstiger nicht-autorisierter Gebrauch von persönlichen Daten und Informationen statt. <b class="blue-text text-darken-3"><i>easyDOAC</i></b> gibt keine personenbezogenen Daten des Nutzers an Dritte weiter, es sei denn, der Nutzer hat seine Einwilligung hierzu erteilt oder es besteht für <b class="blue-text text-darken-3"><i>easyDOAC</i></b> eine gesetzliche Verpflichtung zur Herausgabe der Daten.
      <br><br><b class="blue-text text-darken-3">15. KOMMUNIKATION MIT UNS / INFORMATIONSPFLICHTEN</b>
      <br><br>Für Fragen wenden Sie sich einfach an unseren Kundenservice. Senden Sie uns eine E-Mail an <a href="mailto:terms@bayoocare.com">terms@bayoocare.com</a>
      <br><br>Oder Sie schreiben uns einfach an:
      <br><br>BAYOOCARE GmbH
      <br>Europaplatz 5
      <br>64293 Darmstadt
      <br><br>BAYOOCARE hat sich keinem außergerichtlichen Beschwerde- oder Rechtsbehelfsverfahren unterworfen.
      <br><br>Der Nutzer kann den Vertragstext jederzeit kopieren und abspeichern. Wir selbst speichern die Vertragstexte und machen sie dem Nutzer auf Wunsch per E-Mail zugänglich.
      <br><br>BAYOOCARE hat sich keinem besonderen Verhaltenskodex (Regelwerk) unterworfen.
      <br><br>Seit dem 09.01.2016 ist die Verordnung (EU) Nr. 524/2013 (ODR-Verordnung) in Kraft über die OnlineStreitbeilegung in Verbraucherangelegenheiten. Sie gilt für die außergerichtliche Streitbeilegung von Streitigkeiten über vertragliche Verpflichtungen unter Anderem aus Online-Dienstleistungsverträgen zwischen Verbrauchern und Online-Unternehmen und bezweckt die Erreichung eines hohen Verbraucherschutzniveaus im europäischen Binnenmarkt. Mit der Möglichkeit einer Online-Streitbeilegung (OS) soll eine einfache, effiziente, schnelle und kostengünstige außergerichtliche Lösung für Streitigkeiten angeboten werden. Die OS-Plattform leitet ordnungsgemäß gestellte Beschwerden an die (nach nationalem Recht) zuständigen AS-Stellen (Außergerichtliche Streitbeilegung) weiter. Die Nutzung der OS-Plattform selbst ist kostenlos, in Verfahren vor den AS-Stellen können dem Verbraucher ggf. Kosten (bis zu 30,00 EUR) entstehen, falls sein Antrag rechtsmissbräuchlich ist.
      <br><br><b class="blue-text text-darken-3">16. SCHLUSSBESTIMMUNGEN</b>
      <br><br>16.1. BAYOOCARE ist berechtigt, ihre vertraglichen Rechte und Pflichten einem Dritten zu übertragen. Eine Abtretung der vertraglichen Rechte des Nutzers ist ausgeschlossen. Die vertraglichen Rechte und Pflichten aus dem geschlossenen Nutzungsvertrag des Nutzers können nur dann einem Dritten übertragen werden, wenn BAYOOCARE dem schriftlich zugestimmt hat.
      <br><br>16.2. Sollten einzelne der vorstehenden Bedingungen unwirksam sein oder werden, so berührt dies nicht die Wirksamkeit der übrigen Bestimmungen. Anstelle der unwirksamen Bedingungen sollen solche Regelungen treten, die dem wirtschaftlichen Zweck des Vertrages unter angemessener Wahrung der beiderseitigen Interessen am Nächsten kommen.
      <br><br>16.4. Gerichtsstand für alle Streitigkeiten aus diesem Vertrag und seine Wirksamkeit ist der Sitz von BAYOOCARE.
      <br><br>16.5. Dieser Vertrag unterliegt dem Recht der Bundesrepublik Deutschland unter Ausschluss des UNKaufrechts und des Deutschen Internationalen Privatrechts.<br><br>
    </div>
    <a href="javascript:history.back()" class="btn blue darken-3 backKnopf">Zurück</a><br><br><br>
  </div>
</template>

<script>
export default {
  name: 'Impressum'
}
</script>

<style scoped>
#hauptdiv {
  width:97.5%;
  margin:auto;
}

.CELabel {
  width: 40px;
  margin-left: -3px;
}

.leftZehn {
  width:97.5%;
  margin:auto;
}

.CEImpressum {
  width: 40px;
  padding-top:20px;
  padding-left:0px;
}

#uberschrift {
  font-size:21px;
  margin-left:10px;
  margin-bottom:5px;
}

#logoBayoocare {
  max-width:300px;
  max-height:100px;
  margin-bottom:5px;
}

.backKnopf {
  margin-left: 10px;
}

#logoDaiichi {
  max-width:200px;
  max-height:100px;
  margin-bottom:5px;
  margin-top:-20px;
}
.noBreak {
  white-space: nowrap;
}
</style>
