<template>
  <div class="home container sperre">
    <h4 class="blue-text text-darken-3">{{ sperreHeader }}</h4>
    <span v-html="sperreText"></span>
  </div>
</template>

<script>
// Gewährleistet die Funktionalität von Materialize in dieser Vue-Datei
import M from 'materialize-css/dist/js/materialize.min'

export default {
  name: 'Sperre',
  props: ['bestaetigungArzt', 'phrasen'],
  data: function () {
    return {
      sperreHeader: this.phrasen.app_blocked_header,
      sperreText: this.phrasen.app_blocked
    }
  },
  mounted () {
    M.AutoInit() // Gewährleistet die Funktionalität von Materialize in dieser Vue-Datei
    if (this.bestaetigungArzt === false) {
      this.$router.push({ name: 'Disclaimer' })
    }
    if (localStorage.letzterCheckSperreText != null) {
      this.sperreText = localStorage.letzterCheckSperreText
    }
  }
}
</script>

<style scoped>
 .sperre {
     padding: 20px;
 }
</style>
