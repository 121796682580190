<template>
    <div>
      <div class="blue lighten-5 white-text kosten modal-trigger" href="#kostenPopup">
          <table cellspacing="0" cellpadding="0" class="dosierungsTable">
              <tr class="trBorderNone">
                <td class="noPadding3">
                  <div class="left">
                    <img src="/img/geld.gif" class="GeldWidth">
                  </div>
                  <div class="divGBA">
                    <center>
                      <img src="/img/gba.png" class="GBAWidth"><br><div class="GBADiv blue-text text-darken-3">{{ phrasen.gba }}</div>
                    </center>
                  </div>
                </td>
                <td class="noPadding2">
                  <div class="kostenFont decLineHeight blue-text text-darken-3">{{ phrasen.therapy_costs }}</div>
                </td>
                <td class="noPadding">
                  <img src="/img/pfeil_blau.png" class="kostenPfeil">
                </td>
              </tr>
            </table>
        </div>
        <div id="kostenPopup" class="modal">
            <div class="modal-content padding5">
            <div class="modalContentDosierungHeader">
                <table>
                <tr class="trBorderNone">
                    <td class="geldPopupPadding">
                        <img src="/img/geld.gif" class="GeldWidth">
                    </td>
                    <td class="noPadding">
                        <span class="modalDosierungSpan decLineHeight">{{ phrasen.therapy_costs }}</span>
                    </td>
                    <td class="closeModalTd">
                    <img href="#!" class="modal-close right widthClose" src="/img/close.gif">
                    </td>
                </tr>
                </table>
            </div>
            <div class="kostenPopupContent">
                <table>
                    <tr class="trBorderNone">
                        <td class="kostenTd1">
                            <div class="blue lighten-5 blue-text text-darken-3 boxHellblauKosten">
                                <div class="kostenName"><b>{{ kosten.d1.name }}</b> ({{ kosten.d1.wirkstoff }})</div>
                                <center class="kostenKosten">{{ kosten.d1.tag }} € / {{ phrasen.day }}<br>{{ kosten.d1.jahr }} € / {{ phrasen.year }}</center>
                                <table>
                                    <tr class="trBorderNone">
                                        <td class="noPadding">
                                            <div v-if="kosten.d1.gba != null" class="GBAOverDiv">
                                                <a :href="kosten.d1.gba" target="_blank" class="blue-text text-darken-3"><img src="/img/gba.png" class="GBAWidth2"><br><div class="GBADiv2">{{ phrasen.gba }}</div></a>
                                            </div>
                                        </td>
                                        <td class="noPadding">
                                            <div v-if="kosten.d1.gba != null" class="zusatzNutzen">
                                                <div class="right">
                                                    {{ phrasen.kosten_1 }}
                                                </div><br>
                                                <div class="right">
                                                   {{ phrasen.kosten_2 }}
                                                </div>
                                            </div>
                                            <div v-if="kosten.d1.gba == null" class="zusatzNutzen">
                                                <div class="right">
                                                    {{ phrasen.kosten_3 }}
                                                </div><br>
                                                <div class="right">
                                                    ({{ phrasen.kosten_4 }})
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </td>
                        <td class="kostenTd2">
                            <div class="blue lighten-5 blue-text text-darken-3 boxHellblauKosten">
                                <div class="kostenName"><b>{{ kosten.d2.name }}</b> ({{ kosten.d2.wirkstoff }})</div>
                                <center class="kostenKosten">{{ kosten.d2.tag }} € / {{ phrasen.day }}<br>{{ kosten.d2.jahr }} € / {{ phrasen.year }}</center>
                                <table>
                                    <tr class="trBorderNone">
                                        <td class="noPadding">
                                            <div v-if="kosten.d2.gba != null" class="GBAOverDiv">
                                                <a :href="kosten.d2.gba" target="_blank" class="blue-text text-darken-3"><img src="/img/gba.png" class="GBAWidth2"><br><div class="GBADiv2">{{ phrasen.gba }}</div></a>
                                            </div>
                                        </td>
                                        <td class="noPadding">
                                            <div v-if="kosten.d2.gba != null" class="zusatzNutzen">
                                                <div class="right">
                                                    {{ phrasen.kosten_1 }}
                                                </div><br>
                                                <div class="right">
                                                    {{ phrasen.kosten_2 }}
                                                </div>
                                            </div>
                                            <div v-if="kosten.d2.gba == null" class="zusatzNutzen">
                                                <div class="right">
                                                    {{ phrasen.kosten_3 }}
                                                </div><br>
                                                <div class="right">
                                                    ({{ phrasen.kosten_4 }})
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </td>
                    </tr>
                    <tr class="trBorderNone">
                        <td class="kostenTd1">
                            <div class="blue lighten-5 blue-text text-darken-3 boxHellblauKosten">
                                <div class="kostenName"><b>{{ kosten.d3.name }}</b> ({{ kosten.d3.wirkstoff }})</div>
                                <center class="kostenKosten">{{ kosten.d3.tag }} € / {{ phrasen.day }}<br>{{ kosten.d3.jahr }} € / {{ phrasen.year }}</center>
                                <table>
                                    <tr class="trBorderNone">
                                        <td class="noPadding">
                                            <div v-if="kosten.d3.gba != null" class="GBAOverDiv">
                                                <a :href="kosten.d3.gba" target="_blank" class="blue-text text-darken-3"><img src="/img/gba.png" class="GBAWidth2"><br><div class="GBADiv2">{{ phrasen.gba }}</div></a>
                                            </div>
                                        </td>
                                        <td class="noPadding">
                                            <div v-if="kosten.d3.gba != null" class="zusatzNutzen">
                                                <div class="right">
                                                    {{ phrasen.kosten_1 }}
                                                </div><br>
                                                <div class="right">
                                                    {{ phrasen.kosten_2 }}
                                                </div>
                                            </div>
                                            <div v-if="kosten.d3.gba == null" class="zusatzNutzen">
                                                <div class="right">
                                                    {{ phrasen.kosten_3 }}
                                                </div><br>
                                                <div class="right">
                                                    ({{ phrasen.kosten_4 }})
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </td>
                        <td class="kostenTd2">
                            <div class="blue lighten-5 blue-text text-darken-3 boxHellblauKosten">
                                <div class="kostenName"><b>{{ kosten.d4.name }}</b> ({{ kosten.d4.wirkstoff }})</div>
                                <center class="kostenKosten">{{ kosten.d4.tag }} € / {{ phrasen.day }}<br>{{ kosten.d4.jahr }} € / {{ phrasen.year }}</center>
                                <table>
                                    <tr class="trBorderNone">
                                        <td class="noPadding">
                                            <div v-if="kosten.d4.gba != null" class="GBAOverDiv">
                                                <a :href="kosten.d4.gba" target="_blank" class="blue-text text-darken-3"><img src="/img/gba.png" class="GBAWidth2"><br><div class="GBADiv2">{{ phrasen.gba }}</div></a>
                                            </div>
                                        </td>
                                        <td class="noPadding">
                                            <div v-if="kosten.d4.gba != null" class="zusatzNutzen">
                                                <div class="right">
                                                    {{ phrasen.kosten_1 }}
                                                </div><br>
                                                <div class="right">
                                                    {{ phrasen.kosten_2 }}
                                                </div>
                                            </div>
                                            <div v-if="kosten.d4.gba == null" class="zusatzNutzen">
                                                <div class="right">
                                                    {{ phrasen.kosten_3 }}
                                                </div><br>
                                                <div class="right">
                                                    ({{ phrasen.kosten_4 }})
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Kosten',
  props: ['phrasen', 'kosten']
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#kostenPopup {
    max-width: 650px;
}

.kosten {
  text-align:left;
  padding-left:3%;
  padding-top:12px;
  padding-bottom:8px;
  box-shadow: 3px 3px 3px silver;
  margin-left: 10px;
  margin-right: 9px;
  margin-top: -10px;
  cursor: pointer;
}

.divGBA {
  float:left;
  margin-left:4px;
}

.GBADiv {
    margin-top:-5px;
    font-weight:bold;
}

.GBADiv2 {
    margin-top:-5px;
    font-weight:bold;
    font-size: 11px;
}

.GBAOverDiv {
    text-align: center;
}

.kostenName {
    height: 50px;
}

.kostenKosten {
    height: 50px;
}

.zusatzNutzen {
    font-size: 12px;
    text-align: right;
}

.GBAWidth {
    width:35px;
}

.GBAWidth2 {
    width:30px;
}

.GeldWidth {
    width:42px;
}

.noPadding {
    padding: 0px;
}

.noPadding2 {
    padding: 0px;
    text-align: center;
}

.noPadding3 {
    padding: 0px;
    width: 85px; /* Breiten sind fix gesetzt für die Icons */
}

.decLineHeight {
    line-height: 1.1em; /* Zeilenabstand verkleinern, da meist mehrzeilig */
}

.kostenFont {
    font-size:18px;
    font-weight: bold;
    text-align: left; /* Linksausrichtung dieser Buttonsüberschrift */
    padding-left: 1em; /* Abstand zu Icons links */
    padding-right: 0.5em; /* Abstand zum Pfeil rechts */
}

.kostenPfeil {
    width:20px;
    float:right;
    margin-right:7px;
}

.geldPopupPadding {
    padding:0px;
    padding-right:5px;
}

.boxHellblauKosten {
    width:100%;
    padding:10px;
    box-shadow: 3px 3px 3px silver;
    height: 170px;
}

.kostenPopupContent {
    padding:0px;
    padding-top:5px;
}

.kostenTd1 {
    padding:0px;
    padding-right:3px;
    padding-bottom:3px;
}

.kostenTd2 {
    padding:0px;
    padding-left:3px;
    padding-bottom:3px;
}

@media screen and (min-width: 600px) {

    .noPadding3 {
        width: 115px;
    }
    .GBAWidth {
        width:45px;
    }

    .GeldWidth {
        width:52px;
    }

    .divGBA {
        float:left;
        margin-left:10px;
    }

    .GBADiv2 {
        font-size: 16px;
    }
    .GBAWidth2 {
        width: 52px;
    }

    .boxHellblauKosten {
        width:100%;
        padding:10px;
        box-shadow: 3px 3px 3px silver;
        height: 200px;
    }

    .kostenName{
        font-size: 20px;
    }

    .kostenKosten{
        font-size: 18px;
        height: 70px;
    }

    .zusatzNutzen {
        font-size: 16px;
        text-align: right;
    }

}

@media screen and (min-width: 1100px) {
    .kostenFont {
        font-size:18px;
    }

}

@media screen and (min-width: 1300px) {
    .kostenFont {
        font-size:18px;
    }

}

</style>
