<template>
  <div style="width:97.5%; margin:auto;"><br>
    <a href="javascript:history.back()" class="btn blue darken-3 backKnopf">Zurück</a>
    <br><br>
    <div class="blue-text text-darken-3" id="uberschrift">Kontakt</div>
  <div class="leftZehn">
    <br>
    <img src="/img/bayoocare-logo.png" id="logoBayoocare" />
    <br>BAYOOCARE GmbH
    <br>Europaplatz 5
    <br>64293 Darmstadt
    <br><br>Telefon: +49 (0) 6151 – 86 18 – 0
    <br>Fax: +49 (0) 6151 – 86 18 – 150
    <br><br>
    Kontakt: <a href="mailto:info@bayoocare.com"> info@bayoocare.com </a>
    <br>Support: <a href="mailto:easyDOAC-Support@bayoocare.com">easyDOAC-Support@bayoocare.com</a>
  </div>
  </div>
</template>

<script>
export default {
  name: 'Kontakt'
}
</script>

<style scoped>
#uberschrift {
  font-size:21px;
  margin-left:10px;
  margin-bottom:5px;
}

#logoBayoocare {
  max-width:300px;
  max-height:100px;
  margin-bottom:5px;
}

.leftZehn {
  width:97.5%;
  margin:auto;
}

.backKnopf {
  margin-left: 10px;
}

</style>
