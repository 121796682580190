<template>
  <div class="home container" style="">
    <div class="row" v-if="dataReady">
      <nav class="hide-on-med-and-up mobileFilter">
        <div class="nav-wrapper blue">
          <ul id="nav-mobile" class="">
            <li v-if="buttonStart[0].frageAktiv" class="filterNavAktiv" ><a href="#" class="filterNavA1Aktiv">{{ phrasen.indication }}</a></li>
            <li v-if="!buttonStart[0].frageAktiv" class="filterNav" v-on:click="frageTauschen(0)"><a href="#" class="filterNavA1">{{ phrasen.indication }}</a></li>
            <li v-if="buttonStart[1].frageAktiv" class="filterNavAktiv"><a href="#" class="filterNavAAktiv"> {{ phrasen.kidney }}</a></li>
            <li v-if="!buttonStart[1].frageAktiv" class="filterNav" v-on:click="frageTauschen(1)"><a href="#" class="filterNavA"> {{ phrasen.kidney }}</a></li>
            <li v-if="buttonStart[2].frageAktiv" class="filterNavAktiv"><a href="#" class="filterNavAAktiv"> {{ phrasen.age }}</a></li>
            <li v-if="!buttonStart[2].frageAktiv" class="filterNav" v-on:click="frageTauschen(2)"><a href="#" class="filterNavA"> {{ phrasen.age }}</a></li>
            <li v-if="buttonStart[3].frageAktiv" class="filterNavAktiv"><a href="#" class="filterNavAAktiv"> {{ phrasen.weight }}</a></li>
            <li v-if="!buttonStart[3].frageAktiv" class="filterNav" v-on:click="frageTauschen(3)"><a href="#" class="filterNavA"> {{ phrasen.weight }}</a></li>
            <li v-if="buttonStart[4].frageAktiv" class="filterNavAktiv"><a href="#" class="filterNavAAktiv">{{ phrasen.komed }}</a></li>
            <li v-if="!buttonStart[4].frageAktiv" class="filterNav" v-on:click="frageTauschen(4)"><a href="#" class="filterNavA">{{ phrasen.komed }}</a></li>
            <li class="right ergebnisNav" v-on:click="abschliessen"><a href="#" class="ergebnisNavA">{{ phrasen.result }}</a></li>
          </ul>
        </div>
      </nav>
      <template  v-for="(button_start, index) in buttonStart">
        <div v-if="button_start.frageAktiv" class="hide-on-med-and-up card cardGroesse" v-bind:key="index">
          <div class="card-action blue lighten-5 cardActionGroesse">
            <a href="#" v-if="button_start.buttonTyp !== 'zurueckAbschliessen'" class="blue-text text-darken-3" v-on:click="weiter(index)">
              <img class="right pfeilGroesse" src="/img/pfeil_blau.png" />
            </a>
            <a href="#" v-if="button_start.buttonTyp === 'zurueckAbschliessen'" class="blue-text text-darken-3" v-on:click="abschliessen">
              <img class="right pfeilGroesse" src="/img/pfeil_blau.png" /><div class="right zumErgebnis">{{ phrasen.to_results }}</div>
            </a>
            <a href="#" v-if="button_start.buttonTyp === 'zurueck' || button_start.buttonTyp === 'zurueckAbschliessen'" class="blue-text text-darken-3" v-on:click="zurueck(index)">
              <img class="left pfeilBackGroesse" src="/img/pfeil_blau_back.png" />
            </a>
          </div>
          <div id="cardHoehe">
            <div id="filterFrageDiv" v-if="index !== 4">
              <div class="card-title blue-text text-darken-3" >
                {{ button_start.frage }}
                <img v-if="index === 1" class="right infoButtonBlau" src="/img/info_blau.gif" v-on:click.stop="nierePopupOeffnen">
              </div>
            </div>
            <div id="filterAntwortenDiv">
              <div v-if="index !== 4">
                <label v-for="(button_start2, index2) in button_start.buttons" v-bind:key="index2">
                  <input :checked="ausgewaehlte_daten.includes(index2) ? 'checked' : ''" :name="button_start.klein" type="radio" class="with-gap" v-on:click="zeigeInfos(index2, button_start.id2, index)" :id="index2" />
                  <span class="blue-text text-darken-3"  :id="ausgewaehlte_daten.includes(index2) ? 'frageTextBold' : 'frageText'" v-html="button_start2">{{ button_start.name }}</span><br>
                </label>
              </div>
              <div v-if="index == 4" id="komedUntertitel">
               <div class="modalContentDosierungHeader blue lighten-5 marginTop5 blue-text text-darken-3">
                <div class="fontSize16"><b>{{ phrasen.select_komed2 }}</b></div>
                <table>
                  <tr class="trBorderNone">
                    <td class="paddingLeft15">
                      <template v-for="(buchstabe, key_b) in buchstaben">
                        <div v-if="ausgewaehlter_buchstabe != buchstabe" v-bind:key="key_b" class="buchstaben_click buchstabenClickPosition borderBuchstabe" v-on:click="buchstabeAufruf('buchstabe_' + buchstabe, buchstabe)"><a class="blue-text text-darken-3" href="#">{{ buchstabe }}</a></div>
                        <div v-if="ausgewaehlter_buchstabe == buchstabe" v-bind:key="key_b" class="buchstaben_click buchstabenClickPosition borderBuchstabe1" v-on:click="buchstabeAufruf('buchstabe_' + buchstabe, buchstabe)"><a class="blue-text text-darken-3" href="#">{{ buchstabe }}</a></div>
                      </template>
                      <div v-if="ausgewaehlter_buchstabe != 'alle'" class="buchstaben_click buchstabenClickPositionAlle borderBuchstabe" v-on:click="buchstabeAufruf('buchstabe_alle', 'alle')"><a class="blue-text text-darken-3" href="#">{{ phrasen.show_all }}</a></div>
                      <div v-if="ausgewaehlter_buchstabe == 'alle'" class="buchstaben_click buchstabenClickPositionAlle borderBuchstabe1" v-on:click="buchstabeAufruf('buchstabe_alle', 'alle')"><a class="blue-text text-darken-3" href="#">{{ phrasen.show_all }}</a></div>
                    </td>
                  </tr>
                </table>
              </div>
              <div class="modalContentDosierungHeader blue lighten-5 marginTop5 blue-text text-darken-3" v-if="ausgewaehlter_buchstabe != ''">
                <template v-for="(buchstabe) in buchstaben">
                  <table v-bind:key="buchstabe" v-if="ausgewaehlter_buchstabe == buchstabe">
                    <tr class="trBorderNone">
                      <td class="paddingNull1">
                        <div :id="'buchstabe_' + buchstabe" class="row">
                          <template v-for="(button_start2, index2) in buttonStart[4].buttons">
                            <div v-bind:key="index2" class="col s6" v-if="button_start2.charAt(0) == buchstabe">
                            <label v-if="button_start2.charAt(0) == buchstabe">
                              <input v-if="button_start2.charAt(0) == buchstabe" :name="buttonStart[4].klein" :checked="ausgewaehlte_daten.includes(index2) ? 'checked' : ''" type="checkbox" class="filled-in" v-on:click="zeigeInfos(index2, buttonStart[4].id2)" :id="index2" :ref="index2" />
                              <span v-if="button_start2.charAt(0) == buchstabe" class="blue-text text-darken-3 buchstabeKomedPos" v-html="button_start2">{{ buttonStart[4].name }}</span><br>
                          </label>
                            </div>
                        </template>
                    </div>
                      </td>
                    </tr>
                  </table>
                  </template>
                <table v-if="ausgewaehlter_buchstabe == 'alle'">
                  <tr class="trBorderNone">
                    <td class="paddingNull1">
                      <div class="row">
                        <template v-for="(button_start2, index2) in buttonStart[4].buttons">
                          <div v-bind:key="index2" class="col s6">
                      <label>
                              <input :name="buttonStart[4].klein" :checked="ausgewaehlte_daten.includes(index2) ? 'checked' : ''" type="checkbox" class="filled-in" v-on:click="zeigeInfos(index2, buttonStart[4].id2)" :id="index2" :ref="index2" />
                              <span class="blue-text text-darken-3 buchstabeKomedPos" v-html="button_start2">{{ buttonStart[4].name }}</span><br>
                      </label>
                    </div>
                        </template>
                    </div>
                    </td>
                  </tr>
                </table>
                    </div>
              <div class="modalContentDosierungHeader blue lighten-5 marginTop5 blue-text text-darken-3">
                <div class="ausgewaehlteKomedMobile"><b>{{ phrasen.selected_komed }}</b></div>
                <table>
                    <tr class="trBorderNone">
                      <td style="width:35px;padding:0px;padding-left:5px;">
                      <span ><img v-if="komedikation_ausgewaehlt" href="#!" class="left komedDelete" v-on:click.stop="einzelFilterEntfernen(buttonStart[4])" src="/img/delete_blau.gif"></span>
                      </td>
                      <td class="paddingNull1">
                        <div class="row">
                          <template v-for="(button_start2, index3) in buttonStart[4].buttons">
                            <div v-if="ausgewaehlte_daten.includes(index3)" v-bind:key="'ausgewaehlt_' + index3" class="col s6">
                      <label>
                                <input :name="buttonStart[4].klein" :checked="ausgewaehlte_daten.includes(index3) ? 'checked' : ''" type="checkbox" class="filled-in" v-on:click="zeigeInfos(index3, buttonStart[4])" :id="index3" :ref="index3" />
                                <span class="blue-text text-darken-3 buchstabeKomedPos" v-html="button_start2">{{ buttonStart[4].name }}</span><br>
                      </label>
                    </div>
                </template>
              </div>
                      </td>
                    </tr>
                  </table>
              </div>
                      </div>
              <br v-if="index === 1"><br v-if="index === 1">
              <div v-if="kreatinin_clearance == null" v-on:click="loggeDaten('nierenberechnung_aufruf')">
                <router-link to="/nierenberechnung" v-if="index === 1" class="btn blue darken-3 center width100" data-position="bottom">
                <span class="niere1">{{ phrasen.guess_kidney_function2 }}</span></router-link>
              </div>
              <div v-if="kreatinin_clearance != null" v-on:click="loggeDaten('nierenberechnung_aufruf')">
                <router-link to="/nierenberechnung" v-if="index === 1" class="btn blue darken-3 center width100 niereBerechnenMitErgebnis" data-position="bottom">
                  <span class="niere1">{{ phrasen.guess_kidney_function2 }}</span><br>
                  <span class="niereBerechnenWert">{{ phrasen.current_value }}: <span class="noWrap">{{ Math.round(kreatinin_clearance) }} ml/min</span>
                  <br>{{ phrasen.creatinine_value }}: {{ kreatinin }} mg/dl</span>
                </router-link>
            </div>
          </div>
        </div>
        </div>
      </template>
      <div class="col s12 l4">
        <div class="startseite_buttons hide-on-small-only" id="startseite_buttons">
          <template v-for="(button_start, index) in buttonStart">
            <a :class="button_start.klassen" :id="button_start.klein" :ref="'filter_' + index" v-on:click="zeigeButtons(button_start)" v-bind:key="index" v-if="index != 4">
              <img v-if="index === 1" class="left infoButton" src="/img/info.gif" v-on:click.stop="nierePopupOeffnen">
              <span class="right">
              <img src="/img/pfeil.png"  class="buttonPfeil" alt="pfeil" />
              </span>
              <div class="filterButtonNiere" v-if="index === 1">{{ button_start.name }}</div>
              <div v-if="index !== 1">{{ button_start.name }}</div>
            </a>
            <a :class="button_start.klassen" :id="button_start.klein" :ref="'filter_' + index" v-bind:key="index" v-if="index == 4" @click="buchstabenPopupOeffnen">
              <img v-if="komedikation_ausgewaehlt && index == 4" href="#!" class="left deleteButton" v-on:click.stop="einzelFilterEntfernen(button_start)" src="/img/delete.gif">
              <span class="right">
              <img src="/img/pfeil.png"  class="buttonPfeil" alt="pfeil" />
              </span>
              {{ button_start.name }}
            </a>
            <div :id="button_start.id2" v-bind:key="index+'_2'" class="displayBlock" v-if="index != 4">
              <template v-for="(button_start2, index2) in button_start.buttons">
                <a v-if="ausgewaehlte_daten.includes(index2) && ausgewaehlter_filter != button_start.id2" :class="button_start.klassen2" :id="'button_gewaehlt_' + index2" v-on:click="zeigeButtons(button_start)" v-bind:key="'ausgewaehlt_' + index2"><img class="left deleteButtonBlau" v-on:click.stop="einzelFilterEntfernen(button_start)" src="/img/delete_blau.gif"><span v-html="button_start2"></span></a>
                <a v-if="ausgewaehlter_filter == button_start.id2" :class="button_start.klassen2" :id="'button_' + index2" v-on:click="zeigeInfos(index2, button_start)" v-bind:key="index2"><img v-if="ausgewaehlte_daten.includes(index2)" class="left deleteButtonBlau" v-on:click.stop="einzelFilterEntfernen(button_start)" src="/img/delete_blau.gif"><span v-html="button_start2"></span></a>
              </template>
              <div v-on:click="loggeDaten('nierenberechnung_aufruf')">
                <router-link to="/nierenberechnung" v-if="index == 1 && ausgewaehlter_filter == button_start.id2" class="btn startseite_button2 blue lighten-5 blue-text text-darken-3 buttonFilterNierenberechnung" id="nierenberechnung">
                  {{ phrasen.guess_kidney_function2 }}
                  <span class="right">
                    <img src="/img/pfeil_blau.png"  class="buttonPfeilNiere" alt="pfeil" />
                  </span>
                  <br v-if="kreatinin_clearance != null && index === 1">
                  <div v-if="kreatinin_clearance != null && index === 1" class="kreatininClearanceWert">{{ phrasen.current_value }}: <span class="noWrap">{{ Math.round(kreatinin_clearance) }} ml/min</span><br>{{ phrasen.creatinine_value }}: {{ kreatinin }} mg/dl</div>
                </router-link>
              </div>
            </div>
            <div :id="button_start.id2" v-bind:key="index+'_2'" class="displayBlock"  v-if="index == 4">
              <a :class="button_start.klassen2 + ' komedGruppen'" :id="'button_g_' + button_start.name" v-if="filter_texte.komedikation.vorhanden && ausgewaehlter_filter == button_start.id2">{{ phrasen.selected_komed }}</a>
              <template v-for="(button_start2, index2) in button_start.buttons">
                <a v-if="ausgewaehlte_daten.includes(index2) && ausgewaehlter_filter != button_start.id2" :class="button_start.klassen2" :id="'button_gewaehlt_' + index2" @click="buchstabenPopupOeffnen" v-bind:key="'ausgewaehlt_' + index2"><img v-on:click.stop="zeigeInfos(index2, button_start)"  class="left deleteButtonBlau" src="/img/delete_blau.gif"><span v-html="button_start2"></span></a>
                <a v-if="ausgewaehlte_daten.includes(index2) && ausgewaehlter_filter == button_start.id2" :class="button_start.klassen2 + ' hellblauButton'" :id="'button_' + index2" v-on:click="zeigeInfos(index2, button_start)" v-bind:key="index2"><img class="left deleteButtonBlau" src="/img/delete_blau.gif"><span v-html="button_start2"></span></a>
              </template>
              <a :class="button_start.klassen2 + ' komedGruppen'" :id="'button_nicht_g_' + button_start.name" v-if="filter_texte.komedikation.vorhanden && ausgewaehlter_filter == button_start.id2">{{ phrasen.not_selected_komed }}</a>
              <template v-for="(button_start2, index2) in button_start.buttons">
                <a v-if="!ausgewaehlte_daten.includes(index2) && ausgewaehlter_filter == button_start.id2"  :class="button_start.klassen2" :id="'button_' + index2" v-on:click="zeigeInfos(index2, button_start)" v-bind:key="index2"><span v-html="button_start2"></span></a>
              </template>
            </div>
          </template>
          <br>
          <button class="btn blue darken-3 filterEntfernen" v-on:click="alleFilterEntfernen(null)" v-if="ausgewaehlte_daten.length > 0">
            <img class="left deleteButton2" src="/img/delete.gif">
            Alle Filter entfernen</button>
          <hr id="hrTrennung" class="blue darken-3 hide-on-large-only">
        </div>
      </div>
      <div class="col s12 l8 paddingNull1">
        <div class="doak_typen">
          <div id="doak_tr" class="row" style="margin-left:0px;margin-right:0px;">
            <DOAKAusgabe :ausgabe_texte="ausgabeTexte" :indikation_ausgewaehlt="indikation_ausgewaehlt" :niere_ausgewaehlt="niere_ausgewaehlt" :filter_texte="filter_texte" :phrasen="phrasen" :posNiereTop="nierenButtonTop" />
          </div>
        </div>
        <Kosten :phrasen="phrasen" :kosten="doak_kosten" v-if="indikation_ausgewaehlt && niere_ausgewaehlt"/>
      </div>
      <div v-if="buchstabenPopupOffen || nierePopupOffen" class="black overflowGrau" ></div>
      <div v-if="nierePopupOffen" id="modalKomedAuswahl" class="modal">
        <div class="modal-content padding5">
           <HinweisNiere :phrasen="phrasen" />
        </div>
      </div>
      <div v-if="buchstabenPopupOffen" id="modalKomedAuswahl" class="modal hide-on-small-only">
        <div class="modal-content padding5">
          <div class="modalContentDosierungHeader">
            <table>
              <tr class="trBorderNone">
                <td class="modalDosierungTd">
                  <span class="modalDosierungSpan">{{ phrasen.select_of_komed }}</span>
                </td>
                <td class="modalDosierungTd2">
                </td>
                <td class="closeModalTd">
                  <img href="#!" class="modal-close right widthClose" src="/img/close.gif" @click="buchstabenPopupSchliessen">
                </td>
              </tr>
            </table>
          </div>
          <div v-if="!alleKomed" class="modalContentDosierungHeader blue lighten-5 marginTop5 blue-text text-darken-3">
            <table>
              <tr class="trBorderNone">
                <td class="paddingNull1">
                  <div class="marginTop12">{{ phrasen.select_buchstabe }}</div>
                  <table>
                    <tr class="trBorderNone">
                      <td class="paddingTopAlleAnzeigen">
                        <br>
                        {{ phrasen.or_select }}
                      </td>
                      <td class="paddingTopAlleAnzeigen">
                        <br>
                        <div @click="AlleKomedAnzeigen" class="cursorPonter borderBuchstabe2">{{ phrasen.show_all }}</div>
                      </td>
                    </tr>
                  </table>
                </td>
                <td class="buchstabenUebersicht">
                  <template v-for="(buchstabe, key_b) in buchstaben">
                    <div v-if="ausgewaehlter_buchstabe != buchstabe" v-bind:key="key_b" class="buchstaben_click buchstabenClickPosition borderBuchstabe" v-on:click="buchstabeAufruf('buchstabe_' + buchstabe, buchstabe)"><a class="blue-text text-darken-3" href="#">{{ buchstabe }}</a></div>
                    <div v-if="ausgewaehlter_buchstabe == buchstabe" v-bind:key="key_b" class="buchstaben_click buchstabenClickPosition borderBuchstabe1" v-on:click="buchstabeAufruf('buchstabe_' + buchstabe, buchstabe)"><a class="blue-text text-darken-3" href="#">{{ buchstabe }}</a></div>
                  </template>
                </td>
              </tr>
            </table>
          </div>
          <div class="modalContentDosierungHeader blue lighten-5 marginTop5 blue-text text-darken-3" v-if="alleKomed">
            <div @click="BuchstabenAnzeigen" class="buchstabenZurueck">{{ phrasen.back_to_buchstabe }}</div>
            <diV class="marginLeft5">
            <template v-for="(button_start2, index2) in buttonStart[4].buttons">
              <span v-bind:key="index2">
                  &nbsp;<b><b>&#183;</b></b>
                  <u>
                    <span v-if="!komed_anfang_gleich.includes(index2)" class="blue-text text-darken-3 buchstabeKomedPos cursorPonter" @click="zeigeInfos(index2, buttonStart[4].id2)">
                      <span style="font-size:105%;font-weight:bold;text-transform: uppercase;">{{ button_start2.substr(0, 3) }}</span>{{ button_start2.substr(3, button_start2.length - 3) }}
                    </span>
                    <span v-if="komed_anfang_gleich.includes(index2)" class="blue-text text-darken-3 buchstabeKomedPos cursorPonter" @click="zeigeInfos(index2, buttonStart[4].id2)">
                      <span style="font-size:105%;font-weight:bold;text-transform: uppercase;">{{ button_start2.substr(0, 5) }}</span>{{ button_start2.substr(5, button_start2.length - 5) }}
                    </span>
                  </u>
              </span>
            </template>
            </div>
            <div></div>
          </div>
          <div class="modalContentDosierungHeader blue lighten-5 marginTop5 blue-text text-darken-3" v-if="ausgewaehlter_buchstabe != '' && !alleKomed">
            <template v-for="(buchstabe) in buchstaben">
              <table v-bind:key="buchstabe" v-if="ausgewaehlter_buchstabe == buchstabe">
                <tr class="trBorderNone">
                  <td class="buchstabeGewaehlt">
                  <span><b>{{ buchstabe }}</b></span>
                  </td>
                  <td class="paddingNull1">
                    <div :id="'buchstabe_' + buchstabe" class="row">
                      <template v-for="(button_start2, index2) in buttonStart[4].buttons">
                        <div v-bind:key="index2" class="col s4" v-if="button_start2.charAt(0) == buchstabe">
                        <label v-if="button_start2.charAt(0) == buchstabe">
                          <input v-if="button_start2.charAt(0) == buchstabe" :name="buttonStart[4].klein" :checked="ausgewaehlte_daten.includes(index2) ? 'checked' : ''" type="checkbox" class="filled-in" v-on:click="zeigeInfos(index2, buttonStart[4].id2)" :id="index2" :ref="index2" />
                          <span v-if="button_start2.charAt(0) == buchstabe" class="blue-text text-darken-3 buchstabeKomedPos" v-html="button_start2">{{ buttonStart[4].name }}</span><br>
                        </label>
                        </div>
                      </template>
                    </div>
                  </td>
                </tr>
              </table>
            </template>
          </div>
          <div class="modalContentDosierungHeader blue lighten-5 marginTop5 blue-text text-darken-3">
            <table class="KomedDeleteTable">
              <tr class="trBorderNone">
                <td class="KomedDeleteTd" v-if="komedikation_ausgewaehlt">
                  <span><img href="#!" class="left komedDelete" v-on:click.stop="einzelFilterEntfernen(buttonStart[4])" src="/img/delete_blau.gif"></span>
                </td>
                <td class="paddingNull1">
                  {{ phrasen.selected_komed }}
                </td>
              </tr>
            </table>
            <table>
                <tr class="trBorderNone">
                  <td class="paddingNull1">
                    <div class="row">
                      <template v-for="(button_start2, index3) in buttonStart[4].buttons">
                        <div v-if="ausgewaehlte_daten.includes(index3)" v-bind:key="'ausgewaehlt_' + index3" class="col s3 komedGewaehltDiv">
                          <label>
                            <input :name="buttonStart[4].klein" :checked="ausgewaehlte_daten.includes(index3) ? 'checked' : ''" type="checkbox" class="filled-in" v-on:click="zeigeInfos(index3, buttonStart[4])" :id="index3" :ref="index3" />
                            <span class="blue-text text-darken-3 buchstabeKomedPos" v-html="button_start2">{{ buttonStart[4].name }}</span><br>
                          </label>
                        </div>
                      </template>
                    </div>
                  </td>
                </tr>
              </table>
          </div>
        </div>
      </div>
      <div v-if="alleFragenFertig && ausgewaehlte_daten.length > 0" class="startseite_buttons hide-on-med-and-up col s12 marginTop15" id="startseite_buttons">
        <button class="btn blue darken-3 filterEntfernen" v-on:click="alleFilterEntfernen(null)" v-if="ausgewaehlte_daten.length > 0">
            <img class="left deleteButton2" src="/img/delete.gif">
            Alle Filter entfernen</button>
      </div>
    </div>
  </div>
</template>

<script>
import M from 'materialize-css/dist/js/materialize.min'
import DOAKAusgabe from '@/components/DOAKAusgabe.vue'
import HinweisNiere from '@/components/HinweisNiere.vue'
import Kosten from '@/components/Kosten.vue'

export default {
  name: 'Therapieinhalt',
  props: ['bestaetigungArzt', 'phrasen', 'daten', 'sperre'],
  data () {
    return {
      buttonStart: [
        { name: this.phrasen.indication, klassen: 'btn startseite_button blue darken-3', klein: 'indikation', id2: 'startseite_indikation', klassen2: 'btn startseite_button2 blue lighten-5 blue-text text-darken-3', frage: this.phrasen.indication_select, antwortTyp: 'radio', buttonTyp: 'weiter', frageAktiv: true },
        { name: this.phrasen.kidney_function, klassen: 'btn startseite_button blue darken-3', klein: 'niere', id2: 'startseite_niere', klassen2: 'btn startseite_button2 blue lighten-5 blue-text text-darken-3', frage: this.phrasen.kidney_select, antwortTyp: 'radio', buttonTyp: 'zurueck', frageAktiv: false },
        { name: this.phrasen.age, klassen: 'btn startseite_button blue darken-3', klein: 'alter', id2: 'startseite_alter', klassen2: 'btn startseite_button2 blue lighten-5 blue-text text-darken-3', frage: this.phrasen.age_select, antwortTyp: 'radio', buttonTyp: 'zurueck', frageAktiv: false },
        { name: this.phrasen.weight, klassen: 'btn startseite_button blue darken-3', klein: 'gewicht', id2: 'startseite_gewicht', klassen2: 'btn startseite_button2 blue lighten-5 blue-text text-darken-3', frage: this.phrasen.weight_select, antwortTyp: 'radio', buttonTyp: 'zurueck', frageAktiv: false },
        { name: this.phrasen.komed2, klassen: 'btn startseite_button blue darken-3', klein: 'komedikation', id2: 'startseite_komedikation', klassen2: 'btn startseite_button2 blue lighten-5 blue-text text-darken-3', frage: this.phrasen.komed_select, antwortTyp: 'checkbox', buttonTyp: 'zurueckAbschliessen', frageAktiv: false }
      ],
      ausgewaehlte_daten: [], // hier werden die ausgewählten Filter gespeichert
      ausgewaehlte_daten_temp: [], // hier werden die vorausgewählten Komedikationen gespeichert, die im Buchstaben-Popup ausgewählt werden, bevor diese Übernommen werden
      ausgewaehlte_daten_temp_modified: [], // gilt das gleiche wie oben
      dosierung_texte: {}, // hierher werden die eingelesenen Dosierungstexte aus der daten.json gespeichert
      hinweise: {}, // hierher werden die eingelesenen Hinweise aus der daten.json gespeichert
      begruendungen: {}, // hierher werden die eingelesenen Begründungen aus der daten.json gespeichert
      kontraindikationen: {}, // hierher werden die eingelesenen Kontraindikationen aus der daten.json gespeichert
      doak: null, // Datenfeld für die Namen der vorhanden DOAK-Medikamente
      doak_wirkstoff: null, // Datenfeld für die Namen der vorhanden DOAK-Wirkstoffe
      doak_count: 0, // Datenfeld für die Anzahl der vorhanden DOAK-Medikamente
      komedikation_texte: null, // hierher werden die eingelesenen Komediaktionstexte aus der daten.json gespeichert
      filter_texte: { niere: { vorhanden: false }, indikation: false, alter: false, gewicht: false, komedikation: {} }, // Datenobjekt zur Anzeige der gewählten Filter
      ausgabeTexte: {}, // hier werden alle Texte zur Übergabe aun die ausgabe_texte.vue gespeichert
      countKomed: 0, // Anzahl der Komedikamente
      countIndikation: 0, // Anzahl der Indikationen
      indikation_ausgewaehlt: true, // ist eine Indikation gewählt?
      komedikation_ausgewaehlt: true, // ist ein oder mehrere Komedikamente gewählt?
      alter_ausgewaehlt: false, // ist Alter gewählt?
      niere_ausgewaehlt: false, // ist Niere gewählt?
      gewicht_ausgewaehlt: false, // ist Gewicht gewählt?
      dataReady: false, // wurden die Daten aus der Daten.json eingelesen?
      alleFragenFertig: false, // Alle notwendigen Filter ausgewählt, um das Ergebnis anzuzeigen
      buchstaben: [], // Vorhandenen Erstbuchstaben von den Komedikationen werden hier eingelesen
      ausgewaehlter_buchstabe: '', // der zur Zeit ausgewählte Buchstabe im KomedPopUp
      buchstabenPopupOffen: false, // gibt vor, ob das KomedPopup offen sein soll oder nicht
      ausgewaehlter_filter: '', // der zur Zeit ausgewählte Filter in der Desktop Ansicht
      kuerzel: { doak: 'd', indikation: 'i', niere: 'n', alter: 'a', gewicht: 'g', komed: 'k', komedGruppe: 'kg' },
      doak_pdf: {}, // hier werden die Links zu den Fachinfos abgelegt
      doak_gba_pdf: {}, // hier werden die Links zu den GBA abgelegt
      nierenButtonTop: 0, // Ist die Position der Buttons Nierenfunktion
      doak_kosten: {}, // hier werden alle Daten zu den Kosten dieses DOAK-Medikamentes abgelegt
      alleKomed: false, // sollen in der Komed Auswahl alle Präparate aufgelistet werden?
      komed_anfang_gleich: {}, // hier sind alle Komedikationen hinterlegt, zu denen es eine Komedikation gibt, bei der die ersten 3 Buchstaben übereinstimmen
      nierePopupOffen: false, // gibt vor, ob das NierenInfoPopup offen sein soll oder nicht
      kreatinin_clearance: null, // die berechnete Kreatinin-Clearance
      kreatinin: null, // Kreatinin-Wert, der bei der Nierenberechnung eingegeben wurde
      schweregrad_keine_einschraenkungen: '1', // festgelegter Wert für den Schweregrad 'keine Einschränkungen'
      schweregrad_nicht_zugelassen: '2', // festgelegter Wert für den Schweregrad 'nicht zugelassen'
      schweregrad_mit_vorsicht: '3', // festgelegter Wert für den Schweregrad 'mit Vorsicht'
      schweregrad_vermeiden: '4', // festgelegter Wert für den Schweregrad 'vermeiden'
      schweregrad_nicht_einsetzen: '5', // festgelegter Wert für den Schweregrad 'nicht einsetzen'
      schweregrad_nicht_empfohlen: '6', // festgelegter Wert für den Schweregrad 'nicht empfohlen'
      schweregrad_kontraindiziert: '7', // festgelegter Wert für den Schweregrad 'kontraindiziert'
      schweregrad_besonderheiten_freitext: '8' // festgelegter Wert für den Schweregrad 'Besonderheiten/Freitext'
    }
  },
  components: {
    DOAKAusgabe,
    Kosten,
    HinweisNiere
  },
  methods: {
    // Die Wissensdaten aus der daten.json werden eingelesen
    init_daten: function () {
      this.doak = this.daten.doak
      this.doak_count = Object.keys(this.doak).length
      this.doak_wirkstoff = this.daten.doak_wirkstoff
      this.komedikation_texte = this.daten.komedikation
      this.buttonStart[0].buttons = this.daten.falldaten.indikation
      this.countIndikation = Object.keys(this.buttonStart[0].buttons).length
      this.buttonStart[1].buttons = this.daten.falldaten.niere
      this.buttonStart[2].buttons = this.daten.falldaten.alter
      this.buttonStart[3].buttons = this.daten.falldaten.gewicht
      this.buttonStart[4].buttons = this.daten.falldaten.komedikation
      this.buttonStart[4].gruppen = this.daten.falldaten.komedikation_gruppen
      this.komed_anfang_gleich = this.daten.komed_anfang_gleich
      this.dosierung_texte = this.daten.dosierung_texte
      this.hinweise = this.daten.hinweise
      this.begruendungen = this.daten.begruendungen
      this.kontraindikationen = this.daten.kontraindikationen
      this.doak_pdf = this.daten.doak_pdf
      this.doak_gba_pdf = this.daten.doak_gba_pdf
      this.doak_kosten = this.daten.kosten
      this.countKomed = Object.keys(this.buttonStart[4].buttons).length
      this.dataReady = true
      // Aufbereitung
      for (var i2 = 1; i2 <= this.doak_count; i2++) {
        var doacindex = this.kuerzel.doak + i2 // enthält d1 oder d2 usw.
        // Komedikationsnamen der DOAK-Kontraindikationen setzen
        var namen = {}
        var tempFalldatenKomed = Object.keys(this.daten.falldaten.komedikation) // Lese Indizies von z.B. K164 = Diclofenac -> K164
        for (var a = 1; a <= tempFalldatenKomed.length; a++) {
          var komedKuerzel = tempFalldatenKomed[a] // also z.B. K164
          var kontrakomedcount = this.kontraindikationen[doacindex].komedikation.length // Anzahl der Komed-KI für das DOAC
          for (var kontrakomedindex = 0; kontrakomedindex < kontrakomedcount; kontrakomedindex++) {
            if (this.kontraindikationen[doacindex].komedikation[kontrakomedindex] === komedKuerzel) {
              namen[this.kontraindikationen[doacindex].komedikation[kontrakomedindex]] = this.daten.falldaten.komedikation[komedKuerzel]
            }
          }
        }
        this.kontraindikationen[doacindex].komedNamen = namen
        // an die Kostendaten werden die DOAK-Namen, Wirkstoffe und GBA-Links angehängt, um diese dann an die Komponente "Kosten" zu übergeben
        this.doak_kosten[doacindex].name = this.doak[doacindex]
        this.doak_kosten[doacindex].wirkstoff = this.doak_wirkstoff[doacindex]
        if (this.doak_gba_pdf[doacindex] != null) {
          this.doak_kosten[doacindex].gba = this.doak_gba_pdf[doacindex]
        }
      }
      this.buchstabenFuellen(this.buttonStart[4].buttons)
      this.filterAnzeigen()
      this.infosAnzeigen()
    },
    buchstabenPopupOeffnen: function () {
      this.buchstabenPopupOffen = true
    },
    buchstabenPopupSchliessen: function () {
      this.ausgewaehlter_buchstabe = ''
      this.buchstabenPopupOffen = false
    },
    nierePopupOeffnen: function () {
      this.nierePopupOffen = true
    },
    AlleKomedAnzeigen: function () {
      this.alleKomed = true
    },
    BuchstabenAnzeigen: function () {
      this.alleKomed = false
    },
    // hier werden die Filter für einen bestimmten Punkt (z.B. Indikation) entfernt
    einzelFilterEntfernen: function (id) {
      this.ausgewaehlter_filter = ''
      var tempKuerzel = ''
      for (const [key] of Object.entries(id.buttons)) {
        tempKuerzel = key.slice(0, 1)
        if (this.ausgewaehlte_daten.includes(key)) {
          switch (tempKuerzel) {
            case this.kuerzel.indikation:
              this.loggeDaten('indikation_entfernt_' + key)
              break
            case this.kuerzel.alter:
              var alterTemp = ''
              if (key === 'a1') {
                alterTemp = 'unter75'
              } else if (key === 'a2') {
                alterTemp = 'ueber75'
              } else {
                alterTemp = 'ueber80'
              }
              this.loggeDaten('alter_entfernt_' + alterTemp)
              break
            case this.kuerzel.niere:
              var niereTemp = ''
              if (key === 'n1') {
                niereTemp = 'ueber50'
              } else if (key === 'n2') {
                niereTemp = 'ueber30'
              } else if (key === 'n3') {
                niereTemp = 'ueber15'
              } else {
                niereTemp = 'unter15'
              }
              this.loggeDaten('niere_entfernt_' + niereTemp)
              break
            case this.kuerzel.gewicht:
              var gewichtTemp = ''
              if (key === 'g1') {
                gewichtTemp = 'unter60'
              } else {
                gewichtTemp = 'ueber60'
              }
              this.loggeDaten('gewicht_entfernt_' + gewichtTemp)
              break
            case this.kuerzel.komed:
              this.loggeDaten('komed_min_' + this.buttonStart[4].buttons[key].substring(0, 28))
              break
            default:
          }
          this.ausgewaehlte_daten.splice(this.ausgewaehlte_daten.indexOf(key), 1)
        }
      }
      if (tempKuerzel === this.kuerzel.niere || tempKuerzel === this.kuerzel.alter || tempKuerzel === this.kuerzel.gewicht) {
        this.loescheWerteNierenberechnung()
      }
      localStorage.ausgewaehlte_daten = JSON.stringify(this.ausgewaehlte_daten)
      this.filterAnzeigen()
      this.infosAnzeigen()
    },
    // hier wird nach dem Schliessen eines Popups der overflow aus dem Body entfernt, was die Scollprobleme verhindern soll
    overflowEntfernen: function () {
      var elem1 = document.body
      elem1.style.overflow = ''
    },
    // hier wird nach dem Schliessen des Komed-Popups der overflow aus dem Body entfernt, was die Scollprobleme verhindern soll.
    overflowEntfernenKomedPopup: function () {
      this.ausgewaehlter_buchstabe = ''
      this.buchstabenPopupOffen = false
      var elem1 = document.body
      elem1.style.overflow = ''
    },
    // Beim Öffnen einer Komed_gruppe scrollt man automatisch zu dem Gruppenanfang
    scrollZuGruppe: function (key) {
      var elem1 = document.getElementById('gruppeDiv_' + key)
      elem1.scrollIntoView(true)
    },
    // Hier werden die Erstbuchstaben der Komedikation in das Array "this.buchstaben" gefüllt
    buchstabenFuellen: function (komedikation) {
      var tempFalldatenKomed = Object.keys(komedikation)
      for (var a = 1; a <= tempFalldatenKomed.length; a++) {
        var komedKuerzel = tempFalldatenKomed[a] // also z.B. K164
        if (komedikation[komedKuerzel] != null && !this.buchstaben.includes(komedikation[komedKuerzel].charAt(0))) {
          this.buchstaben.push(komedikation[komedKuerzel].charAt(0))
        }
      }
    },
    // Beim Clicken auf einen Buchstaben, werden hier die dazugehörigen Komedikationen sichtbar gemacht
    buchstabeAufruf: function (id1, buchstabe) {
      this.ausgewaehlter_buchstabe = buchstabe
    },
    // Hier geschieht das Gegenteil von der weiter oben stehenden Funktion
    buchstabeAbruf: function (id1, buchstabe) {
      this.ausgewaehlter_buchstabe = ''
    },
    // Bei dieser Funktion schreitet man bei der mobilen Ansicht von einem Filter zu nächsten
    weiter: function (button) {
      if (this.alleFragenFertig === true) {
        this.abschliessen()
        return
      }
      for (let i = 0; i < 5; i++) {
        this.buttonStart[i].frageAktiv = false
      }
      if (this.buttonStart[button + 1] != null) {
        this.buttonStart[button + 1].frageAktiv = true
      }
    },
    // Bei dieser funktion schreitet man bei der mobilen Ansicht zu einem beliebigen Filter
    frageTauschen: function (button) {
      for (let i = 0; i < 5; i++) {
        this.buttonStart[i].frageAktiv = false
      }
      if (this.buttonStart[button] != null) {
        this.buttonStart[button].frageAktiv = true
      }
    },
    // diese Funktion zeigt die Doesierungsangaben bei der mobilen Ansicht
    abschliessen: function () {
      for (let i = 0; i < 5; i++) {
        this.buttonStart[i].frageAktiv = false
      }
      this.alleFragenFertig = true
    },
    // Bei dieser Funktion geht man bei der mobilen Ansicht zur vorherigen Filter
    zurueck: function (button) {
      for (let i = 0; i < 5; i++) {
        this.buttonStart[i].frageAktiv = false
      }
      if (this.buttonStart[button - 1] != null) {
        this.buttonStart[button - 1].frageAktiv = true
      }
    },
    // diese Funktion löscht alle gesetzten Filter
    alleFilterEntfernen: function (id) {
      if (id == null) {
        this.loggeDaten('filter_entfernen_therapie')
      }
      this.ausgewaehlter_filter = ''
      this.ausgewaehlte_daten = []
      localStorage.removeItem('ausgewaehlte_daten')
      for (let i = 0; i < 5; i++) {
        this.buttonStart[i].frageAktiv = false
      }
      this.buttonStart[0].frageAktiv = true
      this.alleFragenFertig = false
      localStorage.removeItem('kreatinin_clearance')
      this.kreatinin_clearance = null
      this.kreatinin = null
      window.scrollTo(0, 0)
      this.loescheWerteNierenberechnung()
      this.filterAnzeigen()
      this.infosAnzeigen()
    },
    // diese Funktion klappt in der Desktop-Ansicht alle zu einem Filter gehörenden Buttons auf
    zeigeButtons: function (id) {
      if (this.ausgewaehlter_filter === '' || this.ausgewaehlter_filter !== id.id2) {
        this.ausgewaehlter_filter = id.id2
      } else {
        this.ausgewaehlter_filter = ''
      }
    },
    // hier werden die Texte für die Filter gesetzt (z.B. 'a1' -> '< 75 Jahren')
    filterAnzeigen: function () {
      this.filter_texte = { niere: { vorhanden: false }, indikation: false, alter: false, gewicht: false, komedikation: { vorhanden: false } }
      var indikationAusgewaehlt = false
      var niereAusgewaehlt = false
      if (this.ausgewaehlte_daten.length > 0) {
        // Texte für Indikation setzen
        var keysIndikation = Object.keys(this.daten.falldaten.indikation)
        for (var i1 = 0; i1 < keysIndikation.length; i1++) {
          if (this.ausgewaehlte_daten.includes(keysIndikation[i1])) {
            indikationAusgewaehlt = true
            this.filter_texte.indikation = this.buttonStart[0].buttons[keysIndikation[i1]]
            break
          }
        }
        // Texte für Alter setzen
        var keysAlter = Object.keys(this.daten.falldaten.alter)
        for (var i2 = 0; i2 < keysAlter.length; i2++) {
          if (this.ausgewaehlte_daten.includes(keysAlter[i2])) {
            this.filter_texte.alter = this.buttonStart[2].buttons[keysAlter[i2]]
            break
          }
        }
        // Texte für Gewicht setzen
        var keysGewicht = Object.keys(this.daten.falldaten.gewicht)
        for (var i3 = 0; i3 < keysGewicht.length; i3++) {
          if (this.ausgewaehlte_daten.includes(keysGewicht[i3])) {
            this.filter_texte.gewicht = this.buttonStart[3].buttons[keysGewicht[i3]]
            break
          }
        }
        // Texte für Niere setzen
        var keysNiere = Object.keys(this.daten.falldaten.niere)
        for (var i4 = 0; i4 < keysNiere.length; i4++) {
          if (this.ausgewaehlte_daten.includes(keysNiere[i4])) {
            niereAusgewaehlt = true
            this.filter_texte.niere[i4] = this.buttonStart[1].buttons[keysNiere[i4]]
            this.filter_texte.niere.vorhanden = true
            break
          }
        }
        // Texte für Komedikation setzen
        var tempFalldatenKomed = Object.keys(this.daten.falldaten.komedikation)
        for (var i5 = 0; i5 < tempFalldatenKomed.length; i5++) {
          var komedKuerzel = tempFalldatenKomed[i5] // also z.B. K164
          if (this.ausgewaehlte_daten.includes(komedKuerzel)) {
            this.filter_texte.komedikation[komedKuerzel] = this.buttonStart[4].buttons[komedKuerzel]
            this.filter_texte.komedikation.vorhanden = true
          }
        }
      }
      if (indikationAusgewaehlt && niereAusgewaehlt) {
        this.indikationNiereGewaehlt = true
      } else {
        this.indikationNiereGewaehlt = false
      }
    },
    // In dieser Funktion werden die einzeln aufgelisteten Komedikationen aus dem Komed-Buchstaben-Popup ausgewählt
    einzelAuswaehlen: function (id1) {
      if (this.ausgewaehlte_daten.includes(id1)) {
        this.ausgewaehlte_daten.splice(this.ausgewaehlte_daten.indexOf(id1), 1)
        localStorage.ausgewaehlte_daten = JSON.stringify(this.ausgewaehlte_daten)
        this.filterAnzeigen()
        this.infosAnzeigen()
        return
      }
      this.ausgewaehlte_daten.push(id1)
      localStorage.ausgewaehlte_daten = JSON.stringify(this.ausgewaehlte_daten)
      this.filterAnzeigen()
      this.infosAnzeigen()
    },
    // in dieser Funktion werden die ausgewählen Filter in die Arrays this.ausgewaehlte_daten eingetragen bzw aus diesen gelöscht
    zeigeInfos: function (id1, id2, id3) {
      this.ausgewaehlter_filter = ''
      var tempKuerzel = id1.slice(0, 1)
      if (this.ausgewaehlte_daten.includes(id1)) {
        this.ausgewaehlte_daten.splice(this.ausgewaehlte_daten.indexOf(id1), 1)
        localStorage.ausgewaehlte_daten = JSON.stringify(this.ausgewaehlte_daten)
        this.filterAnzeigen()
        this.infosAnzeigen()
        return
      }
      switch (tempKuerzel) {
        case this.kuerzel.indikation:
          for (var j = 1; j <= this.countIndikation; j++) {
            if (this.ausgewaehlte_daten.includes(this.kuerzel.indikation + j)) {
              this.ausgewaehlte_daten.splice(this.ausgewaehlte_daten.indexOf(this.kuerzel.indikation + j), 1)
            }
          }
          this.loggeDaten('indikation_gewaehlt_' + id1)
          break
        case this.kuerzel.alter:
          localStorage.removeItem('kreatinin_clearance')
          this.kreatinin_clearance = null
          for (var j2 = 1; j2 <= this.countIndikation; j2++) {
            if (this.ausgewaehlte_daten.includes(this.kuerzel.alter + j2)) {
              this.ausgewaehlte_daten.splice(this.ausgewaehlte_daten.indexOf(this.kuerzel.alter + j2), 1)
            }
          }
          var alterTemp = ''
          if (id1 === 'a1') {
            alterTemp = 'unter75'
          } else if (id1 === 'a2') {
            alterTemp = 'ueber75'
          } else {
            alterTemp = 'ueber80'
          }
          this.loggeDaten('alter_gewaehlt_' + alterTemp)
          this.loescheWerteNierenberechnung()
          break
        case this.kuerzel.niere:
          localStorage.removeItem('kreatinin_clearance')
          this.kreatinin_clearance = null
          for (var j4 = 1; j4 <= this.countIndikation; j4++) {
            if (this.ausgewaehlte_daten.includes(this.kuerzel.niere + j4)) {
              this.ausgewaehlte_daten.splice(this.ausgewaehlte_daten.indexOf(this.kuerzel.niere + j4), 1)
            }
          }
          var niereTemp = ''
          if (id1 === 'n1') {
            niereTemp = 'ueber50'
          } else if (id1 === 'n2') {
            niereTemp = 'ueber30'
          } else if (id1 === 'n3') {
            niereTemp = 'ueber15'
          } else {
            niereTemp = 'unter15'
          }
          this.loggeDaten('niere_gewaehlt_' + niereTemp)
          this.loescheWerteNierenberechnung()
          break
        case this.kuerzel.gewicht:
          localStorage.removeItem('kreatinin_clearance')
          this.kreatinin_clearance = null
          for (var j3 = 1; j3 <= this.countIndikation; j3++) {
            if (this.ausgewaehlte_daten.includes(this.kuerzel.gewicht + j3)) {
              this.ausgewaehlte_daten.splice(this.ausgewaehlte_daten.indexOf(this.kuerzel.gewicht + j3), 1)
            }
          }
          var gewichtTemp = ''
          if (id1 === 'g1') {
            gewichtTemp = 'unter60'
          } else {
            gewichtTemp = 'ueber60'
          }
          this.loggeDaten('gewicht_gewaehlt_' + gewichtTemp)
          this.loescheWerteNierenberechnung()
          break
        case this.kuerzel.komed:
          this.loggeDaten('komed_plus_' + this.buttonStart[4].buttons[id1].substring(0, 28))
          break
        default:
      }
      this.ausgewaehlte_daten.push(id1)
      localStorage.ausgewaehlte_daten = JSON.stringify(this.ausgewaehlte_daten)
      this.filterAnzeigen()
      this.infosAnzeigen()
      if (tempKuerzel !== this.kuerzel.komed) {
        this.weiter(id3)
      }
    },
    loescheWerteNierenberechnung: function () {
      localStorage.removeItem('kreatinin')
      localStorage.removeItem('alter_nierenberechnung')
      localStorage.removeItem('gewicht_nierenberechnung')
      localStorage.removeItem('geschlecht_nierenberechnung')
    },
    // hier werden im Komed-Popup die angeklickten Komediaktionen zwischengespeicher, solange nicht auf den Übernehmen-Button geklickt wurde
    vorAuswaehlen: function (id1, id2, bereitsAusgewaehlt) {
      if (!this.ausgewaehlte_daten_temp_modified.includes(id1)) {
        this.ausgewaehlte_daten_temp_modified.push(id1)
      }
      if (!bereitsAusgewaehlt) {
        if (this.ausgewaehlte_daten_temp.includes(id1)) {
          this.ausgewaehlte_daten_temp.splice(this.ausgewaehlte_daten_temp.indexOf(id1), 1)
          return
        }
        this.ausgewaehlte_daten_temp.push(id1)
      }
      if (bereitsAusgewaehlt) {
        if (!this.$refs[id1][0].checked && this.ausgewaehlte_daten_temp.includes(id1)) {
          this.ausgewaehlte_daten_temp.splice(this.ausgewaehlte_daten_temp.indexOf(id1), 1)
        } else if (this.$refs[id1][0].checked && !this.ausgewaehlte_daten_temp.includes(id1)) {
          this.ausgewaehlte_daten_temp.push(id1)
        }
      }
    },
    // in dieser Funktion werden mit hilfer der ausgewählten Daten die dazugehörigen Wissensdaten für die Anzeige ausgewählt
    infosAnzeigen: function () {
      this.ausgabeTexte = {}
      var indikationGefunden = false
      this.indikation_ausgewaehlt = false
      this.komedikation_ausgewaehlt = false
      this.alter_ausgewaehlt = false
      this.niere_ausgewaehlt = false
      this.gewicht_ausgewaehlt = false
      for (var i1 = 1; i1 <= this.countIndikation; i1++) {
        if (this.ausgewaehlte_daten.includes(this.kuerzel.indikation + i1)) {
          indikationGefunden = true
          this.indikation_ausgewaehlt = true
          break
        }
      }
      var komedikationGefunden = false
      var tempFalldatenKomed = Object.keys(this.daten.falldaten.komedikation)
      for (var i3 = 1; i3 <= tempFalldatenKomed.length; i3++) {
        var komedKuerzel = tempFalldatenKomed[i3] // also z.B. K164
        if (this.ausgewaehlte_daten.includes(komedKuerzel)) {
          komedikationGefunden = true
          this.komedikation_ausgewaehlt = true
          break
        }
      }
      for (var i4 = 1; i4 <= this.countIndikation; i4++) {
        if (this.ausgewaehlte_daten.includes(this.kuerzel.niere + i4)) {
          this.niere_ausgewaehlt = true
        }
        if (this.ausgewaehlte_daten.includes(this.kuerzel.alter + i4)) {
          this.alter_ausgewaehlt = true
        }
        if (this.ausgewaehlte_daten.includes(this.kuerzel.gewicht + i4)) {
          this.gewicht_ausgewaehlt = true
        }
      }
      // falls keine Indikation gewählt, Buttons im Browsermodus ausklappen
      if (!this.indikation_ausgewaehlt) {
        this.zeigeButtons(this.buttonStart[0])
      }
      // falls Indikation gewählt aber kein Nierenwert, Nierenbuttons-Button im Browsermodus ausklappen
      if (this.indikation_ausgewaehlt && !this.niere_ausgewaehlt) {
        this.zeigeButtons(this.buttonStart[1])
      }
      if (indikationGefunden || komedikationGefunden) {
        var kreatinin = 0
        var kreatininAusgabe = 0
        if (localStorage.kreatinin != null) {
          if (!parseFloat(localStorage.kreatinin).isNaN) {
            kreatinin = parseFloat(localStorage.kreatinin)
            kreatininAusgabe = kreatinin.toString()
            kreatininAusgabe = kreatininAusgabe.replace('.', ',')
          }
        }
        var dosierung = {}
        var begruendung = {}
        var hinweis = {}
        // hier finden die Befüllung vom Dosierungstext, Hinweistext und der Begründung statt.
        for (var d = 1; d <= this.doak_count; d++) {
          for (var ii = 1; ii <= this.countIndikation; ii++) {
            for (var n = 1; n <= 4; n++) {
              // gibt es eine Dosierung für die gewählte Indikation?
              if (this.dosierung_texte[this.kuerzel.doak + d + this.kuerzel.indikation + ii] != null &&
              this.dosierung_texte[this.kuerzel.doak + d + this.kuerzel.indikation + ii].txt != null &&
              this.ausgewaehlte_daten.includes(this.kuerzel.indikation + ii)) {
                dosierung[this.kuerzel.doak + d] = this.dosierung_texte[this.kuerzel.doak + d + this.kuerzel.indikation + ii].txt
              }
              // gibt es eine Dosierung für die gewählte Indikation und den gewählten Nierenwert(wenn dieser gewählt ist)? Wenn ja überschreibe die Doesierung von vorhin.
              if (this.dosierung_texte[this.kuerzel.doak + d + this.kuerzel.indikation + ii + this.kuerzel.niere + n] != null &&
              this.dosierung_texte[this.kuerzel.doak + d + this.kuerzel.indikation + ii + this.kuerzel.niere + n].txt != null &&
              this.ausgewaehlte_daten.includes(this.kuerzel.indikation + ii) &&
              this.ausgewaehlte_daten.includes(this.kuerzel.niere + n)) {
                dosierung[this.kuerzel.doak + d] = this.dosierung_texte[this.kuerzel.doak + d + this.kuerzel.indikation + ii + this.kuerzel.niere + n].txt
              }
              // gibt es einen Hinweis für die gewählte Indikation und den gewählten Nierenwert(wenn dieser gewählt ist)?
              if (this.hinweise[this.kuerzel.doak + d + this.kuerzel.indikation + ii + this.kuerzel.niere + n] != null &&
              this.hinweise[this.kuerzel.doak + d + this.kuerzel.indikation + ii + this.kuerzel.niere + n].txt != null &&
              this.ausgewaehlte_daten.includes(this.kuerzel.indikation + ii) &&
              this.ausgewaehlte_daten.includes(this.kuerzel.niere + n)) {
                hinweis[this.kuerzel.doak + d] = this.hinweise[this.kuerzel.doak + d + this.kuerzel.indikation + ii + this.kuerzel.niere + n].txt
              }
              // gibt es eine Begründung für die gewählte Indikation und den gewählten Nierenwert(wenn dieser gewählt ist)?
              if (this.begruendungen[this.kuerzel.doak + d + this.kuerzel.indikation + ii + this.kuerzel.niere + n] != null &&
              this.begruendungen[this.kuerzel.doak + d + this.kuerzel.indikation + ii + this.kuerzel.niere + n].txt != null &&
              this.ausgewaehlte_daten.includes(this.kuerzel.indikation + ii) &&
              this.ausgewaehlte_daten.includes(this.kuerzel.niere + n)) {
                begruendung[this.kuerzel.doak + d] = this.begruendungen[this.kuerzel.doak + d + this.kuerzel.indikation + ii + this.kuerzel.niere + n].txt
              }
              for (var aa = 1; aa <= 3; aa++) {
                // gibt es eine Dosierung für die gewählte Indikation, den gewählten Nierenwert und das gewählte Alter(wenn diese gewählt sind)? Wenn ja, dann überschreibe den Wert von vorhin.
                if (this.dosierung_texte[this.kuerzel.doak + d + this.kuerzel.indikation + ii + this.kuerzel.niere + n + this.kuerzel.alter + aa] != null &&
                this.dosierung_texte[this.kuerzel.doak + d + this.kuerzel.indikation + ii + this.kuerzel.niere + n + this.kuerzel.alter + aa].txt != null &&
                this.ausgewaehlte_daten.includes(this.kuerzel.indikation + ii) &&
                this.ausgewaehlte_daten.includes(this.kuerzel.niere + n) &&
                this.ausgewaehlte_daten.includes(this.kuerzel.alter + aa)) {
                  dosierung[this.kuerzel.doak + d] = this.dosierung_texte[this.kuerzel.doak + d + this.kuerzel.indikation + ii + this.kuerzel.niere + n + this.kuerzel.alter + aa].txt
                }
                // gibt es einen Hinweis für die gewählte Indikation, den gewählten Nierenwert und das gewählte Alter(wenn diese gewählt sind)? Wenn ja, dann überschreibe den Wert von vorhin.
                if (this.hinweise[this.kuerzel.doak + d + this.kuerzel.indikation + ii + this.kuerzel.niere + n + this.kuerzel.alter + aa] != null &&
                this.hinweise[this.kuerzel.doak + d + this.kuerzel.indikation + ii + this.kuerzel.niere + n + this.kuerzel.alter + aa].txt != null &&
                this.ausgewaehlte_daten.includes(this.kuerzel.indikation + ii) &&
                this.ausgewaehlte_daten.includes(this.kuerzel.niere + n) &&
                this.ausgewaehlte_daten.includes(this.kuerzel.alter + aa)) {
                  hinweis[this.kuerzel.doak + d] = this.hinweise[this.kuerzel.doak + d + this.kuerzel.indikation + ii + this.kuerzel.niere + n + this.kuerzel.alter + aa].txt
                }
                // gibt es eine Begründung für die gewählte Indikation, den gewählten Nierenwert und das gewählte Alter(wenn diese gewählt sind)? Wenn ja, dann überschreibe den Wert von vorhin.
                if (this.begruendungen[this.kuerzel.doak + d + this.kuerzel.indikation + ii + this.kuerzel.niere + n + this.kuerzel.alter + aa] != null &&
                this.begruendungen[this.kuerzel.doak + d + this.kuerzel.indikation + ii + this.kuerzel.niere + n + this.kuerzel.alter + aa].txt != null &&
                this.ausgewaehlte_daten.includes(this.kuerzel.indikation + ii) &&
                this.ausgewaehlte_daten.includes(this.kuerzel.niere + n) &&
                this.ausgewaehlte_daten.includes(this.kuerzel.alter + aa)) {
                  begruendung[this.kuerzel.doak + d] = this.begruendungen[this.kuerzel.doak + d + this.kuerzel.indikation + ii + this.kuerzel.niere + n + this.kuerzel.alter + aa].txt
                }
                // gibt es eine Dosierung für die gewählte Indikation, den gewählten Nierenwert, das gewählte Alter und Kreatininwert über 1,5 (wenn diese gewählt sind)? Wenn ja, dann überschreibe den Wert von vorhin.
                if (this.dosierung_texte[this.kuerzel.doak + d + this.kuerzel.indikation + ii + this.kuerzel.niere + n + this.kuerzel.alter + aa + '**'] != null &&
                this.dosierung_texte[this.kuerzel.doak + d + this.kuerzel.indikation + ii + this.kuerzel.niere + n + this.kuerzel.alter + aa + '**'].txt != null &&
                this.ausgewaehlte_daten.includes(this.kuerzel.indikation + ii) &&
                this.ausgewaehlte_daten.includes(this.kuerzel.niere + n) &&
                this.ausgewaehlte_daten.includes(this.kuerzel.alter + aa) &&
                kreatinin >= 1.5) {
                  dosierung[this.kuerzel.doak + d] = this.dosierung_texte[this.kuerzel.doak + d + this.kuerzel.indikation + ii + this.kuerzel.niere + n + this.kuerzel.alter + aa + '**'].txt
                }
                // gibt es einen Hinweis für die gewählte Indikation, den gewählten Nierenwert, das gewählte Alter und Kreatininwert über 1,5 (wenn diese gewählt sind)? Wenn ja, dann überschreibe den Wert von vorhin.
                if (this.hinweise[this.kuerzel.doak + d + this.kuerzel.indikation + ii + this.kuerzel.niere + n + this.kuerzel.alter + aa + '**'] != null &&
                this.hinweise[this.kuerzel.doak + d + this.kuerzel.indikation + ii + this.kuerzel.niere + n + this.kuerzel.alter + aa + '**'].txt != null &&
                this.ausgewaehlte_daten.includes(this.kuerzel.indikation + ii) &&
                this.ausgewaehlte_daten.includes(this.kuerzel.niere + n) &&
                this.ausgewaehlte_daten.includes(this.kuerzel.alter + aa) &&
                kreatinin >= 1.5) {
                  hinweis[this.kuerzel.doak + d] = this.hinweise[this.kuerzel.doak + d + this.kuerzel.indikation + ii + this.kuerzel.niere + n + this.kuerzel.alter + aa + '**'].txt
                }
                // gibt es eine Begründung für die gewählte Indikation, den gewählten Nierenwert, das gewählte Alter und Kreatininwert über 1,5 (wenn diese gewählt sind)? Wenn ja, dann überschreibe den Wert von vorhin.
                if (this.begruendungen[this.kuerzel.doak + d + this.kuerzel.indikation + ii + this.kuerzel.niere + n + this.kuerzel.alter + aa + '**'] != null &&
                this.begruendungen[this.kuerzel.doak + d + this.kuerzel.indikation + ii + this.kuerzel.niere + n + this.kuerzel.alter + aa + '**'].txt != null &&
                this.ausgewaehlte_daten.includes(this.kuerzel.indikation + ii) &&
                this.ausgewaehlte_daten.includes(this.kuerzel.niere + n) &&
                this.ausgewaehlte_daten.includes(this.kuerzel.alter + aa) &&
                kreatinin >= 1.5) {
                  begruendung[this.kuerzel.doak + d] = this.begruendungen[this.kuerzel.doak + d + this.kuerzel.indikation + ii + this.kuerzel.niere + n + this.kuerzel.alter + aa + '**'].txt
                }
                for (var g = 1; g <= 2; g++) {
                  // gibt es eine Dosierung für die gewählte Indikation, den gewählten Nierenwert und das gewählte Gewicht (wenn diese gewählt sind)? Wenn ja, dann überschreibe den Wert von vorhin.
                  if (this.dosierung_texte[this.kuerzel.doak + d + this.kuerzel.indikation + ii + this.kuerzel.niere + n + 'a*g' + g] != null &&
                  this.dosierung_texte[this.kuerzel.doak + d + this.kuerzel.indikation + ii + this.kuerzel.niere + n + 'a*g' + g].txt != null &&
                  this.ausgewaehlte_daten.includes(this.kuerzel.indikation + ii) &&
                  this.ausgewaehlte_daten.includes(this.kuerzel.niere + n) &&
                  this.ausgewaehlte_daten.includes(this.kuerzel.gewicht + g)) {
                    dosierung[this.kuerzel.doak + d] = this.dosierung_texte[this.kuerzel.doak + d + this.kuerzel.indikation + ii + this.kuerzel.niere + n + 'a*g' + g].txt
                  }
                  // gibt es eine Dosierung für die gewählte Indikation, den gewählten Nierenwert, das gewählte Gewicht und Kreatininwert über 1,5 (wenn diese gewählt sind)? Wenn ja, dann überschreibe den Wert von vorhin.
                  if (this.dosierung_texte[this.kuerzel.doak + d + this.kuerzel.indikation + ii + this.kuerzel.niere + n + 'a*g' + g + '**'] != null &&
                  this.dosierung_texte[this.kuerzel.doak + d + this.kuerzel.indikation + ii + this.kuerzel.niere + n + 'a*g' + g + '**'].txt != null &&
                  this.ausgewaehlte_daten.includes(this.kuerzel.indikation + ii) &&
                  this.ausgewaehlte_daten.includes(this.kuerzel.niere + n) &&
                  this.ausgewaehlte_daten.includes(this.kuerzel.gewicht + g) &&
                  kreatinin >= 1.5) {
                    dosierung[this.kuerzel.doak + d] = this.dosierung_texte[this.kuerzel.doak + d + this.kuerzel.indikation + ii + this.kuerzel.niere + n + 'a*g' + g + '**'].txt
                  }
                  // gibt es eine Dosierung für die gewählte Indikation, den gewählten Nierenwert, das gewählte Alter und das gewählte Gewicht (wenn diese gewählt sind)? Wenn ja, dann überschreibe den Wert von vorhin.
                  if (this.dosierung_texte[this.kuerzel.doak + d + this.kuerzel.indikation + ii + this.kuerzel.niere + n + this.kuerzel.alter + aa + this.kuerzel.gewicht + g] != null &&
                  this.dosierung_texte[this.kuerzel.doak + d + this.kuerzel.indikation + ii + this.kuerzel.niere + n + this.kuerzel.alter + aa + this.kuerzel.gewicht + g].txt != null &&
                  this.ausgewaehlte_daten.includes(this.kuerzel.indikation + ii) &&
                  this.ausgewaehlte_daten.includes(this.kuerzel.niere + n) &&
                  this.ausgewaehlte_daten.includes(this.kuerzel.gewicht + g) &&
                  this.ausgewaehlte_daten.includes(this.kuerzel.alter + aa)) {
                    dosierung[this.kuerzel.doak + d] = this.dosierung_texte[this.kuerzel.doak + d + this.kuerzel.indikation + ii + this.kuerzel.niere + n + this.kuerzel.alter + aa + this.kuerzel.gewicht + g].txt
                  }
                  // gibt es einen Hinweis für die gewählte Indikation, den gewählten Nierenwert und das gewählte Gewicht (wenn diese gewählt sind)? Wenn ja, dann überschreibe den Wert von vorhin.
                  if (this.hinweise[this.kuerzel.doak + d + this.kuerzel.indikation + ii + this.kuerzel.niere + n + 'a*g' + g] != null &&
                  this.hinweise[this.kuerzel.doak + d + this.kuerzel.indikation + ii + this.kuerzel.niere + n + 'a*g' + g].txt != null &&
                  this.ausgewaehlte_daten.includes(this.kuerzel.indikation + ii) &&
                  this.ausgewaehlte_daten.includes(this.kuerzel.niere + n) &&
                  this.ausgewaehlte_daten.includes(this.kuerzel.gewicht + g)) {
                    hinweis[this.kuerzel.doak + d] = this.hinweise[this.kuerzel.doak + d + this.kuerzel.indikation + ii + this.kuerzel.niere + n + 'a*g' + g].txt
                  }
                  // gibt es einen Hinweis für die gewählte Indikation, den gewählten Nierenwert, das gewählte Gewicht und Kreatininwert über 1,5 (wenn diese gewählt sind)? Wenn ja, dann überschreibe den Wert von vorhin.
                  if (this.hinweise[this.kuerzel.doak + d + this.kuerzel.indikation + ii + this.kuerzel.niere + n + 'a*g' + g + '**'] != null &&
                  this.hinweise[this.kuerzel.doak + d + this.kuerzel.indikation + ii + this.kuerzel.niere + n + 'a*g' + g + '**'].txt != null &&
                  this.ausgewaehlte_daten.includes(this.kuerzel.indikation + ii) &&
                  this.ausgewaehlte_daten.includes(this.kuerzel.niere + n) &&
                  this.ausgewaehlte_daten.includes(this.kuerzel.gewicht + g) &&
                  kreatinin >= 1.5) {
                    hinweis[this.kuerzel.doak + d] = this.hinweise[this.kuerzel.doak + d + this.kuerzel.indikation + ii + this.kuerzel.niere + n + 'a*g' + g + '**'].txt
                  }
                  // gibt es einen Hinweis für die gewählte Indikation, den gewählten Nierenwert, das gewählte Alter und das gewählte Gewicht (wenn diese gewählt sind)? Wenn ja, dann überschreibe den Wert von vorhin.
                  if (this.hinweise[this.kuerzel.doak + d + this.kuerzel.indikation + ii + this.kuerzel.niere + n + this.kuerzel.alter + aa + this.kuerzel.gewicht + g] != null &&
                  this.hinweise[this.kuerzel.doak + d + this.kuerzel.indikation + ii + this.kuerzel.niere + n + this.kuerzel.alter + aa + this.kuerzel.gewicht + g].txt != null &&
                  this.ausgewaehlte_daten.includes(this.kuerzel.indikation + ii) &&
                  this.ausgewaehlte_daten.includes(this.kuerzel.niere + n) &&
                  this.ausgewaehlte_daten.includes(this.kuerzel.gewicht + g) &&
                  this.ausgewaehlte_daten.includes(this.kuerzel.alter + aa)) {
                    hinweis[this.kuerzel.doak + d] = this.hinweise[this.kuerzel.doak + d + this.kuerzel.indikation + ii + this.kuerzel.niere + n + this.kuerzel.alter + aa + this.kuerzel.gewicht + g].txt
                  }
                  // gibt es eine Begründung für die gewählte Indikation, den gewählten Nierenwert und das gewählte Gewicht (wenn diese gewählt sind)? Wenn ja, dann überschreibe den Wert von vorhin.
                  if (this.begruendungen[this.kuerzel.doak + d + this.kuerzel.indikation + ii + this.kuerzel.niere + n + 'a*g' + g] != null &&
                  this.begruendungen[this.kuerzel.doak + d + this.kuerzel.indikation + ii + this.kuerzel.niere + n + 'a*g' + g].txt != null &&
                  this.ausgewaehlte_daten.includes(this.kuerzel.indikation + ii) &&
                  this.ausgewaehlte_daten.includes(this.kuerzel.niere + n) &&
                  this.ausgewaehlte_daten.includes(this.kuerzel.gewicht + g)) {
                    begruendung[this.kuerzel.doak + d] = this.begruendungen[this.kuerzel.doak + d + this.kuerzel.indikation + ii + this.kuerzel.niere + n + 'a*g' + g].txt
                  }
                  // gibt es eine Begründung für die gewählte Indikation, den gewählten Nierenwert, das gewählte Gewicht und Kreatininwert über 1,5 (wenn diese gewählt sind)? Wenn ja, dann überschreibe den Wert von vorhin.
                  if (this.begruendungen[this.kuerzel.doak + d + this.kuerzel.indikation + ii + this.kuerzel.niere + n + 'a*g' + g + '**'] != null &&
                  this.begruendungen[this.kuerzel.doak + d + this.kuerzel.indikation + ii + this.kuerzel.niere + n + 'a*g' + g + '**'].txt != null &&
                  this.ausgewaehlte_daten.includes(this.kuerzel.indikation + ii) &&
                  this.ausgewaehlte_daten.includes(this.kuerzel.niere + n) &&
                  this.ausgewaehlte_daten.includes(this.kuerzel.gewicht + g) &&
                  kreatinin >= 1.5) {
                    begruendung[this.kuerzel.doak + d] = this.begruendungen[this.kuerzel.doak + d + this.kuerzel.indikation + ii + this.kuerzel.niere + n + 'a*g' + g + '**'].txt
                  }
                  // gibt es eine Begründung für die gewählte Indikation, den gewählten Nierenwert, das gewählte Alter und das gewählte Gewicht (wenn diese gewählt sind)? Wenn ja, dann überschreibe den Wert von vorhin.
                  if (this.begruendungen[this.kuerzel.doak + d + this.kuerzel.indikation + ii + this.kuerzel.niere + n + this.kuerzel.alter + aa + this.kuerzel.gewicht + g] != null &&
                  this.begruendungen[this.kuerzel.doak + d + this.kuerzel.indikation + ii + this.kuerzel.niere + n + this.kuerzel.alter + aa + this.kuerzel.gewicht + g].txt != null &&
                  this.ausgewaehlte_daten.includes(this.kuerzel.indikation + ii) &&
                  this.ausgewaehlte_daten.includes(this.kuerzel.niere + n) &&
                  this.ausgewaehlte_daten.includes(this.kuerzel.gewicht + g) &&
                  this.ausgewaehlte_daten.includes(this.kuerzel.alter + aa)) {
                    begruendung[this.kuerzel.doak + d] = this.begruendungen[this.kuerzel.doak + d + this.kuerzel.indikation + ii + this.kuerzel.niere + n + this.kuerzel.alter + aa + this.kuerzel.gewicht + g].txt
                  }
                }
              }
              var tempKomedKeys = Object.keys(this.daten.falldaten.komedikation)
              for (var kk = 1; kk <= tempKomedKeys.length; kk++) {
                var tempKomedKuerzel = tempKomedKeys[kk] // also z.B. K164
                // gibt es eine Dosierung für die gewählte Indikation, den gewählten Nierenwert und die gewählte Komedikation (wenn diese gewählt sind)? Wenn ja, dann setze neue Werte für Dosierung und Begründung
                if (this.dosierung_texte[this.kuerzel.doak + d + this.kuerzel.indikation + ii + this.kuerzel.niere + n + tempKomedKuerzel] != null &&
                this.dosierung_texte[this.kuerzel.doak + d + this.kuerzel.indikation + ii + this.kuerzel.niere + n + tempKomedKuerzel].txt != null &&
                this.ausgewaehlte_daten.includes(this.kuerzel.indikation + ii) && this.ausgewaehlte_daten.includes(this.kuerzel.niere + n) &&
                this.ausgewaehlte_daten.includes(tempKomedKuerzel)) {
                  dosierung[this.kuerzel.doak + d] = this.dosierung_texte[this.kuerzel.doak + d + this.kuerzel.indikation + ii + this.kuerzel.niere + n + tempKomedKuerzel].txt
                }
                if (this.begruendungen[this.kuerzel.doak + d + this.kuerzel.indikation + ii + this.kuerzel.niere + n + tempKomedKuerzel] != null &&
                this.begruendungen[this.kuerzel.doak + d + this.kuerzel.indikation + ii + this.kuerzel.niere + n + tempKomedKuerzel].txt != null &&
                this.ausgewaehlte_daten.includes(this.kuerzel.indikation + ii) && this.ausgewaehlte_daten.includes(this.kuerzel.niere + n) &&
                this.ausgewaehlte_daten.includes(tempKomedKuerzel)) {
                  begruendung[this.kuerzel.doak + d] = this.begruendungen[this.kuerzel.doak + d + this.kuerzel.indikation + ii + this.kuerzel.niere + n + tempKomedKuerzel].txt
                }
              }
            }
          }
        }
        for (var i = 1; i <= this.doak_count; i++) {
          if (this.doak[this.kuerzel.doak + i] !== null) {
            var doak = {}
            var komedikation = {}
            var kontraVorhandenKomed = false
            var kontraKomedText = ''
            var kontraNiereText = this.kontraindikationen[this.kuerzel.doak + i].niere
            var vermeidenVorhandenKomed = false
            var nichtEinsetzenVorhandenKomed = false
            var komedNichtEmpfohlen = false
            var komedCount = 0
            var komedikationVorhanden = false
            var komedikationNachherVorhanden = false
            // hier wird geschaut, ob es für die gewählte Komedikation, Texte gibt und ob diese Texte bestimmte Phrasen enthalten.
            var tempKomedindexList = Object.keys(this.daten.falldaten.komedikation)
            for (var a = 1; a <= tempKomedindexList.length; a++) {
              var komedIndex = tempKomedindexList[a] // also z.B. K164
              if (this.ausgewaehlte_daten.includes(komedIndex) &&
              this.komedikation_texte[komedIndex + this.kuerzel.doak + i] != null &&
              this.komedikation_texte[komedIndex + this.kuerzel.doak + i].txt != null) {
                komedikation[this.buttonStart[4].buttons[komedIndex]] = this.komedikation_texte[komedIndex + this.kuerzel.doak + i].txt
                komedikationVorhanden = true
                komedCount++
                if (this.komedikation_texte[komedIndex + this.kuerzel.doak + i].sid === this.schweregrad_kontraindiziert) {
                  kontraVorhandenKomed = true

                  kontraKomedText += this.kontraindikationen[this.kuerzel.doak + i].komedNamen[komedIndex] + ', '
                  begruendung[this.kuerzel.doak + i] = ''
                }
                if (this.komedikation_texte[komedIndex + this.kuerzel.doak + i].sid === this.schweregrad_vermeiden) {
                  vermeidenVorhandenKomed = true
                  begruendung[this.kuerzel.doak + i] = ''
                }
                if (this.komedikation_texte[komedIndex + this.kuerzel.doak + i].sid === this.schweregrad_nicht_einsetzen) {
                  nichtEinsetzenVorhandenKomed = true
                  begruendung[this.kuerzel.doak + i] = ''
                }
                if (this.komedikation_texte[komedIndex + this.kuerzel.doak + i].sid === this.schweregrad_nicht_empfohlen) {
                  komedNichtEmpfohlen = true
                  begruendung[this.kuerzel.doak + i] = ''
                }
              }
              // hier wird geschaut, ob es für die gewählte Komedikation, in Verbindung mit einer bestimmten Indikation, Texte gibt und ob diese Texte bestimmte Phrasen enthalten.
              for (var i2 = 1; i2 <= 5; i2++) {
                if (this.ausgewaehlte_daten.includes(komedIndex) &&
                this.komedikation_texte[komedIndex + this.kuerzel.doak + i + this.kuerzel.indikation + i2] != null &&
                this.komedikation_texte[komedIndex + this.kuerzel.doak + i + this.kuerzel.indikation + i2].txt != null &&
                this.ausgewaehlte_daten.includes(this.kuerzel.indikation + i2)) {
                  komedikation[this.buttonStart[4].buttons[komedIndex]] = this.komedikation_texte[komedIndex + this.kuerzel.doak + i + this.kuerzel.indikation + i2].txt
                  komedikationVorhanden = true
                  komedCount++
                  if (this.komedikation_texte[komedIndex + this.kuerzel.doak + i + this.kuerzel.indikation + i2].sid === this.schweregrad_kontraindiziert) {
                    kontraVorhandenKomed = true
                    kontraKomedText += this.kontraindikationen[this.kuerzel.doak + i].komedNamen[komedIndex] + ', '
                    begruendung[this.kuerzel.doak + i] = ''
                  }
                  if (this.komedikation_texte[komedIndex + this.kuerzel.doak + i + this.kuerzel.indikation + i2].sid === this.schweregrad_vermeiden) {
                    vermeidenVorhandenKomed = true
                    begruendung[this.kuerzel.doak + i] = ''
                  }
                  if (this.komedikation_texte[komedIndex + this.kuerzel.doak + i + this.kuerzel.indikation + i2].sid === this.schweregrad_nicht_einsetzen) {
                    nichtEinsetzenVorhandenKomed = true
                    begruendung[this.kuerzel.doak + i] = ''
                  }
                  if (this.komedikation_texte[komedIndex + this.kuerzel.doak + i + this.kuerzel.indikation + i2].sid === this.schweregrad_nicht_empfohlen) {
                    komedNichtEmpfohlen = true
                    begruendung[this.kuerzel.doak + i] = ''
                  }
                }
              }
            }

            kontraKomedText = kontraKomedText.substr(0, kontraKomedText.length - 2)
            doak.wirkstoff = this.doak_wirkstoff[this.kuerzel.doak + i]
            doak.kreatinin = kreatinin
            doak.kreatininAusgabe = kreatininAusgabe
            doak.dosierung = dosierung[this.kuerzel.doak + i]
            doak.begruendung = begruendung[this.kuerzel.doak + i]
            doak.hinweis = hinweis[this.kuerzel.doak + i]
            doak.kontraVorhandenKomed = kontraVorhandenKomed
            doak.kontraKomedText = kontraKomedText
            doak.kontraNiereText = kontraNiereText
            doak.vermeidenVorhandenKomed = vermeidenVorhandenKomed
            doak.nichtEinsetzenVorhandenKomed = nichtEinsetzenVorhandenKomed
            doak.komedNichtEmpfohlen = komedNichtEmpfohlen
            doak.kontraindikationen = this.kontraindikationen[this.kuerzel.doak + i]
            doak.komedCount = komedCount
            doak.komedikation = komedikation
            doak.komedikationVorhanden = komedikationVorhanden
            doak.komedikationNachherVorhanden = komedikationNachherVorhanden
            doak.dokumentePopupId = 'modal' + i
            doak.dokumentePopupTrigger = '#modal' + i
            doak.pdf = this.doak_pdf['d' + i]
            if (this.doak_gba_pdf['d' + i] != null) {
              doak.pdf_gba = this.doak_gba_pdf['d' + i]
            }
            this.ausgabeTexte[this.doak[this.kuerzel.doak + i]] = doak
          }
        }
      }
    },
    // hier werden bestimmte Aktionen aus diesem Vue geloggt und im LocalStorage abgelegt
    loggeDaten: function (id) {
      // hier wird geprüft, ob der User gerade online oder offline ist.
      var statusJetzt = 'offline'
      if (navigator.onLine) {
        statusJetzt = 'online'
      }
      // hier werden die LoggDaten aus dem LocalStorage in ein Array eingelesen und eine Kopie von den LoggDaten aus dem LocalStorage erstellt.
      var loggDaten = []
      var localStorageLoggDatenKopie = null
      if (localStorage.loggDaten != null) {
        loggDaten = JSON.parse(localStorage.loggDaten)
        localStorageLoggDatenKopie = localStorage.loggDaten
      }
      var maxLogEintraege = parseInt(localStorage.maxLogEintraege)
      // wenn die Loggsaten, die Maximallänge nicht überschreiten, dann wird der neue Eintrag in die Daten eingefügt.
      if (loggDaten.length < maxLogEintraege) {
        var neuerLogg = { aktion: id, datum: new Date(), status: statusJetzt }
        loggDaten.push(JSON.stringify(neuerLogg))
        var loggDatenAktuell = JSON.stringify(loggDaten)
        // hier wird versucht, die erweiterten Loggdaten in den LocalStorage zu schreiben. Wenn das schiefläuft, dann werden die alten Loggdaten in den LocalStorage geschrieben.
        try {
          localStorage.loggDaten = loggDatenAktuell
        } catch (e) {
          localStorage.loggDaten = localStorageLoggDatenKopie
        }
      }
    }
  },
  mounted () {
    M.AutoInit()

    if (this.bestaetigungArzt === false) {
      this.$router.push({ name: 'Disclaimer' })
    }
    if (this.sperre === true) {
      this.$router.push({ name: 'Sperre' })
    }
    if (localStorage.ausgewaehlte_daten != null) {
      var ausgewaehlteDatenTemp = JSON.parse(localStorage.ausgewaehlte_daten)
      for (var i = ausgewaehlteDatenTemp.length - 1; i >= 0; i--) {
        this.ausgewaehlte_daten.push(ausgewaehlteDatenTemp[i])
      }
    }
    this.init_daten()

    // hier wird geschaut, ob die Kreatinin-Clearance bereits ausgerechnet und im LocalStorage abgelegt wurde. Falls Ja, wird diese in einer lokalen Variable für die Anzeige gespeichert.
    if (localStorage.kreatinin_clearance != null) {
      this.kreatinin_clearance = localStorage.kreatinin_clearance
    }
    // hier wird geschaut, ob die Kreatinin-Clearance bereits ausgerechnet und der Kreatinin-Wert in Speicher abgelegt wurde. Falls Ja, wird dieser Wert in einer lokalen Variable für die Anzeige gespeichert.
    if (localStorage.kreatinin != null) {
      if (!parseFloat(localStorage.kreatinin).isNaN) {
        this.kreatinin = parseFloat(localStorage.kreatinin)
        this.kreatinin = this.kreatinin.toString()
        this.kreatinin = this.kreatinin.replace('.', ',')
      }
    }

    var tempThis = this
    document.addEventListener('filterEntfernt', function () {
      tempThis.alleFilterEntfernen('global')
    })
    document.addEventListener('schliesseNierePopup', function () {
      tempThis.nierePopupOffen = false
    })
  },
  updated () {
    M.AutoInit()
    this.nierenButtonTop = this.$refs.filter_1[0].offsetTop
  }
}
</script>

<style scoped>
.infoButton {
  margin-top: 8px;
  width: 20px;
  cursor: pointer;
}

.infoButtonBlau {
  margin-right: 5px;
  margin-top: 5px;
  width: 22px;
  cursor: pointer;
}
.width100 {
  width: 100%;
}

.niereBerechnenWert {
  font-size: 90%;
  margin-top: 7px;
  text-transform: none;
}

.niereBerechnenMitErgebnis {
  height: 75px;
  line-height: 1.5;
  padding-top: 7px;
}

.noWrap {
  white-space: nowrap;
}

.kreatininClearanceWert {
  font-size: 90%;
  margin-top: 12px;
}

.collapsible-body {
  padding-top:5px !important;
  padding-bottom:5px !important;
}

.marginTop5 {
  margin-top: 5px;
  font-size: 18px;
}

.borderBuchstabe {
  border: 2px solid rgb(21, 101, 192);
  border-radius: 5px 5px 5px 5px;
  margin: 3px;
  cursor: pointer;
  font-weight:bold;
}

.borderBuchstabe2 {
  border: 2px solid rgb(21, 101, 192);
  border-radius: 5px 5px 5px 5px;
  margin: 3px;
  cursor: pointer;
  padding: 5px;
  text-align: center;
  margin-right:-30px;
}

.borderBuchstabe:hover {
  background-color: #8ac2ed;
}

.borderBuchstabe2:hover {
  background-color: #8ac2ed;
}

.marginTop12 {
  margin-top:20px;
}

.borderBuchstabe1 {
  border: 2px solid rgb(21, 101, 192);
  border-radius: 5px 5px 5px 5px;
  margin: 3px;
  cursor: pointer;
  font-weight:bold;
  background-color: #8ac2ed;
}

#modalKomedAuswahl {
  z-index: 1005;
  display: block;
  top: 100px;
}

.fontSize16 {
  font-size: 16px;
  text-align: center;
}

.paddingLeft15 {
  padding-left: 15px;
}

.ausgewaehlteKomedMobile {
  margin-left: 5px;
  font-size: 16px;
}

.komedDelete {
  width:25px;
  cursor:pointer;
}

.cursorPonter{
  cursor:pointer;
}

.buchstabenUebersicht {
  width: 60%;
  padding-left:30px;
}

.buchstabenZurueck {
  border: 2px solid rgb(21, 101, 192);
  border-radius: 5px 5px 5px 5px;
  margin: 3px;
  cursor: pointer;
  padding: 5px;
  text-align: center;
  width:300px;
  margin-bottom: 10px;
}

.buchstabenZurueck:hover {
  background-color: #8ac2ed;
}

.marginLeft5 {
  margin-left: 5px;
}

.buchstabeGewaehlt {
  width:70px;
  padding:0px;
  padding-left:5px;
  font-size:40px;
}

.KomedDeleteTable {
  margin-left:5px;
  margin-top:5px;
}

.KomedDeleteTd {
  width:50px;
  padding:0px;
}

.paddingNull1 {
  padding: 0px;
}

.komedGewaehltDiv {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.marginTop15 {
  margin-top:15px;
}

.cardGroesse {
    width:100%;
    margin: auto;
    margin-top: -0px;
    box-shadow: none !important;
}

.cardActionGroesse {
    height:32px;
    padding-top:0px;
    border: none !important;
}

.pfeilGroesse {
    width:13px;
    margin-top:5px;
    margin-right:-10px;
}

.pfeilBackGroesse {
    width:13px;
    margin-top:5px;
    margin-left:-10px;
}
.pfeilBackGroesse2 {
    width:13px;
    margin-top:5px;
}

.overflowGrau {
  height:100vh;
  width:100%;
  left:0;
  position:fixed;
  z-index: 1004;
  top:0px;
  opacity: 0.5;
}

#modal_buchstabensuche {
    z-index: 1005;
    display: block;
}

#cardHoehe {
    min-height:79vh !important;
}

#filterFrageDiv {
    padding:10px;
    padding-bottom:0px;
}

.buchstabeKomedPos {
    margin-top: 20px;
}

.mobileFilter {
  margin-top:-5px;
}

.buchstabenClickPosition {
    width:12%;
    float: left;
    text-align:center;
    padding:5px;
}

.buchstabenClickPositionAlle {
    width: calc(84% + 36px);
    float: left;
    text-align:center;
    padding:5px;
}

.zumErgebnis {
  text-transform: none !important;
  margin-right: 5px;
  margin-top: 6px;
  font-weight: bold;
}

.buchstabeTitel {
    font-size:18px;
}

#komedUntertitel {
    font-size: calc(7px + 2vw) !important;
}

.niere1 {
  font-size:12px;
  font-weight:normal;
}

.deleteButton {
    width:16px;
    margin-top:6px;
}

.deleteButton2 {
    width:16px;
    margin-top:6px;
    margin-right: 10px;
}

.hellblauButton {
  padding-bottom: 10px !important;
}

.deleteButtonBlau {
    width:16px;
    margin-top:-5px;
}

#frageTextBold {
    margin-top: 20px;
    font-size: calc(7px + 2.4vw) !important;
    font-weight:bold;
}

#frageText {
    margin-top: 20px;
    font-size: calc(7px + 2.4vw) !important;
}

.marginTop150 {
    margin-top:150px;
}

.modalContentGroesse {
    height:370px;
    overflow-x:auto;
}

.buchstabeUnsichtbar {
    display:none;
}

.positionEinzelAuswahl1 {
    width:45%;
    float:left;
    margin-left:9%;
}

.komedGruppen {
    background-color:white !important;
}

.positionEinzelAuswahl2 {
    width:45%;
    float:left;
}

.positionEinzelAuswahl3 {
    width:45%;
    float:left;
    margin-top:20px;
    margin-left:9%;
}

.positionEinzelAuswahl4 {
    width:45%;
    float:left;
    margin-top:20px;
}

.buttonPfeil {
    width:12px;
    margin-top:8px;
}

.buttonFilterNierenberechnung {
  padding-bottom: 10px !important;
}

.buttonPfeilNiere {
    width:12px;
    margin-top: -3px;
}

#hrTrennung {
    height:2px;
    margin-top: 20px;
}

.displayNone {
    display: none !important;
}

.collapsibleLi {
    width: 100%;
    margin-left:0px !important;
    height:55px !important;
}

.collapsibleHeader {
    padding:5px;
    padding-left:15px;
    height:54px;
}

.styleX {
    font-weight:bold;
    font-size:24px;
}

.collapsibleHeaderText {
    font-size: calc(7px + 2vw) !important;
    line-height:15px;
}

.collapsibleHeaderText2 {
    font-size: calc(7px + 2vw) !important;
    line-height:15px;
    margin-top: 20px;
}

.displayBlock {
    display: block !important;
}

#filterAntwortenDiv {
    padding:10px;
    padding-top:1px;
}

.filterNavAktiv {
    margin-left:0px !important;
    margin-right: 0px !important;
    /* line-height:45px !important; */
    background-color: #E3F2FD;
    height: 56px;
    padding-right: 1px;
    padding-left: 1px;
}
.filterNav {
    margin-left:0px !important;
    margin-right: 0px !important;
    padding-right: 1px;
    padding-left: 1px;
}

.filterNavA1 {
    padding-left:3px !important;
    padding-right:3px !important;
    font-size: calc(9px + 1.7vw) !important;
    margin-left:7px;
}
.filterNavA1Aktiv {
    padding-left:3px !important;
    padding-right:3px !important;
    font-size: calc(9px + 1.7vw) !important;
    margin-left:7px;
    color: #1565C0 !important;
}
.filterNavA {
    padding-left:3px !important;
    padding-right:3px !important;
    font-size: calc(9px + 1.7vw) !important;
}
.filterNavAAktiv {
    padding-left:3px !important;
    padding-right:3px !important;
    font-size: calc(9px + 1.7vw) !important;
    color: #1565C0 !important;
}
.ergebnisNavA {
    padding-left:5px !important;
    padding-right:9px !important;
    font-size: calc(9px + 1.7vw) !important;
}
.ergebnisNav {
    margin-left:0px !important;
    margin-right: 0px !important;
    margin-right:5px;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
[type="radio"]:checked+span:after, [type="radio"].with-gap:checked+span:after {
  background-color:#1565C0;
}
[type="radio"]:checked+span:after, [type="radio"].with-gap:checked+span:before,
[type="radio"].with-gap:checked+span:after {
  border: 2px solid #1565C0;
}
[type="checkbox"].filled-in:checked + span:not(.lever):after {
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid rgb(112, 166, 228);
  background-color: #1565C0;
  z-index: 0;
}

.container {
  background-color: white;
}

.paddingTopAlleAnzeigen {
  padding-top: 2px;
}

.filterButtonNiere {
  margin-right: 0px;
}

@media screen and (min-width: 320px) {
  .container {
    background-color: white;
    width: 100% !important;
  }
}
@media screen and (min-width: 600px) {
  .container {
     background-color: white;
  }
}
@media screen and (min-width: 1194px) {
  .paddingTopAlleAnzeigen {
    padding-top: 28px;
  }
}
@media screen and (min-width: 1371px) {
  .paddingTopAlleAnzeigen {
    padding-top: 55px;
  }

  .filterButtonNiere {
    margin-right: 30px !important;
  }
}
</style>
